<template>
  <div class="plan-container padding tiny">
    <div class="content">
      <div class="content-head">
        <h1 class="label-title">플랜 변경</h1>
        <h2 class="label-small-title plan-title">
          <span>구독 플랜 선택</span>
          <hr class="mobile-only" />
        </h2>
      </div>

      <div class="content-detail">
        <div class="plan-wrapper">
          <div class="plan-list">
            <plan-card-item
                v-for="(plan, index) in plans"
                :itemNumber="index"
                :key="plan.id"
                :plan="plan"
                :selectedType="selectedType"
                :couponId="selectedCoupon"
                :isDetailShow="showDetail"
                @selected="planSelected"
            />
          </div>
        </div>

        <div class="infos">
          <div class="box">
            월간 플랜 변경시 <strong>2개월</strong> 추가 혜택을 드립니다.
<!--            이번 달 월간 플랜 구독료 <strong>환불 후</strong> 연간 플랜 구독료가 결제됩니다.-->
          </div>
        </div>

        <div class="payment payment-container" v-if="differentPlan">
          <h2 class="label-small-title payment-title">
            <span>구매 정보</span>
            <hr />
          </h2>

          <div class="amount">
            <div class="row">
              <div class="key">구매 상품</div>
              <div class="val plan" v-if="selectedPlan">{{selectedPlan.name}} 플랜</div>
            </div>

<!--            <div class="row">-->
<!--              <div class="key">결제 금액</div>-->
<!--              <div class="val">${{ selectedPlan.price }}</div>-->
<!--            </div>-->

<!--            <div class="row discount" v-if="(paymentInfo.discount > 0)">-->
<!--              <div class="key">할인 <span class="small"></span></div>-->

<!--              <div class="val">${{paymentInfo.discount}}</div>-->
<!--            </div>-->

<!--            <div class="row total">-->
<!--              <div class="key"><strong>최종 결제 금액</strong></div>-->
<!--              <div class="val"><strong>${{ paymentInfo.totalAmount }}</strong></div>-->
<!--            </div>-->
          </div>
        </div>

<!--        <div class="payment payment-container" v-if="differentPlan && (coupons.length > 0) && selectedPlan.coupon_usable">-->
<!--          <h2 class="label-small-title payment-title">-->
<!--            <span>쿠폰</span>-->
<!--            <hr />-->
<!--          </h2>-->

<!--          <div class="text-input">-->
<!--            <div class="selectbox-element">-->
<!--              <div class="selectbox-element-wrapper">-->
<!--                <div class="selected">-->
<!--                  <span class="">{{couponName}}</span>-->
<!--                </div>-->
<!--                <div class="icon-arrow">-->
<!--                  <div class="icon"></div>-->
<!--                </div>-->

<!--                <select v-model="selectedCoupon" @change="changedCoupon">-->
<!--                  <option value="-1">-->
<!--                    쿠폰을 선택해주세요.-->
<!--                  </option>-->

<!--                  <option :value="coupon.id" v-for="coupon in coupons" :disabled="getDisabled(coupon)">-->
<!--                    {{coupon.name}}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->

<!--              <label class="state-message" v-if="selectedCoupon != -1">-->
<!--                <i class="material-icons done">done</i>-->
<!--                <small>할인 코드가 적용 되었습니다.</small>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

      </div>

      <div class="content-option" v-if="differentPlan">
        <div class="change-button-wrapper">
          <miilk-button :v="$v" name="플랜 변경" class="primary" @clicked="changePlan" />
        </div>
      </div>
    </div>

    <order-layer-pop v-if="confirm"
                     title=""
                     content="월간 플랜으로 변경하시겠습니까?"
                     @clicked="checkConfirm"
                     @canceled="closePop"
    />

    <layer-popup v-if="paymentError"
                 title="결제에 실패하였습니다."
                 content="마이페이지 > 결제수단에서 카드 변경 후 다시 진행해주세요."
                 @close-layer-popup="closeError"
    />
  </div>
</template>

<script>
import PlanCardItem from "../../components/orders/plan_card_item";
import OrderLayerPop from "./layer_popup";
import MiilkButton from "../../components/commons/miilk_button";

export default {
  props: {
  },
  data() {
    return {
      plans: [],
      plan: null,
      showDetail: true,

      selectedType: null,
      selectedPlan: null,

      confirm: false,
      isProcessing: false,
      paymentError: false,
      coupons: [],
      user: null,
      paymentInfo: {
        cardInfo: null,
        isValidCard: false,
        isValidCvc: true,

        cardInfoError: null,
        name: null,

        discount: 0,
        discountRate: 0,
        paymentTerm: null,
        totalAmount: 0,
        isFree: false,
        selectedMethod: -1,
      },
      selectedCoupon: -1,
      uid: null,
    }
  },
  created() {
    const self = this;
    this.uid = this.generateUUID();
    axios
        .get("/api/users/me")
        .then((res) => {
          self.user = res.data.user;
          self.selectedType = res.data.user.current_subscribe.terms;
          self.setSelectedPlan();
        })
        .catch((e) => {});

    // get plans
    axios.get(`/api/orders/plans?free=false`).then((res) => {
      self.plans = res.data.plans;
      self.setSelectedPlan();
    });

    // get coupons
    axios.get("/api/users/coupons").then((res) => {
      self.coupons = res.data.coupons;
    });

  },
  mounted() {
  },
  computed: {
    differentPlan() {
      return this.user != null
          && this.selectedPlan != null
          && this.user.current_subscribe.terms != this.selectedPlan.terms;
    },
    amount: function() {
      return this.user.selectedPlan?.price;
    },
    couponName() {
      if (this.selectedCoupon == -1) {
        return '쿠폰을 선택해주세요.';
      }

      const coupon = this.coupons.find(x => x.id == this.selectedCoupon);
      return coupon.name;
    },
  },
  methods: {
    setSelectedPlan() {
      if (this.selectedType == null || this.plans.length < 1) {
        return null;
      }

      const self = this;
      this.selectedPlan = this.plans.find((plan) => {
        return plan.terms == self.selectedType;
      });
    },
    generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : r & 0x7 | 0x8).toString(16);
      });
      return uuid;
    },
    planSelected: function(type, id) {
      if (this.user.current_subscribe.terms == type) {
        return;
      }

      this.selectedType = type;
      this.selectedPlan = this.plans.find((plan) => {
        return plan.terms == type;
      });

      // this.initPaymentInfo();
      this.selectedCoupon = -1;

      this.$v.$reset();
      this.$v.$touch();
    },
    initPaymentInfo() {
      this.paymentInfo.discount = this.selectedPlan.price - this.selectedPlan.amount;
      this.paymentInfo.discountRate = 0;
      this.paymentInfo.totalAmount = this.selectedPlan.amount;
    },
    changedCoupon() {
      if (this.selectedCoupon == -1) {
        this.initPaymentInfo();
        return;
      }

      // check price
      axios.get(`/api/orders/calculate?plan_id=${this.selectedPlan.id}&coupon_id=${this.selectedCoupon}`).then(res => {
        this.paymentInfo.discount = res.data.discount;
        this.paymentInfo.discountRate = res.data.discount_rate;
        this.paymentInfo.totalAmount = res.data.price;
      });
    },
    getDisabled(coupon) {
      if (
          this.selectedPlan.terms == "yearly" &&
          coupon.coupon_type.discount_type == "terms"
      ) {
        return true;
      } else if (
          this.selectedPlan.terms == "monthly" &&
          coupon.coupon_type.discount_type == "rate"
      ) {
        return true;
      }

      return false;
    },
    changePlan: function() {
      if (this.selectedType == 'monthly') {
        if (!this.isProcessing) {
          this.confirm = true;
        }
      }
    },
    checkConfirm: function(confirmed) {
      this.confirm = false;
      this.isProcessing = true;
      axios.put(`/api/users/change_to_monthly_plan`).then(response => {
        location.href = "/users/me/membership";
      }).catch(e => {
        this.paymentError = true;
        this.isProcessing = false;
      });
    },
    closePop: function(e) {
      this.confirm = false;
    },
    closeError: function() {
      location.href = '/users/me/cards';
    },
  },
  validations: {
  },
  components: {
    OrderLayerPop, MiilkButton, PlanCardItem
  }
}
</script>
