<template>
  <footer class="footer">
    <div class="content">
      <div class="link-container">
        <ul class="menus">
          <li class="menu">
            <a href="/about" class="service-name">회사소개</a>
          </li>

          <li class="menu">
            <a href="/faq" class="service-name">자주 묻는 질문</a>
          </li>

          <li class="menu">
            <a href="/business" class="service-name">더밀크 기업구독 안내</a>
          </li>

          <li class="menu">
            <a href="/terms" class="service-name">이용약관</a>
          </li>

          <li class="menu">
            <a href="/privacy" class="service-name">개인정보처리방침</a>
          </li>
        </ul>

        <div class="side-container">
          <ul class="social-service">
            <li class="item">
              <a href="https://www.facebook.com/themiilkinc" class="service-name" target="_blank">
                <img src="https://assets.themiilk.com/common/sns-facebook.svg" class="Facebook" />
              </a>
            </li>

            <li class="item">
              <a href="https://www.youtube.com/themiilk" class="service-name" target="_blank">
                <img src="https://assets.themiilk.com/common/sns-youtube.svg" class="Youtube" />
              </a>              
            </li>

            <li class="item">
              <a href="https://www.linkedin.com/company/the-miilk" class="service-name" target="_blank">
                <img src="https://assets.themiilk.com/common/sns-linkedin.svg" class="LinkedIn" />
              </a>                            
            </li>

            <li class="item">
              <a href="https://twitter.com/TheMiilkInc" class="service-name" target="_blank">
                <img src="https://assets.themiilk.com/common/sns-twitter.svg" class="Twitter" />
              </a>                                          
            </li>
          </ul>

          <div class="address label-xsmall">
            1900 Embarcadero Rd #202 <br/>
            Palo Alto, CA 94303
          </div>
        </div>
      </div>
      <p class="copyright">© The Miilk. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
    }
  },
}
</script>