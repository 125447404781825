var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.article
    ? _c(
        "ng-container",
        [
          !_vm.article.author_list
            ? _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.article.author_names[0]))
              ])
            : _vm._l(_vm.article.author_list, function(author, index) {
                return _c(
                  "span",
                  { key: "author-" + author.name, staticClass: "bold" },
                  [
                    _vm._v(_vm._s(index !== 0 ? " · " : "") + "\n    "),
                    author.reporter
                      ? _c("ng-container", [
                          _c(
                            "a",
                            {
                              staticClass: "bold",
                              attrs: { href: "/reporter/" + author.id }
                            },
                            [_vm._v(_vm._s(author.name))]
                          )
                        ])
                      : _c("ng-container", [
                          _vm._v("\n      " + _vm._s(author.name) + "\n    ")
                        ])
                  ],
                  1
                )
              })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }