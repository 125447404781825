import { render, staticRenderFns } from "./result.vue?vue&type=template&id=87033478&"
import script from "./result.vue?vue&type=script&lang=js&"
export * from "./result.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jaehwanlee/workspace/themiilk/themiilk-webapp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('87033478')) {
      api.createRecord('87033478', component.options)
    } else {
      api.reload('87033478', component.options)
    }
    module.hot.accept("./result.vue?vue&type=template&id=87033478&", function () {
      api.rerender('87033478', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/front/components/portfolios/result.vue"
export default component.exports