<template>
  <div class="coupon-container">
    <template v-if="user != null">
      <div class="content padding" v-if="usable && user.is_subscribe">
        <div class="image-wrapper remove-line-height">
          <img
            src="https://assets.themiilk.com/common/illust-coupon-registered.png"
            class="icon"
          />
        </div>

        <div class="text-wrapper">
          <h2 class="title label-sub-title bold">
            쿠폰이 등록되었습니다.
          </h2>
        </div>

        <div class="button-wrapper">
          <a href="/" class="button button-box round no-deco">
            더밀크 홈으로가기
          </a>
        </div>
      </div>

      <div class="content padding" v-else-if="usable && !user.is_subscribe">
        <div class="image-wrapper remove-line-height">
          <img
            src="https://assets.themiilk.com/common/illust-coupon-registered.png"
            class="icon"
          />
        </div>

        <div class="text-wrapper">
          <h2 class="title label-sub-title bold">
            쿠폰이 등록되었습니다.
          </h2>
          <p class="description label-small-description">
            구독하시면 더 많은 정보를 얻을 수 있습니다.
          </p>
        </div>

        <div class="button-wrapper">
          <a href="/orders/plans" class="button button-box round no-deco">
            구독하러가기
          </a>
        </div>
      </div>

      <div class="content padding" v-else-if="!usable">
        <div class="image-wrapper remove-line-height">
          <img
            src="https://assets.themiilk.com/common/illust-coupon-failed.png"
            class="icon"
          />
        </div>

        <div class="text-wrapper">
          <h2 class="title label-sub-title bold">
            사용 할 수 없는 쿠폰입니다.
          </h2>
          <p class="description label-small-description">
            쿠폰 코드를 다시 확인 하시거나 고객센터에 문의 해주세요.
          </p>
        </div>

        <div class="button-wrapper">
          <a href="/orders/plans" class="button button-box round no-deco">
            구독하러가기
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      user: this.getUser(),
      usable: true,
      couponNumber: this.$route.params.couponNumber,
    };
  },
  mounted() {
    if (this.user == null) {
      location.href = `/login?url=${this.$route.path}`;
    }

    const self = this;
    axios
      .put(`/api/coupons/${this.couponNumber}/new`)
      .then((res) => {
        self.user = res.data.user;

        if (!self.user.is_subscribe) {
          location.href = '/orders/plans';
        }
      })
      .catch((error) => {
        if (error.response.status == 403) {
          self.user = {};
          self.usable = false;
        }
      });
  },
  computed: {},
  methods: {
    getUser() {
      const userInfo = localStorage.getItem("miilkUser");
      if (userInfo != null && userInfo.length > 0) {
        return JSON.parse(userInfo);
      }

      return null;
    },
  },
};
</script>
