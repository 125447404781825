<template>
  <div class="popup-container">
    <div
        class="popup-item reservation guest"
        :class="{ show: isShow }"
    >
      <div class="state-wrapper remove-line-height">
        <img
            class="image"
            src="https://assets.themiilk.com/commons/icon-error.svg"
            alt=""
        />
      </div>

      <div class="popup-wrapper c-black">
        <div class="text-wrapper">
          <h2 class="popup-title label-middle-title bold">
            {{title}}
          </h2>

          <p class="popup-description label-small-description">
            {{description}}
          </p>
        </div>
      </div>

      <div class="button-wrapper">
        <button
            class="button button-box round large grey no-deco c-white"
            v-on:click="closePopup"
        >
          닫기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    }
  },
  data() {
    return {};
  },
  methods: {
    closePopup() {
      this.$emit('close');
    }
  }
}
</script>