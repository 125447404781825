var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reporter
    ? _c("div", { staticClass: "reporter-landing-head" }, [
        _c(
          "div",
          {
            staticClass: "background-image remove-line-height",
            class: { active: _vm.show }
          },
          [
            _c("img", {
              staticClass: "background",
              attrs: { src: _vm.reporter.background_url, alt: "" }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "content single" }, [
          _c(
            "div",
            {
              staticClass: "image-wrapper remove-line-height",
              class: { active: _vm.show }
            },
            [
              _c("img", {
                staticClass: "profile",
                attrs: { src: _vm.reporter.profile_url, alt: "" }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-wrapper" }, [
            _c("div", { staticClass: "option" }, [
              _c("div", { staticClass: "option-item location" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.reporter.location) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "option-item category" }, [
                _vm._v(_vm._s(_vm.reporter.interest))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name-wrapper" }, [
              _c(
                "h2",
                { staticClass: "name label-sub-title-small bold c-white" },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.reporter.name) + "\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "description label-small-description ellipsis three",
                class: { show: _vm.show }
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.reporter.description) }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "control-wrapper" }, [
              _c("div", { staticClass: "social remove-line-height" }, [
                _vm.reporter.sns
                  ? _c(
                      "a",
                      {
                        staticClass: "social-item telegram",
                        attrs: { href: _vm.reporter.sns }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "https://assets.themiilk.com/reporters/icon-telegram.svg",
                            alt: "telegram_icon"
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }