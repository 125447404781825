<template>
  <component :is="getLayout">
    <router-view />
  </component>
</template>

<script>
import BlankLayout from "./components/commons/blank_layout";
import Layout from "./components/commons/layout";

export default {
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    getLayout() {
      const blankLayout = this.$route.meta.blankLayout;

      if (blankLayout) {
        return 'BlankLayout';
      }
      else {
        return 'Layout';
      }
    }
  },
  components: {
    BlankLayout, Layout
  },
  mounted() {
  },
  watch: {
  },
};
</script>
