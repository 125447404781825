import Vue from 'vue';

function isObject (value) {
  return value === Object(value)
}

function isArray (value) {
  return Array.isArray(value)
}

function isFile (value) {
  return value instanceof File
}

Date.prototype.format = function(f) {

};

export const LIST_SURVEY_ANSWER_TYPES = ['choice', 'checkboxes'];
export const DEFAULT_SURVEY_QUESTIONS = [
  { category: 'username', answer_type: 'text', question: '이름' },
	{ category: 'email', answer_type: 'text', question: 'email' },
	{ category: 'birth_date', answer_type: 'date', question: '생년월일' },
	{ category: 'company', answer_type: 'text', question: '회사' },
	{ category: 'position', answer_type: 'text', question: '직급' },
  { category: 'department', answer_type: 'text', question: '부서' },
  { category: 'gender', answer_type: 'choice', question: '성별', survey_question_options_attributes: [{ name: '남', matched_value: 'm' }, { name: '여',  matched_value: 'w' }] },
  { category: 'marital_status', answer_type: 'choice', question: '결혼여부', survey_question_options_attributes: [{ name: '기혼', matched_value: 'true' }, { name: '미혼', matched_value: 'false' }] },
  { category: 'home_area', answer_type: 'area', question: '거주지역' }
];

export default {
  cleanup(object, excludedKeys) {
    return _.omitBy(object,
      (value, key) => { return _.includes(excludedKeys, key) || _.isNull(value) }
    );
  },
  cleanupCollection(collection, excludedKeys) {
    return _.map(collection, item => this.cleanup(item, excludedKeys))
  },
  queryParams() {
    return document.location.search.replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = decodeURIComponent(n[1]),this}.bind({}))[0];
  },
  objectToFormData(obj, fd, pre) {
    fd = fd || new FormData();
    let that = this;

    Object.keys(obj).forEach(function (prop) {
      let key = pre ? (pre + '[' + prop + ']') : prop;

      if (isObject(obj[prop]) && !isArray(obj[prop]) && !isFile(obj[prop])) {
        that.objectToFormData(obj[prop], fd, key)
      } else if (isArray(obj[prop])) {
        obj[prop].forEach(function (value, index) {
          if (isObject(value) && !isFile(value)) {
            let objectKey = key + '[' + index + ']';
            that.objectToFormData(value, fd, objectKey)
          } else {
            let arrayKey = key + '[]';
            fd.append(arrayKey, value)
          }
        })
      } else {
        fd.append(key, obj[prop])
      }
    });

    return fd
  },
  formTitle(objectId, name, defaultTitle) {
    const trimmedName = _.trim(name);

    if (objectId) {
      return `#${objectId} ${trimmedName}`;
    } else {
      return trimmedName || defaultTitle;
    }
  },
  truncate(str, max) {
    if (!str) {
      return ''
    }

    return str.length > max ? str.substr(0, max - 1) + '…' : str;
  },
  numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '0';
    }
  },
  mountComponent(targetElementId, component) {
    const targetElement = document.getElementById(targetElementId);
    if (targetElement) {
      const props = JSON.parse(targetElement.getAttribute('data'));

      new Vue({
        render: h => h(component, {
          props
        })
      }).$mount(targetElement);
    }
  },
  dateToString(date) {
    return date.getFullYear() + "." + (date.getMonth() + 1) + "." + date.getDate();
  },
  intToDateString(d) {
    if (d == null) {
      return '';
    }
    const date = new Date(d * 1000);
    return date.getFullYear() + "." + (date.getMonth() + 1) + "." + date.getDate();
  }
}
