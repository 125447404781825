var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "reporter-landing" },
    [
      _vm.reporter
        ? _c("landing-head", { attrs: { reporter: _vm.reporter } })
        : _vm._e(),
      _vm._v(" "),
      _vm.articles
        ? _c("landing-result", {
            attrs: {
              articles: _vm.articles,
              latest: _vm.latest,
              totalCount: _vm.totalCount
            },
            on: { changeOrder: _vm.changeOrder }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "reporter-landing-pagenation" }, [
        _c("div", { staticClass: "content single" }, [
          _c("div", { staticClass: "pagination-wrapper" }, [
            _c("ul", { staticClass: "pagination" }, [
              _c("li", { staticClass: "page-item prev" }, [
                _c(
                  "div",
                  {
                    staticClass: "page-link",
                    class: { "link-a": _vm.page != 1 },
                    on: {
                      click: function($event) {
                        return _vm.pageClick(_vm.page - 1)
                      }
                    }
                  },
                  [_vm._m(0), _vm._v(" "), _c("span", [_vm._v("이전")])]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "number" },
                _vm._l(this.pageCount, function(idx, index) {
                  return _vm.liPageNumber(idx) > 0 && index < 5
                    ? _c(
                        "li",
                        {
                          key: idx,
                          staticClass: "page-item page-link",
                          class: { active: _vm.liPageNumber(idx) == _vm.page },
                          on: {
                            click: function($event) {
                              _vm.pageClick(_vm.liPageNumber(idx))
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.liPageNumber(idx)) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "page-item next",
                  class: { ex: _vm.lastPage() }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-link",
                      class: { "link-a": !_vm.lastPage() },
                      on: {
                        click: function($event) {
                          return _vm.pageClick(_vm.page + 1)
                        }
                      }
                    },
                    [_c("span", [_vm._v("다음")]), _vm._v(" "), _vm._m(1)]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "icon" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }