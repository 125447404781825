var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkbox-input", class: { error: _vm.v.$error } },
    [
      _c("input", {
        staticClass: "form-control",
        class: { error: _vm.v.$error, toggle: _vm.isToggle },
        attrs: { type: "checkbox", disabled: _vm.disabled, id: _vm.pk },
        domProps: { checked: _vm.checked },
        on: {
          input: function($event) {
            return _vm.onInput($event.target.checked)
          },
          click: _vm.onClick
        }
      }),
      _vm._v(" "),
      _vm.isToggle
        ? _c("i", { staticClass: "material-icons toggle-off" }, [
            _vm._v("toggle_off")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isToggle
        ? _c("i", { staticClass: "material-icons toggle-on" }, [
            _vm._v("toggle_on")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isToggle
        ? _c("i", { staticClass: "material-icons checked-icon" }, [
            _vm._v("check_box")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("label", {
        staticClass: "label",
        attrs: { for: _vm.pk },
        domProps: { innerHTML: _vm._s(_vm.label) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }