var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "portfolio-landing" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("section", { staticClass: "portfolio-landing-content result" }, [
      _c("div", { staticClass: "content single" }, [
        _c("div", { staticClass: "box-wrapper c-black" }, [
          _c(
            "div",
            { staticClass: "box-step label-small-description bold c-white" },
            [_vm._v("\n          DONE\n        ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "box" }, [
            _vm._m(2),
            _vm._v(" "),
            _vm.performanceLoading || _vm.portfolioLoading
              ? _c("div", { staticClass: "box-content" }, [_vm._m(3)])
              : _c("div", { staticClass: "box-content" }, [
                  _vm.alphaPortfolio.length == 0
                    ? _c("div", { staticClass: "working-wrapper" }, [
                        _vm._m(4),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-wrapper" }, [
                          _c(
                            "h3",
                            { staticClass: "point label-small-title bold" },
                            [
                              _vm._v(
                                "\n                  포트폴리오 진단이 진행중입니다…\n                  "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "description label-small-description normal"
                            },
                            [
                              _vm._v(
                                "\n                  입력하신 포트폴리오를 최적화를 하는 중입니다. 잠시만\n                  기다려주세요.\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "button button-box xsmall button-refresh",
                              on: { click: _vm.reload }
                            },
                            [
                              _vm._v(
                                "\n                  데이터 새로고침\n                  "
                              ),
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src:
                                    "https://assets.themiilk.com/portfolio/icon-refresh.svg"
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.alphaPortfolio.length > 0 && this.result.length > 1
                    ? _c("div", { staticClass: "compare-wrapper" }, [
                        _c("div", { staticClass: "compare-list" }, [
                          _c("div", { staticClass: "text-wrapper" }, [
                            _vm._m(5),
                            _vm._v(" "),
                            _c("div", { staticClass: "data-wrapper my" }, [
                              _c("div", { staticClass: "data-item point" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-point label-middle-description bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        내 포트폴리오\n                      "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-value label-middle-description bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.amountFormat(
                                            _vm.userPerformance["nav"],
                                            "USD",
                                            true
                                          )
                                        ) +
                                        "\n                      "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "data-item small" }, [
                                _c(
                                  "div",
                                  { staticClass: "data-point label-xsmall" },
                                  [
                                    _vm._v(
                                      "\n                        최초 진단일부터의 수익\n                      "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "data-value label-xsmall" },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.amountFormat(
                                            _vm.userPerformance["gain"] || 0,
                                            "USD",
                                            true,
                                            false,
                                            true,
                                            true
                                          )
                                        ) +
                                        "\n                      "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "data-wrapper alphametrica" },
                              [
                                _c("div", { staticClass: "data-item point" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-point label-middle-description bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                        추천 포트폴리오\n                      "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-value label-middle-description bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.amountFormat(
                                              _vm.alphaPerformance["nav"],
                                              "USD",
                                              true
                                            )
                                          ) +
                                          "\n                      "
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "data-item small" }, [
                                  _c(
                                    "div",
                                    { staticClass: "data-point label-xsmall" },
                                    [
                                      _vm._v(
                                        "\n                        최초 진단일부터의 수익\n                      "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "data-value label-xsmall" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.amountFormat(
                                              _vm.alphaPerformance["gain"] || 0,
                                              "USD",
                                              true,
                                              false,
                                              true,
                                              true
                                            )
                                          ) +
                                          "\n                      "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-wrapper" }, [
                            _vm._m(6),
                            _vm._v(" "),
                            _c("div", { staticClass: "data-wrapper my" }, [
                              _c("div", { staticClass: "data-item point" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-point label-middle-description bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        내 포트폴리오\n                      "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-value label-small-title bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.userPerformance[
                                              "abs_irr_max"
                                            ] || 0
                                          ) > 0
                                            ? "+" +
                                                (
                                                  _vm.userPerformance[
                                                    "abs_irr_max"
                                                  ] || 0
                                                ).toFixed(2)
                                            : (
                                                _vm.userPerformance[
                                                  "abs_irr_max"
                                                ] || 0
                                              ).toFixed(2)
                                        ) +
                                        "\n                        %\n                      "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "data-wrapper alphametrica" },
                              [
                                _c("div", { staticClass: "data-item point" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-point label-middle-description bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                        추천 포트폴리오\n                      "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "data-value label-small-title bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            parseFloat(
                                              _vm.alphaPerformance[
                                                "abs_irr_max"
                                              ] || 0
                                            ) > 0
                                              ? "+" +
                                                  (
                                                    _vm.alphaPerformance[
                                                      "abs_irr_max"
                                                    ] || 0
                                                  ).toFixed(2)
                                              : (
                                                  _vm.alphaPerformance[
                                                    "abs_irr_max"
                                                  ] || 0
                                                ).toFixed(2)
                                          ) +
                                          "\n                        %\n                      "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "chart-wrapper" }, [
                          _c(
                            "div",
                            { staticClass: "chart" },
                            [
                              _c("GChart", {
                                ref: "gChart",
                                attrs: {
                                  type: "LineChart",
                                  data: _vm.chartData,
                                  settings: { packages: ["corechart"] },
                                  options: _vm.chartOptions,
                                  events: _vm.chartEvents
                                },
                                on: { ready: _vm.onChartReady }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._m(7)
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "guide-wrapper label-small-description"
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                  자산 가치는 미국시장\n                  " +
                                  _vm._s(
                                    parseInt(_vm.today.substring(0, 4)) +
                                      "년 " +
                                      parseInt(_vm.today.substring(4, 6)) +
                                      "월 " +
                                      parseInt(_vm.today.substring(6, 8)) +
                                      "일"
                                  ) +
                                  "\n                  종가를 기준으로 계산되었습니다.\n                "
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "bar-charts-wrapper",
                      class: {
                        only:
                          this.alphaRiskToReturn == 0 &&
                          this.userRiskToReturn == 0
                      }
                    },
                    [
                      _vm.alphaPortfolio.length > 0
                        ? _c("div", { staticClass: "bar-chart-wrapper" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "title label-middle-description bold"
                              },
                              [
                                _vm._v(
                                  "\n                  포트폴리오 비중\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "description normal label-xsmall"
                              },
                              [
                                _vm._v(
                                  "\n                  alphametrica 알고리즘은 위험대비 수익을 개선할 비중을\n                  추천합니다.\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "bar-chart-item-wrapper current" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "title label-xsmall bold" },
                                  [
                                    _vm._v(
                                      "\n                    MY\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "bar-chart-item-list" },
                                  _vm._l(this.compareTable, function(
                                    item,
                                    idx
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "bar-chart-item",
                                        class: [
                                          (item.user_rate * 100).toFixed(0) == 0
                                            ? "zero"
                                            : ""
                                        ],
                                        style:
                                          "--width: " +
                                          (item.user_rate * 100).toFixed(1) +
                                          "%"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "color",
                                            class: "c-" + idx
                                          },
                                          [
                                            _c("div", { staticClass: "base" }),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "over" })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.ticker !== "cash" &&
                                        item.user_rate * 100 > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "label-xxsmall" },
                                              [_vm._v(_vm._s(item.ticker))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.ticker === "cash"
                                          ? _c(
                                              "span",
                                              { staticClass: "label-xxsmall" },
                                              [_vm._v(_vm._s("Cash"))]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "bar-chart-item-wrapper recommand"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "title label-xsmall bold" },
                                  [
                                    _vm._v(
                                      "\n                    추천\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "bar-chart-item-list" },
                                  _vm._l(this.compareTable, function(
                                    item,
                                    idx
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "bar-chart-item",
                                        class: [
                                          (item.alpha_rate * 100).toFixed(0) ==
                                          0
                                            ? "zero"
                                            : ""
                                        ],
                                        style:
                                          "--width: " +
                                          (item.alpha_rate * 100).toFixed(1) +
                                          "%"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "color",
                                            class: "c-" + idx
                                          },
                                          [
                                            _c("div", { staticClass: "base" }),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "over" })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.ticker !== "cash" &&
                                        item.alpha_rate * 100 > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "label-xxsmall" },
                                              [_vm._v(_vm._s(item.ticker))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.ticker === "cash"
                                          ? _c(
                                              "span",
                                              { staticClass: "label-xxsmall" },
                                              [_vm._v(_vm._s("Cash"))]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      this.userRiskToReturn > 0 &&
                      this.alphaRiskToReturn > 0 &&
                      _vm.alphaPortfolio.length > 0
                        ? _c("div", { staticClass: "double-side-bar-chart" }, [
                            _c(
                              "div",
                              { staticClass: "double-side-bar-chart-wrapper" },
                              [
                                _vm._m(8),
                                _vm._v(" "),
                                _c("div", { staticClass: "head-wrapper" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "title label-small-description bold"
                                    },
                                    [
                                      _vm._v(
                                        "\n                      위험대비 기대수익\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button button-show-detail medium",
                                      on: {
                                        click: function($event) {
                                          return _vm.showPopup(
                                            "expectation_modal"
                                          )
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("자세히 보기")])]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "chart-wrapper" }, [
                                  _c(
                                    "div",
                                    { staticClass: "bar-item current" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "color",
                                          style:
                                            "--percent: " +
                                            (
                                              this.userRiskToReturn * 100
                                            ).toFixed(1) +
                                            "%"
                                        },
                                        [
                                          _c("div", { staticClass: "text" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "percentage bold",
                                                class:
                                                  (
                                                    this.userRiskToReturn * 100
                                                  ).toFixed(1) > 38 && "c-white"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.userRiskToReturn.toFixed(
                                                      3
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "bar-item recommand" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "color",
                                          style:
                                            "--percent: " +
                                            (
                                              this.alphaRiskToReturn * 100
                                            ).toFixed(1) +
                                            "%"
                                        },
                                        [
                                          _c("div", { staticClass: "text" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "percentage bold",
                                                class:
                                                  (
                                                    this.userRiskToReturn * 100
                                                  ).toFixed(1) > 38 && "c-white"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.alphaRiskToReturn.toFixed(
                                                      3
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "message-wrapper label-small"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    기존 포트폴리오 대비 추천 포트폴리오의 위험대비 기대수익이\n                    "
                                    ),
                                    _c("em", [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            ((this.alphaRiskToReturn -
                                              this.userRiskToReturn) /
                                              this.userRiskToReturn) *
                                            100
                                          ).toFixed(1)
                                        ) + "%"
                                      )
                                    ]),
                                    _vm._v(
                                      "\n                    더 높은 배분 입니다.\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.alphaPortfolio.length > 0
                    ? _c("div", { staticClass: "portfolio-list-wrapper" }, [
                        _vm._m(9),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "list-item-wrapper" },
                          _vm._l(this.compareTable, function(item, idx) {
                            return _c(
                              "div",
                              {
                                staticClass: "list-item",
                                class: { cash: item.ticker === "cash" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-section ticker label-small-description bold"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "ellipsis one" },
                                      [_vm._v(_vm._s(item.ticker))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "description label-xxsmall normal ellipsis"
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-section right current label-small-description bold"
                                  },
                                  [
                                    item.ticker === "cash"
                                      ? _c("span", { staticClass: "mark" })
                                      : _vm._e(),
                                    _vm._v(
                                      _vm._s(
                                        (item.user_rate * 100).toFixed(2)
                                      ) + "%\n                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "description label-xxsmall normal"
                                      },
                                      [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              item.user_amount.toFixed(2) || 0
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-section right recommand label-small-description bold",
                                    class: {
                                      plus: item.user_rate < item.alpha_rate,
                                      minus: item.user_rate > item.alpha_rate
                                    }
                                  },
                                  [
                                    item.ticker === "cash"
                                      ? _c("span", { staticClass: "mark" })
                                      : _vm._e(),
                                    _vm._v(
                                      _vm._s(
                                        (item.alpha_rate * 100).toFixed(2)
                                      ) + "%\n                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "description label-xxsmall normal"
                                      },
                                      [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              item.alpha_amount.toFixed(2) || 0
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "item-section row bar-graph" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "bar-item current" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "base-wrapper",
                                            class: {
                                              full: item.user_rate > 0.95,
                                              zero: item.user_rate === 0,
                                              up:
                                                item.user_rate <
                                                item.alpha_rate,
                                              down:
                                                item.user_rate > item.alpha_rate
                                            },
                                            style:
                                              "--height: " +
                                              (item.user_rate * 100).toFixed(
                                                1
                                              ) +
                                              "%"
                                          },
                                          [
                                            _c("div", { staticClass: "base" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "percent",
                                                  class: "c-" + idx,
                                                  style:
                                                    "--height: " +
                                                    (
                                                      item.user_rate * 100
                                                    ).toFixed(1) +
                                                    "%"
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "point",
                                                    class: "c-" + idx
                                                  })
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "copy label-xxsmall" },
                                          [_vm._v("보유")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(10, true),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "bar-item recommand" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "base-wrapper",
                                            class: {
                                              full: item.alpha_rate > 0.95,
                                              zero: item.alpha_rate === 0
                                            }
                                          },
                                          [
                                            _c("div", { staticClass: "base" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "percent",
                                                  class: "c-" + idx,
                                                  style:
                                                    "--height: " +
                                                    (
                                                      item.alpha_rate * 100
                                                    ).toFixed(1) +
                                                    "%"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "point",
                                                      class: "c-" + idx
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "icon-arrow",
                                                          class: {
                                                            up:
                                                              item.user_rate <
                                                              item.alpha_rate,
                                                            down:
                                                              item.user_rate >
                                                              item.alpha_rate
                                                          }
                                                        },
                                                        [
                                                          item.user_rate !=
                                                          item.alpha_rate
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "arrow"
                                                              })
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "copy label-xxsmall" },
                                          [_vm._v("추천")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm._m(11)
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.performanceLoading && !_vm.portfolioLoading
                    ? _c(
                        "div",
                        { staticClass: "box-lower-message flip" },
                        _vm._l(_vm.resultMessages, function(item, index) {
                          return _c(
                            "div",
                            {
                              staticClass: "flip-message",
                              class: { show: _vm.resultMessages[index].show }
                            },
                            [
                              _c("div", { staticClass: "message-header" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "message-title label-small-title bold"
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                                _vm._v(" "),
                                !_vm.resultMessages[index].show
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button button-rounded bordered xsmall",
                                        on: {
                                          click: function($event) {
                                            {
                                              _vm.resultMessages[
                                                index
                                              ].show = true
                                            }
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.button.open))
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.resultMessages[index].show
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "button button-rounded arrow up xsmall",
                                        on: {
                                          click: function($event) {
                                            {
                                              _vm.resultMessages[
                                                index
                                              ].show = false
                                            }
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.button.close))
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass: "message-list",
                                  class: {
                                    show: _vm.resultMessages[index].show
                                  }
                                },
                                _vm._l(item.descriptions, function(
                                  message,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    {
                                      staticClass:
                                        "message-item label-small-description"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(message) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "box-lower-message inside" }, [
                    _vm.userStatus.transactionInit == false
                      ? _c("p", { staticClass: "message" }, [
                          _vm._v("\n                포트폴리오를 "),
                          _c("b", [_vm._v("확정")]),
                          _vm._v(
                            "하시면, 미국 시장 종가로 평가된 성과를\n                매일 확인 하실 수 있습니다.\n              "
                          )
                        ])
                      : _vm.userStatus.transactionInit == true
                      ? _c("p", { staticClass: "message" }, [
                          _vm._v(
                            "\n                고객님의 포트폴리오와 alphametrica의 포트폴리오 가치가 매일\n                평가 됩니다."
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                주기적으로 방문하셔서 성과를 비교해 보세요.\n              "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "message caution" }, [
                      _vm._v(
                        "\n                추천된 포트폴리오의 비중은 참고사항이며 투자에 대한 최종\n                책임은 사용자에게 있습니다.\n              "
                      )
                    ])
                  ])
                ])
          ]),
          _vm._v(" "),
          _vm.alphaPortfolio.length > 0
            ? _c("div", { staticClass: "box-button-wrapper" }, [
                _vm.userStatus.transactionInit == false &&
                _vm.userStatus.initCheck < 5
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "button button-rounded shadow hover no-deco",
                        on: { click: _vm.checkAgain }
                      },
                      [
                        _vm._m(12),
                        _vm._v(" "),
                        _c("span", { staticClass: "balloon" }, [
                          _vm._v(
                            _vm._s(5 - _vm.userStatus.initCheck) + "회 남음"
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userStatus.transactionInit == true
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "button button-rounded shadow hover no-deco modify",
                        class: { disabled: _vm.userStatus.updateable != true },
                        on: { click: _vm.checkAgain }
                      },
                      [
                        _vm._m(13),
                        _vm._v(" "),
                        _vm.userStatus.updateable == false
                          ? _c("span", { staticClass: "balloon" }, [
                              _vm._v("다음 영업일 장종료 후 가능")
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.userStatus.transactionInit == false
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "button button-rounded shadow hover confirm no-deco",
                        on: {
                          click: function($event) {
                            return _vm.showPopup("confirm_modal")
                          }
                        }
                      },
                      [_vm._m(14)]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(15),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "popup-container", class: { active: _vm.currentPopup } },
      [
        _c("div", { staticClass: "popup-background" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item reservation guest",
            class: {
              show: _vm.popups.confirm_modal
            }
          },
          [
            _vm._m(16),
            _vm._v(" "),
            _vm._m(17),
            _vm._v(" "),
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large grey no-deco c-white",
                  on: { click: _vm.closePopup }
                },
                [_vm._v("\n          닫기\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large bright-blue no-deco c-white",
                  attrs: { disabled: _vm.isLoading },
                  on: { click: _vm.confirm }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.isLoading ? "확인중" : "확인") +
                      "\n        "
                  )
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item reservation guest",
            class: {
              show: _vm.popups.alpha_modal
            }
          },
          [
            _vm._m(18),
            _vm._v(" "),
            _vm._m(19),
            _vm._v(" "),
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large grey no-deco c-white",
                  on: { click: _vm.closePopup }
                },
                [_vm._v("\n          닫기\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large bright-blue no-deco c-white",
                  attrs: { disabled: _vm.isLoading },
                  on: { click: _vm.sendEmail }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.isLoading ? "확인중" : "확인") +
                      "\n        "
                  )
                ]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item guide",
            class: {
              show: _vm.popups.expectation_modal
            }
          },
          [
            _vm._m(20),
            _vm._v(" "),
            _c("div", { staticClass: "popup-wrapper c-black" }, [
              _vm._m(21),
              _vm._v(" "),
              _c("div", { staticClass: "chart-wrapper" }, [
                _c("div", { staticClass: "single-bar-chart-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "single-bar-chart-item-wrapper current" },
                    [
                      _c("div", { staticClass: "bar-chart-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "color low",
                            style:
                              "--height: " +
                              (this.userRiskToReturn * 100).toFixed(1) +
                              "%"
                          },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._m(22),
                              _vm._v(" "),
                              _c("span", { staticClass: "percentage" }, [
                                _vm._v(_vm._s(this.userRiskToReturn.toFixed(3)))
                              ])
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "label label-xsmall bold" }, [
                        _vm._v("MY")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "single-bar-chart-item-wrapper recommand" },
                    [
                      _c("div", { staticClass: "bar-chart-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "color",
                            style:
                              "--height: " +
                              (this.alphaRiskToReturn * 100).toFixed(1) +
                              "%"
                          },
                          [
                            _c("div", { staticClass: "text" }, [
                              _vm._m(23),
                              _vm._v(" "),
                              _c("span", { staticClass: "percentage" }, [
                                _vm._v(
                                  _vm._s(this.alphaRiskToReturn.toFixed(3))
                                )
                              ])
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "label label-xsmall bold" }, [
                        _vm._v("추천")
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "poll-message-wrapper" }, [
                _c(
                  "p",
                  { staticClass: "popup-description label-small-description" },
                  [_vm._v("\n            설명에 만족 하셨나요?\n          ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "icon thumbs-up",
                    on: {
                      click: function($event) {
                        return _vm.clickThumbs("up")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "https://assets.themiilk.com/portfolio/icon-like.svg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "icon thumbs-down",
                    on: {
                      click: function($event) {
                        return _vm.clickThumbs("down")
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "https://assets.themiilk.com/portfolio/icon-unlike.svg"
                      }
                    })
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large bright-blue no-deco c-white",
                  on: { click: _vm.closePopup }
                },
                [_vm._v("\n          닫기\n        ")]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "portfolio-landing-background result" },
      [
        _c("div", { staticClass: "image-wrapper middle remove-line-height" }, [
          _c("img", {
            staticClass: "image",
            attrs: {
              src:
                "https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-middle.png",
              alt: ""
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "image-wrapper middle effect remove-line-height" },
          [
            _c("img", {
              staticClass: "image",
              attrs: {
                src:
                  "https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-middle-effect.png",
                alt: ""
              }
            })
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "portfolio-landing-hero result" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "current-page" }, [
          _c("h2", { staticClass: "name c-white" }, [_vm._v("Portfolio")]),
          _vm._v(" "),
          _c("p", { staticClass: "description c-white" }, [
            _vm._v(
              "\n          더밀크에서 제공하는 최고 수준의 포트폴리오 진단을 경험하세요.\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section-title" }, [
          _c("img", {
            staticClass: "main",
            attrs: {
              src:
                "https://assets.themiilk.com/portfolio/logo-themiilk-rich.svg",
              alt: "더밀크.리치"
            }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "sub",
            attrs: {
              src:
                "https://assets.themiilk.com/portfolio/logo-powered-by-alphametrica.svg",
              alt: "Powered by alphametrica"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "box-head compare label-sub-title-small" },
      [
        _c("div", { staticClass: "text-wrapper" }, [
          _c("span", { staticClass: "section-title bold" }, [
            _vm._v("포트폴리오 비교")
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "section-description normal label-small-description"
            },
            [
              _vm._v(
                "\n                추천된 포트폴리오의 비중을 입력하신 포트폴리오와 비교해 보세요\n              "
              )
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "working-wrapper" }, [
      _c("div", { staticClass: "image-wrapper remove-line-height" }, [
        _c("img", {
          attrs: {
            src: "https://assets.themiilk.com/portfolio/icon-analytics.svg",
            alt: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-wrapper" }, [
        _c("h3", { staticClass: "point label-small-title bold" }, [
          _vm._v(
            "\n                  데이터를 받아 오는 중입니다.\n                "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "description label-small-description normal" }, [
          _vm._v(
            "\n                  사용자의 환경에 따라 응답이 다소 늦을 수 있습니다. 잠시만\n                  기다려주세요.\n                "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        attrs: {
          src: "https://assets.themiilk.com/portfolio/icon-analytics.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head-wrapper" }, [
      _c("h2", { staticClass: "point label-small-title bold" }, [
        _vm._v("전체자산")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "head-wrapper" }, [
      _c("h2", { staticClass: "point label-small-title bold" }, [
        _vm._v("수익률")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-wrapper" }, [
      _c("button", { staticClass: "button button-bordered round active" }, [
        _vm._v("\n                    주간\n                  ")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button button-bordered round disabled" }, [
        _vm._v("\n                    월간\n                  ")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button button-bordered round disabled" }, [
        _vm._v("\n                    분기\n                  ")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button button-bordered round disabled" }, [
        _vm._v("\n                    년\n                  ")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button button-bordered round disabled" }, [
        _vm._v("\n                    최대\n                  ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "point-icon remove-line-height" }, [
      _c("img", {
        attrs: {
          src: "https://assets.themiilk.com/portfolio/icon-risk.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-item head-wrapper" }, [
      _c("div", { staticClass: "item-section ticker label-xsmall bold" }, [
        _vm._v("\n                  Ticker\n                  "),
        _c("span", { staticClass: "description label-xxsmall normal" }, [
          _vm._v("종목명")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-section current label-xsmall bold" }, [
        _vm._v("보유")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-section recommand label-xsmall bold" }, [
        _vm._v("\n                  추천\n                ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-section bar-graph label-xsmall bold" }, [
        _vm._v("\n                  차이\n                ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bar-item explain" }, [
      _c("div", { staticClass: "range" }, [
        _c("div", { staticClass: "high" }, [_vm._v("100%")]),
        _vm._v(" "),
        _c("div", { staticClass: "low" }, [_vm._v("0%")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "copy label-xxsmall" }, [_vm._v("범위")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ticker-popup" }, [
      _c("div", { staticClass: "ticker-popup-wrapper" }, [
        _c("button", { staticClass: "button button-close" }),
        _vm._v(" "),
        _c("div", { staticClass: "head-wrapper" }, [
          _c(
            "h2",
            { staticClass: "section-title label-sub-title-small bold" },
            [_vm._v("\n                      JPM\n                    ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "section-description label-small-description" },
            [
              _vm._v(
                "\n                      Jpmorgan chase\n                    "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "list label-middle-description bold" }, [
          _c("li", { staticClass: "item" }, [
            _c("p", {}, [
              _vm._v("\n                        시가총액: "),
              _c("span", { staticClass: "thin" }, [_vm._v("$1,000,000,000")])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("p", {}, [
              _vm._v("현재가: "),
              _c("span", { staticClass: "thin" }, [_vm._v("$12")])
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("p", {}, [
              _vm._v("\n                        평가기관 컨센서스: "),
              _c("span", { staticClass: "thin" }, [_vm._v("Buy")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "consensus-bar label-xsmall" }, [
              _c(
                "div",
                {
                  staticClass: "bar-item buy",
                  staticStyle: { "--width": "60%" }
                },
                [
                  _vm._v(
                    "\n                          13\n                        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bar-item hold",
                  staticStyle: { "--width": "30%" }
                },
                [
                  _vm._v(
                    "\n                          3\n                        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bar-item sell",
                  staticStyle: { "--width": "10%" }
                },
                [
                  _vm._v(
                    "\n                          1\n                        "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "item" }, [
            _c("p", {}, [
              _vm._v(
                "\n                        목표가 컨센서스\n                      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dot-chart" }, [
              _c("div", { staticClass: "bar label-xxsmall" }, [
                _c(
                  "div",
                  {
                    staticClass: "dot-item current",
                    staticStyle: { "--position": "32.5%" }
                  },
                  [
                    _c("div", { staticClass: "label" }, [
                      _c("span", { staticClass: "point" }, [_vm._v("현재")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "price" }, [_vm._v("133.84")])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dot-item average",
                    staticStyle: { "--position": "42%" }
                  },
                  [
                    _c("div", { staticClass: "label" }, [
                      _c("span", { staticClass: "point" }, [_vm._v("평균")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "price" }, [_vm._v("138.84")])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "labels label-xxxsmall normal" }, [
                _c("div", { staticClass: "label" }, [
                  _c("span", { staticClass: "point" }, [_vm._v("최저")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price" }, [_vm._v("118.00")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "label" }, [
                  _c("span", { staticClass: "point" }, [_vm._v("최대")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "price" }, [_vm._v("174.00")])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-wrapper" }, [
          _c(
            "a",
            {
              staticClass: "button button-arrow label-small-description medium",
              attrs: { href: "#" }
            },
            [
              _vm._v(
                "\n                      JPMORGAN 관련 기사 보러가기\n                      "
              ),
              _c("div", { staticClass: "icon-arrow" }, [
                _c("div", { staticClass: "icon" })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "message" }, [
          _c("p", { staticClass: "label-small" }, [
            _vm._v(
              "\n                      본정보는 개별 분석기관이 분석한 정보를 수집한 단순\n                      데이터 입니다.\n                    "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "sub label-xxsmall" }, [
            _vm._v(
              "\n                      alphametrica 알고리즘은 위와 같은 정보외에\n                      개인투자자들이 접근할 수 없는 시장정보를 수집하여\n                      종합적으로 진단하고 포트폴리오 비중을 계산합니다.\n                    "
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("다시 진단"),
      _c("span", { staticClass: "fold-hide" }, [_vm._v("하기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("수정"),
      _c("span", { staticClass: "fold-hide" }, [_vm._v("하기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("확정"),
      _c("span", { staticClass: "fold-hide" }, [_vm._v("하기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "portfolio-landing-illust" }, [
      _c("div", { staticClass: "content remove-line-height" }, [
        _c("img", {
          staticClass: "icon-illust",
          attrs: {
            src: "https://assets.themiilk.com/portfolio/lower-illust.svg",
            alt: ""
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: " https://assets.themiilk.com/portfolio/icon-notice.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-wrapper c-black" }, [
      _c("div", { staticClass: "text-wrapper" }, [
        _c("h2", { staticClass: "popup-title label-middle-title bold" }, [
          _vm._v("\n            포트폴리오가 확정 됩니다.\n          ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "popup-description label-small-description" }, [
          _vm._v(
            "\n            확정된 포트폴리오는 매일 미국 시장 종료 후 가치 평가가 이뤄집니다.\n            주기적으로 방문 하셔서, 고객님의 포트폴리오와 alphametrica의\n            포트폴리오 성과를 비교해 보세요."
          ),
          _c("br"),
          _vm._v(
            "\n            포트폴리오가 확정 되면 하루 한번만 포트폴리오를 수정하고 진단하실\n            수 있습니다.\n            "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: " https://assets.themiilk.com/portfolio/icon-notice.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-wrapper c-black" }, [
      _c("div", { staticClass: "text-wrapper" }, [
        _c("h2", { staticClass: "popup-title label-middle-title bold" }, [
          _vm._v("\n            alphametrica로 이동합니다.\n          ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "popup-description label-small-description" }, [
          _vm._v(
            "\n            보다 장기간의 데이터를 확인하시려면, alphametrica 사이트를\n            방문해주세요. "
          ),
          _c("br"),
          _vm._v(
            "아래 확인 버튼을 누르시면 더밀크에 가입하신\n            이메일로 alphametrica에 로그인 링크가 전송 됩니다.\n          "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: "https://assets.themiilk.com/portfolio/icon-guide.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-wrapper" }, [
      _c("h2", { staticClass: "popup-title label-middle-title bold" }, [
        _vm._v("\n            위험대비 기대수익\n          ")
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "popup-description label-small-description text-red-400"
        },
        [
          _vm._v(
            "\n            더밀크리치의 포트폴리오 진단 알고리즘은 다양한 시장\n            데이터와 포트폴리오 이론에 기반하여 고객님이 입력한 포트폴리오의 위험대비\n            기대 수익을 최대화 하도록 개별 종목의 비중을 조정하여 추천 포트폴리오를 제시 합니다.\n          "
          )
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "popup-description label-small-description" }, [
        _vm._v(
          "\n            포트폴리오 진단 알고리즘은 입력하신 포트폴리오 대비 추천 포트폴리오의 \n            위험대비 기대 수익을 비교해 보세요!\n          "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "arrow" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "arrow" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }