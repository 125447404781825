var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-container" }, [
    _c("div", { staticClass: "dialog-background" }),
    _vm._v(" "),
    _c("div", { staticClass: "dialog-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "button button-circle close-btn",
          on: {
            click: function($event) {
              return _vm.closePop(false)
            }
          }
        },
        [
          _c("img", {
            staticClass: "icon-close",
            attrs: {
              src: "https://assets.themiilk.com/common/icon-close-white.svg",
              alt: "Close"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "dialog-box" }, [
        _c("div", { staticClass: "dialog-content" }, [
          _vm.title
            ? _c(
                "div",
                { staticClass: "section-title label-small-title bold" },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "content-wrapper label-middle-description",
            domProps: { innerHTML: _vm._s(_vm.content) }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dialog-button" }, [
          _c(
            "div",
            {
              staticClass: "button button-box cancel-btn",
              on: {
                click: function($event) {
                  return _vm.closePop(false)
                }
              }
            },
            [_vm._v("아니오")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "button button-box confirm-btn",
              on: {
                click: function($event) {
                  return _vm.closePop(true)
                }
              }
            },
            [_vm._v("예")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }