'use strict'
const sendHeapOrder = (res, data) => {
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
        event: "begin_purchase",
        ecommerce: {
            items: [
                {
                    item_id: data.plan_id,
                    item_name: data.plan_name,
                    item_category: "Subscription",
                    currency: "USD",
                    price: data.total_amount,
                    quantity: 1,
                },
            ],
        },
    });

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "purchase",
        ecommerce: {
            purchase: {
                actionField: {
                    id: res.data.order.id,
                    revenue: data.total_amount,
                },
                products: [
                    {
                        name: data.plan_id == 1 ? "monthly" : "yearly",
                        id: data.plan_id,
                        price: data.total_amount,
                        category: "Subscription",
                        quantity: 1,
                    },
                ],
            },
        },
    });

    if (data.coupon_id > 0) {
        heap.track("Purchase", {
            order_id: res.data.order.id,
            product_id: data.plan_id,
            product_name: data.plan_id == 1 ? "monthly" : "yearly",
            product_amount: data.total_amount,
            product_category: "Subscription",
            order_quntity: 1,
            order_currency: "USD",
            coupon_id: data.coupon_id,
            coupon_type_id: data.coupon_type_id,
            free_count: data.free_count,
        });
        heap.addUserProperties({
            coupon_id: data.coupon_id,
            coupon_type_id: data.coupon_type_id,
        });
    } else {
        heap.track("Purchase", {
            order_id: res.data.order.id,
            product_id: data.plan_id,
            product_name: data.plan_id == 1 ? "monthly" : "yearly",
            product_amount: data.total_amount,
            product_category: "Subscription",
            order_quntity: 1,
            order_currency: "USD",
            free_count: data.free_count,
        });
    }
}

export {
    sendHeapOrder
}