<template>
  <div class="reporter-landing-result">
    <section class="search-form-landing-tabs">
      <div class="content single set-underline">
        <ul class="tabs">
          <li class="tab">
            <div class="tab-item">News<span class="count">{{ totalCount }}</span></div>
          </li>
        </ul>

        <div class="sort">
          <button 
            class="sort-item"
            :class="{ active: latest }"
            @click="changeOrder()"
          >
            최신<span class="mobile-hide">순</span>
            <svg
              class="icon"
              width="12px"
              height="14px"
              viewBox="0 0 12 14"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g transform="translate(-1262.000000, -420.000000)">
                  <g transform="translate(388.000000, 403.000000)">
                    <g transform="translate(874.000000, 17.000000)">
                      <rect x="0" y="0" width="12" height="14"></rect>
                      <g
                        class="svg"
                        transform="translate(2.000000, 1.000000)"
                        fill-rule="nonzero"
                      >
                        <polygon
                          points="4.66156356 0 4.66150638 9.94940104 7.49524399 7.116677 8.25237799 7.873811 4.12618899 12 9.51016282e-16 7.873811 0.757133979 7.116677 3.59075724 9.94940104 3.59081442 1.90203256e-15"
                        ></polygon>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </section>

    <section class="search-form-landing-result">
      <div class="content single set-underline">
        <div class="list-wrapper" v-if="articles.length !== 0">
          <ul class="list short-top news">
            <li class="item" v-for="article in filterArticles(articles)">
              <article class="item-wrapper investing-item">
                <div class="image-wrapper remove-line-height">
                  <a :href="articleUrl(article)" @click="clickArticle(article)">
                    <div class="image-box" :style="heroImageUrl(article)">
                      <img class='blank-image' src="https://assets.themiilk.com/common/blank-6-4.png" alt='' />
                    </div>
                  </a>
                </div>

                <div class="text-wrapper">
                  <ul class="tags" v-if="article.tags.length > 0">
                    <li class="tag" v-for="tag in article.tags">
                      <a :href="tagUrl(tag)">
                        {{ tag.name }}
                      </a>
                    </li>
                  </ul>

                  <a :href="articleUrl(article)" @click="clickArticle(article)">
                    <h2 class="title label-middle-title bold ellipsis one">
                      {{ article.title }}
                    </h2>
                  </a>

                  <a class="no-deco" :href="articleUrl(article)" v-if="article.first_text" @click="clickArticle(article)">
                    <p class="description label-small-description ellipsis three">
                      {{ stripTag(article.first_text) }}
                    </p>
                  </a>

                  <p class="author label-xsmall">
                    <span class="bold">{{ article.author_names }}</span>
                    <span class="date">
                      {{ article.str_published_at }}
                    </span>
                  </p>
                </div>
              </article>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['articles', 'latest', 'totalCount'],
  data() {
    return {}
  },
  mounted() {
    console.log(this.totalCount)
  },
  methods: {
    filterArticles(articles) {
      let result = [];
      articles.map(article => {
        if (article) {
          result.push(article)
        }
      })

      console.log(result);
      return result;
    },
    heroImageUrl: function(article) {
      const imageUrl = "https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/";
      return `background-size: cover; background-image: url('${imageUrl}${article.hero_image_url}')`;
    },
    articleUrl: function(article) {
      return `/articles/${article.nickname}`;
    },
    clickArticle: function(article) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'click_article',
        'article_id': article.id,
        'article_title': article.title,
        'article_author': article.author,
        'article_published_at': article.published_at
      })
    },
    tagUrl: function(tag) {
      return `/tags/${tag.id}`;
    },
    changeOrder() {
      this.$emit("changeOrder")
    },
    stripTag: function(str) {
      var div = document.createElement("div");
      div.innerHTML = str;
      var text = div.textContent || div.innerText || "";
      return text;
    },
  }
}
</script>