<template>
  <div class="text-input" :class="{error: v.$error}">
    <label v-if="label" class="label" :class="{required: required}">{{ labelText }}</label>
    <input :type="type" class="form-control" :placeholder="placeholder"
           :value="value" :disabled="disabled" :readonly="readonly"
           :class="{error: v.$error, readonly: readonly}"
           @input="onInput($event.target.value)"
           @click="onClick"
           @keydown.enter="onEnter"
           @keydown="onKeydown"
    >
    <label v-if="description" class="desc"> {{ description }} </label>
    <validation-message :v="v" v-if="isShowError"></validation-message>
  </div>
</template>

<script>
  import {defaultV} from '../../commons/validators'
  import ValidationMessage from "./validation_message";

  export default {
    components: {
      ValidationMessage
    },
    props: {
      label: String,
      value: [String, Number],
      placeholder: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'text'
      },
      required: {
        type: Boolean,
        default: false,
      },
      isShowError: {
        type: Boolean,
        default: true
      },
      description: String,
      v: defaultV
    },
    data() {
      return {
      }
    },
    computed: {
      labelText: function() {
        return this.required ? this.label : `${this.label} (선택)`;
      }
    },
    methods: {
      onInput(value) {
        this.v.$touch();
        this.$emit('input', value);
      },
      onClick(e) {
        this.$emit('click', e);
      },
      onEnter(e) {
        this.$emit('enter', e);
      },
      onKeydown(e) {
        this.$emit('keydown', e);
      },
    }
  }
</script>