<template>
  <div class="error-message" v-if="v.$error">
    <small v-if="v.requiredIf === false ||
                 v.required === false ||
                 v.collectionRequired === false">이건 꼭 입력해야 해요!</small>
    <small v-if="v.maxLength === false">최대 자리수를 확인해주세요.</small>
    <small v-if="v.minLength === false">비밀번호 형식에 맞지 않습니다.</small>
    <small v-if="v.ticketClassPrice === false">0원 이상이어야 합니다.</small>
    <small v-if="v.shouldBeFuture === false">현재 시각 이후로 설정해 주세요.</small>
    <small v-if="v.adAllowedHours === false">광고성 푸시는 오전 8시부터 오후 9시 사이에만 보낼 수 있습니다.</small>
    <small v-if="v.emailValid === false">이메일 형식에 맞지 않습니다.</small>
    <small v-if="v.confirmPassword === false">비밀번호가 동일하지 않습니다.</small>
    <small v-if="v.misMatchedPwd === false">이메일 또는 비밀번호를 확인해주세요.</small>
    <small v-if="v.requiredName === false">이름을 입력해주세요.</small>
    <small v-if="v.requiredEmail === false">이메일을 입력해주세요.</small>
    <small v-if="v.requiredPassword === false">패스워드를 입력해주세요.</small>

    <small v-if="v.validPromotionCode === false">유효하지 않은 코드입니다. 확인 후 다시 입력해주세요.</small>

    <small v-if="v.cardValidation === false">카드 번호가 올바르지 않습니다. 카드 번호를 확인하거나 다른 카드를 사용하세요.</small>
    <small v-if="v.cvcValid === false">카드의 CVC가 잘못되었습니다. 카드의 CVC를 확인하거나 다른 카드를 사용하세요.</small>

  </div>
</template>

<script>
  import { defaultV } from '../../commons/validators'

  export default {
    props: {
      v: defaultV
    }
  }
</script>
