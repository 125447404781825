var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-input", class: { error: _vm.v.$error } },
    [
      _vm.label
        ? _c(
            "label",
            { staticClass: "label", class: { required: _vm.required } },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "card-element" }, [
        _c("input", {
          staticClass: "form-control",
          class: { error: _vm.v.$error },
          attrs: {
            type: _vm.type,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            readonly: _vm.readonly
          },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              return _vm.onInput($event.target.value)
            },
            click: _vm.onClick,
            keydown: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onEnter.apply(null, arguments)
              },
              _vm.onKeydown
            ]
          }
        })
      ]),
      _vm._v(" "),
      _vm.description
        ? _c("label", { staticClass: "desc" }, [
            _vm._v(" " + _vm._s(_vm.description) + " ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowError
        ? _c("validation-message", { attrs: { v: _vm.v } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }