var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "homepage-landing" },
    [
      _c("section", { staticClass: "homepage-landing-hero" }, [
        _c("div", { staticClass: "content" }, [
          _c("span", { staticClass: "bg-text thin c-white" }, [
            _vm._v(" The Miilk analysis ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "left" },
            [
              _vm.level1 != null
                ? [
                    _c("div", { staticClass: "text-wrapper sticky" }, [
                      _c("div", { staticClass: "sticky-wrapper" }, [
                        _vm.level1.hero_tags.length > 0
                          ? _c(
                              "ul",
                              {
                                staticClass: "tags",
                                class: {
                                  "small-margin":
                                    _vm.level1.collections.length == 0
                                }
                              },
                              [
                                _vm.level1.collections.length > 0
                                  ? _c("li", { staticClass: "collection" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "collection-linkage no-deco ellipsis one",
                                          attrs: {
                                            href:
                                              "/collections/" +
                                              _vm.level1.hero_collection.id
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.level1.hero_collection.name
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._l(_vm.level1.hero_tags, function(tag) {
                                      return _c(
                                        "li",
                                        {
                                          key: "l1tag-" + tag.id,
                                          staticClass: "tag"
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "tag-linkage",
                                              attrs: { href: "/tags/" + tag.id }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(tag.name) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          { attrs: { href: _vm.articleUrl(_vm.level1) } },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "title label-middle-title bold c-white ellipsis three"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.level1.title) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "no-deco",
                            attrs: { href: _vm.articleUrl(_vm.level1) }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description label-middle-description c-white ellipsis four"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.level1.first_text) +
                                    "\n                  "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "author label-xsmall c-white" },
                          [
                            _c("author-name", {
                              attrs: { article: _vm.level1 }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(_vm._s(_vm.level1.pdt_published_at) + " ")
                            ])
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "image-wrapper" }, [
                      _c("a", { attrs: { href: _vm.articleUrl(_vm.level1) } }, [
                        _c(
                          "div",
                          { staticClass: "image-box float fit-cover" },
                          [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  "data-srcset":
                                    _vm.heroImageUrl(_vm.level1, 750) + " 375w",
                                  media: "(max-width: 375px)",
                                  type: "image/webp"
                                }
                              }),
                              _vm._v(" "),
                              _c("source", {
                                attrs: {
                                  "data-srcset":
                                    _vm.heroImageUrl(_vm.level1, 375) +
                                    " 1x, " +
                                    _vm.heroImageUrl(_vm.level1, 750) +
                                    " 2x",
                                  type: "image/webp"
                                }
                              }),
                              _vm._v(" "),
                              _c("source", {
                                attrs: {
                                  srcset:
                                    _vm.heroImageUrl(_vm.level1, 750) + " 375w",
                                  media: "(max-width: 375px)",
                                  type: "image/jpg"
                                }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  src: _vm.heroImageUrl(_vm.level1, 640),
                                  srcset:
                                    _vm.heroImageUrl(_vm.level1, 1280) +
                                    " 2x, " +
                                    _vm.heroImageUrl(_vm.level1, 2560) +
                                    " 4x",
                                  sizes: "auto",
                                  alt: _vm.level1.title
                                }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "blank-image",
                          attrs: {
                            src:
                              "https://assets.themiilk.com/commons/blank.png",
                            alt: ""
                          }
                        })
                      ])
                    ])
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right" },
            [
              _vm.level2 != null
                ? [
                    _c("div", { staticClass: "image-wrapper" }, [
                      _c("a", { attrs: { href: _vm.articleUrl(_vm.level2) } }, [
                        _c(
                          "div",
                          { staticClass: "image-box float fit-cover" },
                          [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  "data-srcset":
                                    _vm.heroImageUrl(_vm.level2, 750) + " 375w",
                                  media: "(max-width: 375px)",
                                  type: "image/webp"
                                }
                              }),
                              _vm._v(" "),
                              _c("source", {
                                attrs: {
                                  "data-srcset":
                                    _vm.heroImageUrl(_vm.level2, 375) +
                                    " 1x, " +
                                    _vm.heroImageUrl(_vm.level2, 750) +
                                    " 2x",
                                  type: "image/webp"
                                }
                              }),
                              _vm._v(" "),
                              _c("source", {
                                attrs: {
                                  srcset:
                                    _vm.heroImageUrl(_vm.level2, 750) + " 375w",
                                  media: "(max-width: 375px)",
                                  type: "image/jpg"
                                }
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  src: _vm.heroImageUrl(_vm.level2, 380),
                                  srcset:
                                    _vm.heroImageUrl(_vm.level2, 760) +
                                    " 2x, " +
                                    _vm.heroImageUrl(_vm.level2, 1520) +
                                    " 4x",
                                  sizes: "auto",
                                  alt: _vm.level2.title
                                }
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "blank-image",
                          attrs: {
                            src:
                              "https://assets.themiilk.com/commons/blank.png",
                            alt: ""
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-wrapper" }, [
                      _vm.level2.hero_tags.length > 0
                        ? _c(
                            "ul",
                            { staticClass: "tags small-margin" },
                            [
                              _vm.level2.collections.length > 0
                                ? _c("li", { staticClass: "collection" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "collection-linkage no-deco ellipsis one",
                                        attrs: {
                                          href:
                                            "/collections/" +
                                            _vm.level2.hero_collection.id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.level2.hero_collection.name
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._l(_vm.level2.hero_tags, function(tag) {
                                    return _c(
                                      "li",
                                      {
                                        key: "l2tag-" + tag.id,
                                        staticClass: "tag"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "tag-linkage",
                                            attrs: { href: "/tags/" + tag.id }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(tag.name) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("a", { attrs: { href: _vm.articleUrl(_vm.level2) } }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "title label-small-title bold c-white ellipsis two"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.level2.title) +
                                "\n              "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "author label-xsmall c-white" },
                        [
                          _c("author-name", { attrs: { article: _vm.level2 } }),
                          _vm._v(" "),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              " " + _vm._s(_vm.level2.pdt_published_at) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "news-container type2 sub-section" }, [
            _c("div", { staticClass: "news-wrapper" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "news-items lastest-brief" },
                _vm._l(_vm.level3, function(level3Article) {
                  return _c(
                    "div",
                    { key: level3Article.id, staticClass: "news-item" },
                    [
                      _c("div", { staticClass: "image-wrapper" }, [
                        _c(
                          "a",
                          { attrs: { href: _vm.articleUrl(level3Article) } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "image-box float fit-cover small-radius"
                              },
                              [
                                _c("picture", [
                                  _c("source", {
                                    attrs: {
                                      "data-srcset":
                                        _vm.heroImageUrl(level3Article, 750) +
                                        " 375w",
                                      media: "(max-width: 375px)",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    attrs: {
                                      "data-srcset":
                                        _vm.heroImageUrl(level3Article, 375) +
                                        " 1x, " +
                                        _vm.heroImageUrl(level3Article, 750) +
                                        " 2x",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    attrs: {
                                      srcset:
                                        _vm.heroImageUrl(level3Article, 750) +
                                        " 375w",
                                      media: "(max-width: 375px)",
                                      type: "image/jpg"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.heroImageUrl(level3Article, 300),
                                      srcset:
                                        _vm.heroImageUrl(level3Article, 600) +
                                        " 2x, " +
                                        _vm.heroImageUrl(level3Article, 1200) +
                                        " 4x",
                                      sizes: "auto",
                                      alt: level3Article.title
                                    }
                                  })
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "blank-image",
                              attrs: {
                                src:
                                  "https://assets.themiilk.com/commons/blank.png",
                                alt: ""
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-wrapper" }, [
                        level3Article.hero_tags.length > 0
                          ? _c(
                              "ul",
                              { staticClass: "tags small-margin" },
                              [
                                level3Article.collections.length > 0
                                  ? _c("li", { staticClass: "collection" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "collection-linkage no-deco ellipsis one",
                                          attrs: {
                                            href:
                                              "/collections/" +
                                              level3Article.hero_collection.id
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                level3Article.hero_collection
                                                  .name
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._l(level3Article.hero_tags, function(
                                      tag
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key:
                                            "l3tag-" +
                                            level3Article.id +
                                            "-" +
                                            tag.id,
                                          staticClass: "tag"
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "tag-linkage",
                                              attrs: { href: "/tags/" + tag.id }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(tag.name) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          { attrs: { href: _vm.articleUrl(level3Article) } },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "title label-description bold ellipsis two"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(level3Article.title) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "author label-xsmall ellipsis one" },
                          [
                            _c("author-name", {
                              attrs: { article: level3Article }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(level3Article.pdt_published_at) +
                                  "\n                "
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _vm._m(1)
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "news-container split type1 video-section" },
            [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "news-wrapper" }, [
                _vm.level1Video != null
                  ? _c("div", { staticClass: "news-item main-news" }, [
                      _c("div", { staticClass: "image-wrapper" }, [
                        _c(
                          "a",
                          { attrs: { href: "/videos/" + _vm.level1Video.id } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-box",
                                style:
                                  "background-size: cover; background-image: url('" +
                                  _vm.level1Video.thumbnail_url +
                                  "')"
                              },
                              [
                                _c("img", {
                                  staticClass: "blank-image",
                                  attrs: {
                                    src:
                                      "https://assets.themiilk.com/commons/blank.png"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-wrapper sticky" }, [
                        _c("div", { staticClass: "sticky-wrapper" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "/videos/" + _vm.level1Video.id }
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "title label-middle-title bold ellipsis three"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.level1Video.title) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "no-deco",
                              attrs: { href: "/videos/" + _vm.level1Video.id }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "description label-middle-description ellipsis five"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.level1Video.description) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.level2Video != null
                  ? _c(
                      "div",
                      { staticClass: "news-item vertical mobile-middle" },
                      [
                        _c("div", { staticClass: "image-wrapper" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "/videos/" + _vm.level2Video.id }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-box",
                                  style:
                                    "background-size: cover; background-image: url('" +
                                    _vm.level2Video.thumbnail_url +
                                    "')"
                                },
                                [
                                  _c("img", {
                                    staticClass: "blank-image",
                                    attrs: {
                                      src:
                                        "https://assets.themiilk.com/commons/blank.png"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-wrapper" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "/videos/" + _vm.level2Video.id }
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "title label-small-title bold ellipsis two mobile-three"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.level2Video.title) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "no-deco",
                              attrs: { href: "/videos/" + _vm.level2Video.id }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "description label-middle-description ellipsis one tablet-show"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.level2Video.description) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _vm.eventType != null
            ? [
                _c(
                  "div",
                  {
                    staticClass: "news-container split type-event event-section"
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm.eventType == "event"
                      ? _c("div", { staticClass: "news-wrapper" }, [
                          _c("div", { staticClass: "news-item main-news" }, [
                            _c("div", { staticClass: "image-wrapper" }, [
                              _c(
                                "a",
                                {
                                  attrs: { href: "/events/" + _vm.mainEvent.id }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-box",
                                      style:
                                        "background-size: cover; background-image: url('" +
                                        _vm.mainEvent.hero_image_url +
                                        "')"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "blank-image",
                                        attrs: {
                                          src:
                                            "https://assets.themiilk.com/commons/blank.png"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-wrapper sticky" }, [
                              _c("div", { staticClass: "sticky-wrapper" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/events/" + _vm.mainEvent.id
                                    }
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "title label-middle-title bold ellipsis three"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.mainEvent.title) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "no-deco",
                                    attrs: {
                                      href: "/events/" + _vm.mainEvent.id
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "description label-middle-description ellipsis five"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.mainEvent.description) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      : _vm.eventType == "report"
                      ? _c("div", { staticClass: "news-wrapper" }, [
                          _c("div", { staticClass: "news-item main-news" }, [
                            _c("div", { staticClass: "image-wrapper" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/reports/" + _vm.mainEvent.id
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-box",
                                      style:
                                        "background-size: cover; background-image: url('" +
                                        _vm.mainEvent.main_image_url +
                                        "')"
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "blank-image",
                                        attrs: {
                                          src:
                                            "https://assets.themiilk.com/commons/blank.png"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-wrapper sticky" }, [
                              _c("div", { staticClass: "sticky-wrapper" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/reports/" + _vm.mainEvent.id
                                    }
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "title label-middle-title bold ellipsis three"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("stripHTML")(
                                                _vm.mainEvent.title
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "no-deco",
                                    attrs: {
                                      href: "/reports/" + _vm.mainEvent.id
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "description label-middle-description ellipsis five"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("stripHTML")(
                                                _vm.mainEvent.summary
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "news-container split type1 business-insight-section"
            },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "news-wrapper" }, [
                _vm.investingArticle != null
                  ? _c("div", { staticClass: "news-item main-news" }, [
                      _c("div", { staticClass: "image-wrapper" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.articleUrl(_vm.investingArticle)
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "image-box float fix-cover" },
                              [
                                _c("picture", [
                                  _c("source", {
                                    attrs: {
                                      "data-srcset":
                                        _vm.heroImageUrl(
                                          _vm.investingArticle,
                                          750
                                        ) + " 375w",
                                      media: "(max-width: 375px)",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    attrs: {
                                      "data-srcset":
                                        _vm.heroImageUrl(
                                          _vm.investingArticle,
                                          375
                                        ) +
                                        " 1x, " +
                                        _vm.heroImageUrl(
                                          _vm.investingArticle,
                                          750
                                        ) +
                                        " 2x",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    attrs: {
                                      srcset:
                                        _vm.heroImageUrl(
                                          _vm.investingArticle,
                                          750
                                        ) + " 375w",
                                      media: "(max-width: 375px)",
                                      type: "image/jpg"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.heroImageUrl(
                                        _vm.investingArticle,
                                        640
                                      ),
                                      srcset:
                                        _vm.heroImageUrl(
                                          _vm.investingArticle,
                                          1280
                                        ) +
                                        " 2x, " +
                                        _vm.heroImageUrl(
                                          _vm.investingArticle,
                                          2560
                                        ) +
                                        " 4x",
                                      alt: _vm.investingArticle.title
                                    }
                                  })
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "blank-image",
                          attrs: {
                            src:
                              "https://assets.themiilk.com/commons/blank.png",
                            alt: ""
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-wrapper" }, [
                        _vm.investingArticle.hero_tags.length > 0
                          ? _c(
                              "ul",
                              { staticClass: "tags small-margin" },
                              _vm._l(_vm.investingArticle.hero_tags, function(
                                tag
                              ) {
                                return _c(
                                  "li",
                                  {
                                    key: "investingTag-" + tag.id,
                                    staticClass: "tag"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "tag-linkage",
                                        attrs: { href: "/tags/" + tag.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(tag.name) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.articleUrl(_vm.investingArticle)
                            }
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "title label-middle-title bold ellipsis three"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.investingArticle.title) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "no-deco",
                            attrs: {
                              href: _vm.articleUrl(_vm.investingArticle)
                            }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "description label-middle-description ellipsis five"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.investingArticle.first_text) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "author label-xsmall" },
                          [
                            _c("author-name", {
                              attrs: { article: _vm.investingArticle }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.investingArticle.pdt_published_at
                                  ) +
                                  "\n              "
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.investingArticle2 != null
                  ? _c("div", { staticClass: "news-item vertical" }, [
                      _c("div", { staticClass: "image-wrapper" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.articleUrl(_vm.investingArticle2)
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "image-box float fit-cover" },
                              [
                                _c("picture", [
                                  _c("source", {
                                    attrs: {
                                      "data-srcset":
                                        _vm.heroImageUrl(
                                          _vm.investingArticle2,
                                          750
                                        ) + " 375w",
                                      media: "(max-width: 375px)",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    attrs: {
                                      "data-srcset":
                                        _vm.heroImageUrl(
                                          _vm.investingArticle2,
                                          375
                                        ) +
                                        " 1x, " +
                                        _vm.heroImageUrl(
                                          _vm.investingArticle2,
                                          750
                                        ) +
                                        " 2x",
                                      type: "image/webp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("source", {
                                    attrs: {
                                      srcset:
                                        _vm.heroImageUrl(
                                          _vm.investingArticle2,
                                          750
                                        ) + " 375w",
                                      media: "(max-width: 375px)",
                                      type: "image/jpg"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.heroImageUrl(
                                        _vm.investingArticle2,
                                        640
                                      ),
                                      srcset:
                                        _vm.heroImageUrl(
                                          _vm.investingArticle2,
                                          1280
                                        ) +
                                        " 2x, " +
                                        _vm.heroImageUrl(
                                          _vm.investingArticle2,
                                          2560
                                        ) +
                                        " 4x",
                                      sizes: "auto",
                                      alt: _vm.investingArticle2.title
                                    }
                                  })
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "blank-image",
                          attrs: {
                            src:
                              "https://assets.themiilk.com/commons/blank.png",
                            alt: ""
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-wrapper" }, [
                        _vm.investingArticle2.hero_tags.length > 0
                          ? _c(
                              "ul",
                              { staticClass: "tags small-margin" },
                              _vm._l(_vm.investingArticle2.hero_tags, function(
                                tag
                              ) {
                                return _c(
                                  "li",
                                  {
                                    key: "investingTag2-" + tag.id,
                                    staticClass: "tag"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "tag-linkage",
                                        attrs: { href: "/tags/" + tag.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(tag.name) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.articleUrl(_vm.investingArticle2)
                            }
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "title label-small-title bold ellipsis two"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.investingArticle2.title) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "author label-xsmall" },
                          [
                            _c("author-name", {
                              attrs: { article: _vm.investingArticle2 }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.investingArticle2.pdt_published_at
                                  ) +
                                  "\n              "
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _vm.setLayerPopup
        ? _c("email-layer-popup", { on: { closeModal: _vm.closeModal } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "news-head" }, [
      _c("span", { staticClass: "label-small bold head-title" }, [
        _vm._v("\n            Latest Brief\n          ")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button button-arrow label-small",
          attrs: { href: "/topics/all" }
        },
        [
          _vm._v("\n            All Articles\n            "),
          _c("div", { staticClass: "icon-arrow" }, [
            _c("div", { staticClass: "icon" })
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "news-wrapper special" }, [
      _c("div", { staticClass: "cta-head" }, [
        _c("span", { staticClass: "label-small bold head-title" }, [
          _vm._v("The Miilk Special")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cta-side" }, [
        _c("a", {
          staticClass: "cta-item no-deco cta-banner-ss",
          attrs: { target: "_blank", href: "https://news.samsung.com/global/" }
        }),
        _vm._v(" "),
        _c("a", {
          staticClass: "cta-item no-deco cta-banner-kb",
          attrs: { target: "_blank", href: "https://pf.kakao.com/_lFdxhs" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "news-head" }, [
      _c("span", { staticClass: "label-small bold head-title" }, [
        _vm._v("\n          Video\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "news-head" }, [
      _c("span", { staticClass: "label-small bold head-title" }, [
        _vm._v(" The Miilk Event ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "news-head" }, [
      _c("span", { staticClass: "label-small bold head-title" }, [
        _vm._v("\n          Business Insight\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }