import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import HomeIndex from './pages/home';
import PortfolioIndex from './pages/portfolio';
import OrderPlan from './pages/orders/plans';
import OrderChangePlan from './pages/orders/change_plan';
import CouponRegister from './pages/coupons/regist';
import CouponRegister2 from './pages/coupons/regist2';
import ReporterIndex from './pages/reporter';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: HomeIndex, name: 'home', meta: {blankLayout: false} },
    { path: '/portfolio', component: PortfolioIndex, name: 'portfolio', meta: {blankLayout: false}},

    { path: '/orders/plans', component: OrderPlan, name: 'orderPlan', meta: {blankLayout: false}, props: {signup: false}},
    { path: '/orders/signup_plans', component: OrderPlan, name: 'orderPlan', meta: {blankLayout: false}, props: {signup: true}},
    { path: '/orders/plans/:coupon', component: OrderPlan, name: 'orderPlan', meta: {blankLayout: false}, props: {signup: false}},
    { path: '/orders/signup_plans/:coupon', component: OrderPlan, name: 'orderPlan', meta: {blankLayout: false}, props: {signup: true}},
    { path: '/orders/change_plan', component: OrderChangePlan, name: 'orderChangePlan', meta: {blankLayout: false}, props: {signup: false}},

    // deprecated
    { path: '/users/change_plan', component: OrderChangePlan, name: 'deprecatedOrderChangePlan', meta: {blankLayout: false}, props: {signup: false}},

    { path: '/coupons/:couponNumber/new', component: CouponRegister2, name: 'couponRegister2', meta: {blankLayout: false}},
    { path: '/coupons/:couponNumber', component: CouponRegister, name: 'couponRegister', meta: {blankLayout: false}},

    { path: '/reporter/:id', component: ReporterIndex, name: 'reporter', meta: {blankLayout: false}},
    { path: '*', redirect: '/' },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.matched.some(m => m.meta.disableScroll)) return;

    const position = {
      x: 0,
      y: 0
    };

    if (savedPosition) {
      position.x = savedPosition.x;
      position.y = savedPosition.y;
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position)
      }, 100)
    });
  }
});

export default router;