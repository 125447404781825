var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "portfolio-landing" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "portfolio-landing-hero" }, [
      _c("div", { staticClass: "content" }, [
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "button-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "button button-rounded shadow hover confirm no-deco",
              on: { click: _vm.reserveBtnClicked }
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    this.user != null ? "시작하기" : "회원가입 후	무료 진단하기"
                  )
                )
              ])
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "portfolio-landing-content" }, [
      _c("div", { staticClass: "content single" }, [
        _vm._m(4),
        _vm._v(" "),
        _c("ul", { staticClass: "box-items" }, [
          _c(
            "li",
            {
              staticClass: "box-item c-black subscriber",
              on: {
                click: function($event) {
                  return _vm.showPopup("special_service")
                }
              }
            },
            [
              _vm._m(5),
              _vm._v(" "),
              _c("h3", { staticClass: "label-description bold" }, [
                _vm._v("더밀크 특별 서비스")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "box-item c-black portfolio",
              on: {
                click: function($event) {
                  return _vm.showPopup("optimization")
                }
              }
            },
            [
              _vm._m(6),
              _vm._v(" "),
              _c("h3", { staticClass: "label-description bold" }, [
                _vm._v("포트폴리오 최적화란?")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "box-item c-black algorithm",
              on: {
                click: function($event) {
                  return _vm.showPopup("algorithm_based_optimization")
                }
              }
            },
            [
              _vm._m(7),
              _vm._v(" "),
              _c("h3", { staticClass: "label-description bold" }, [
                _vm._v("알고리즘 기반 최적화")
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _c("div", { staticClass: "button-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "button button-rounded shadow hover confirm no-deco",
              on: { click: _vm.reserveBtnClicked }
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    this.user != null ? "시작하기" : "회원가입 후	무료 진단하기"
                  )
                )
              ])
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(9),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "popup-container", class: { active: _vm.currentPopup } },
      [
        _c("div", {
          staticClass: "popup-background",
          on: { click: _vm.closePopup }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item special-service",
            class: { show: _vm.popups.special_service }
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "button button-circle button-close remove-line-height",
                on: { click: _vm.closePopup }
              },
              [
                _c("img", {
                  staticClass: "close",
                  attrs: {
                    src:
                      "https://assets.themiilk.com/commons/icon-close-thin-white.svg",
                    alt: ""
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "popup-wrapper c-black" }, [
              _c("div", { staticClass: "text-wrapper" }, [
                _vm._m(10),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "popup-description label-middle-description" },
                  [
                    _vm._v(
                      "\n            더밀크 사용자들은 최초 구성시 5번까지의 최적화 실험을 해볼수\n            있으며, 최초 포트폴리오 구성 이후 시장상황의 변화등을 반영하여\n            수시로 최적 비중을 업데이트 받아 보게 됩니다.\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "button-wrapper" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button button-box round large bright-blue no-deco c-white",
                      on: { click: _vm.reserveBtnClicked }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            this.user != null
                              ? "시작하기"
                              : "회원가입 후	무료 진단하기"
                          ) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(11)
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item optimization",
            class: { show: _vm.popups.optimization }
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "button button-circle button-close remove-line-height",
                on: { click: _vm.closePopup }
              },
              [
                _c("img", {
                  staticClass: "close",
                  attrs: {
                    src:
                      "https://assets.themiilk.com/commons/icon-close-thin-white.svg",
                    alt: ""
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "popup-wrapper c-black" }, [
              _c("div", { staticClass: "text-wrapper" }, [
                _vm._m(12),
                _vm._v(" "),
                _vm._m(13),
                _vm._v(" "),
                _c("div", { staticClass: "button-wrapper" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button button-box round large bright-blue no-deco c-white",
                      on: { click: _vm.reserveBtnClicked }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            this.user != null
                              ? "시작하기"
                              : "회원가입 후	무료 진단하기"
                          ) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(14)
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item algorithm-based-optimization",
            class: { show: _vm.popups.algorithm_based_optimization }
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "button button-circle button-close remove-line-height",
                on: { click: _vm.closePopup }
              },
              [
                _c("img", {
                  staticClass: "close",
                  attrs: {
                    src:
                      "https://assets.themiilk.com/commons/icon-close-thin-white.svg",
                    alt: ""
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "popup-wrapper c-black" }, [
              _c("div", { staticClass: "text-wrapper" }, [
                _vm._m(15),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "popup-description label-middle-description" },
                  [
                    _vm._v(
                      "\n            alphametrica는 학술적 연구를 통해 엄밀하게 검증된 모델들을 활용해\n            시장 상황에 따른 최적화된 조합을 제시합니다.\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "button-wrapper" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button button-box round large bright-blue no-deco c-white",
                      on: { click: _vm.reserveBtnClicked }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            this.user != null
                              ? "시작하기"
                              : "회원 가입하고 진단하기"
                          ) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(16),
              _vm._v(" "),
              _vm._m(17)
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item reservation confirm",
            class: {
              show: _vm.popups.reservation_confirm
            }
          },
          [
            _vm._m(18),
            _vm._v(" "),
            _vm._m(19),
            _vm._v(" "),
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large bright-blue no-deco c-white",
                  on: { click: _vm.closePopup }
                },
                [_vm._v("\n          확인\n        ")]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item reservation guest",
            class: {
              show: _vm.popups.reservation_guest
            }
          },
          [
            _vm._m(20),
            _vm._v(" "),
            _vm._m(21),
            _vm._v(" "),
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large grey no-deco c-white",
                  on: { click: _vm.closePopup }
                },
                [_vm._v("\n          닫기\n        ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "button button-box round large bright-blue no-deco c-white",
                  attrs: { href: "/orders/plans?url=/portfolio" }
                },
                [_vm._v("유료 구독하러 가기")]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item reservation membership",
            class: {
              show: _vm.popups.reservation_membership
            }
          },
          [
            _vm._m(22),
            _vm._v(" "),
            _vm._m(23),
            _vm._v(" "),
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large grey no-deco c-white",
                  on: { click: _vm.closePopup }
                },
                [_vm._v("\n          닫기\n        ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "button button-box round large bright-green no-deco c-white",
                  attrs: { href: "/users/change_plan?url=/portfolio" }
                },
                [_vm._v("연간회원으로 전환하기")]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-item reservation no-email",
            class: {
              show: _vm.popups.reservation_no_email
            }
          },
          [
            _vm._m(24),
            _vm._v(" "),
            _c("div", { staticClass: "popup-wrapper c-black" }, [
              _c("div", { staticClass: "text-wrapper" }, [
                _c(
                  "h2",
                  { staticClass: "popup-title label-middle-title bold" },
                  [_vm._v("\n            이메일을 등록해주세요.\n          ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "input round label-middle-description email",
                  class: { warning: _vm.emailWarning },
                  attrs: {
                    type: "text",
                    placeholder: "이메일을 입력해주세요."
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.emailWarning
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "popup-description label-small-description warning"
                      },
                      [
                        _vm._v(
                          "\n            정확한 이메일을 입력해주세요.\n          "
                        )
                      ]
                    )
                  : _c(
                      "p",
                      {
                        staticClass: "popup-description label-small-description"
                      },
                      [
                        _vm._v(
                          "\n            이메일을 입력하시면, 더밀크리치를 활용한 미국 주식 포트폴리오 진단이 가능합니다.\n          "
                        )
                      ]
                    )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large grey no-deco c-white",
                  on: { click: _vm.closeRegisterPopup }
                },
                [_vm._v("\n          취소\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "button button-box round large bright-green no-deco c-white",
                  on: { click: _vm.registerEmail }
                },
                [_vm._v("\n          입력하기\n        ")]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "portfolio-landing-background" }, [
      _c(
        "div",
        { staticClass: "image-wrapper side top-right remove-line-height" },
        [
          _c("img", {
            staticClass: "image",
            attrs: {
              src:
                "https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-rt.png",
              alt: ""
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "image-wrapper side bottom-left remove-line-height" },
        [
          _c("img", {
            staticClass: "image",
            attrs: {
              src:
                "https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-rt.png",
              alt: ""
            }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "current-page" }, [
      _c("h2", { staticClass: "name c-white" }, [_vm._v("Portfolio")]),
      _vm._v(" "),
      _c("p", { staticClass: "description c-white" }, [
        _vm._v(
          "\n          더밀크에서 제공하는 최고 수준의 포트폴리오 진단 서비스를 경험하세요.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-title" }, [
      _c("img", {
        staticClass: "main",
        attrs: {
          src: "https://assets.themiilk.com/portfolio/logo-themiilk-rich.svg",
          alt: "더밀크.리치"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "sub",
        attrs: {
          src:
            "https://assets.themiilk.com/portfolio/logo-powered-by-alphametrica.svg",
          alt: "Powered by alphametrica"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h2",
      { staticClass: "section-point label-sub-title-small bold c-white" },
      [
        _vm._v("\n        더밀크에서 제공하는 최고 수준의 "),
        _c("br"),
        _vm._v("\n        포트폴리오 진단 서비스를 경험하세요!\n      ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-wrapper" }, [
      _c("h2", { staticClass: "section-head label-sub-title-small c-white" }, [
        _c("div", { staticClass: "label" }, [_vm._v("더밀크리치란?")]),
        _vm._v(" "),
        _c("div", { staticClass: "line" })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "section-description label-description normal" }, [
        _vm._v(
          "\n          더밀크리치는 개인 맞춤형 포트폴리오 진단 서비스입니다."
        ),
        _c("br"),
        _vm._v(
          "\n          여러분이 진단 의뢰한 미국 주식 포트폴리오를 alphametrica가 추천하는\n          위험 대비 수익률을 장기적으로 극대화 시키는 포트폴리오 배분과 비교\n          분석해 드립니다."
        ),
        _c("br")
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "section-description label-middle-description thin" },
        [
          _vm._v(
            "\n          'alphametrica'는 금융 선진국의 일류 연구기관과 헤지펀드에서 퀀트\n          알고리즘을 연구, 개발하고 있는 최고 수준의 계량 투자 전문가들이 만든\n          포트폴리오 최적화 엔진입니다.\n        "
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        attrs: {
          src:
            "https://assets.themiilk.com/portfolio/illust-special-service.png",
          alt: ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "message" }, [_vm._v("자세히 알아보기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        attrs: {
          src: "https://assets.themiilk.com/portfolio/illust-optimization.png",
          alt: ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "message" }, [_vm._v("자세히 알아보기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        attrs: {
          src:
            "https://assets.themiilk.com/portfolio/illust-algorithm-based-optimization.png",
          alt: ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "message" }, [_vm._v("자세히 알아보기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "help-message label-middle-description thin" },
      [
        _vm._v(
          "\n        더밀크 회원에게만 드리는 더밀크리치 포트폴리오 진단 서비스를\n        "
        ),
        _c("br"),
        _vm._v("\n        이용하시려면 아래 버튼을 눌러 시작해주세요!\n      ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "portfolio-landing-illust" }, [
      _c("div", { staticClass: "content remove-line-height" }, [
        _c("img", {
          staticClass: "icon-illust",
          attrs: {
            src: "https://assets.themiilk.com/portfolio/lower-illust.svg",
            alt: ""
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "popup-title label-sub-title" }, [
      _vm._v("\n            더밀크 회원 "),
      _c("br"),
      _vm._v(" "),
      _c("strong", { staticClass: "bold" }, [_vm._v("특별 제공 서비스")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "illust",
        attrs: {
          src:
            "https://assets.themiilk.com/portfolio/illust-special-service-detail.png",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "popup-title label-sub-title" }, [
      _vm._v("\n            포트폴리오 "),
      _c("strong", { staticClass: "bold" }, [_vm._v("최적화란")]),
      _vm._v("?\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "bullet-list label-middle-description" }, [
      _c("li", { staticClass: "item" }, [
        _vm._v(
          "\n              투자가 여러가지 종목 혹은 자산으로 이루어져 있을 때, 전체 성과는\n              어떤 종목을 선택하였는지 뿐만 아니라 각 종목이 얼마나 투자\n              되었는지에 큰 영향을 받게 됩니다.\n            "
        )
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "item" }, [
        _vm._v(
          "\n              따라서 전체 투자 자산, 즉 포트폴리오를 구성하고 있는 각각의\n              자산의 비중을 결정하는 것은 매우 중요하며, 포트폴리오의 위험에\n              대비한 기대 수익률을 최대한 늘리게끔 구성 자산의 비중을 변경하는\n              것을 포트폴리오 최적화라 합니다.\n            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "illust",
        attrs: {
          src:
            "https://assets.themiilk.com/portfolio/illust-optimization-detail.png",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "popup-title label-sub-title" }, [
      _c("strong", { staticClass: "bold" }, [_vm._v("알고리즘 기반")]),
      _vm._v(" "),
      _c("br"),
      _vm._v("\n            포트폴리오 최적화 효과\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("div", { staticClass: "lines" }, [
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("div", { staticClass: "line" })
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "chart",
        attrs: {
          src:
            "https://assets.themiilk.com/portfolio/illust-algorithm-based-optimization-detail-chart.png",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "image-wrapper remove-line-height point" },
      [
        _c("img", {
          staticClass: "point",
          attrs: {
            src:
              "https://assets.themiilk.com/portfolio/illust-algorithm-based-optimization-detail-point.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: "https://assets.themiilk.com/portfolio/icon-confirm.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-wrapper c-black" }, [
      _c("div", { staticClass: "text-wrapper" }, [
        _c("h2", { staticClass: "popup-title label-middle-title bold" }, [
          _vm._v("\n            신청이 완료 되었습니다.\n          ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "popup-description label-small-description" }, [
          _vm._v(
            "\n            베타 서비스 오픈 후, 안내 메일을 발송해 드립니다.\n          "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: "https://assets.themiilk.com/portfolio/icon-subscribe.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-wrapper c-black" }, [
      _c("div", { staticClass: "text-wrapper" }, [
        _c("h2", { staticClass: "popup-title label-middle-title bold" }, [
          _vm._v("\n            회원 전용 서비스 입니다.\n          ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "popup-description label-small-description" }, [
          _vm._v(
            "\n            회원 가입 후 더 많은 혜택을 누려 보세요.\n          "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: "https://assets.themiilk.com/portfolio/icon-upgrade.svg",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup-wrapper c-black" }, [
      _c("div", { staticClass: "text-wrapper" }, [
        _c("h2", { staticClass: "popup-title label-middle-title bold" }, [
          _vm._v("\n            회원 전용 서비스 입니다.\n          ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "popup-description label-small-description" }, [
          _vm._v(
            "\n            회원 가입 후 더 많은 혜택을 누려 보세요.\n          "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: "https://assets.themiilk.com/portfolio/icon-email.svg",
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }