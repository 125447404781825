<template>
  <div class="dialog-container">
    <div class="dialog-background"></div>
    <div class="dialog-wrapper">
      <div class="button button-circle close-btn" v-on:click="closePop(false)">
        <img class="icon-close" src="https://assets.themiilk.com/common/icon-close-white.svg" alt="Close" />
      </div>
      <div class="dialog-box">
        <div class="dialog-content">
          <div class="section-title label-small-title bold" v-if="title">{{ title }}</div>
          <div class="content-wrapper label-middle-description" v-html="content"></div>
        </div>
        <div class="dialog-button">
          <div class="button button-box cancel-btn" v-on:click="closePop(false)">아니오</div>
          <div class="button button-box confirm-btn" v-on:click="closePop(true)">예</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'content'],
    methods: {
      closePop: function(e) {
        this.$emit('close-layer-popup', e);
      }
    }
  }
</script>
