<template>
  <section class="portfolio-landing">
    <section class="portfolio-landing-background result">
      <div class="image-wrapper middle remove-line-height">
        <img
          class="image"
          src="https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-middle.png"
          alt=""
        />
      </div>
      <div class="image-wrapper middle effect remove-line-height">
        <img
          class="image"
          src="https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-middle-effect.png"
          alt=""
        />
      </div>
    </section>

    <section class="portfolio-landing-hero result">
      <div class="content">
        <div class="current-page">
          <h2 class="name c-white">Portfolio</h2>
          <p class="description c-white">
            더밀크에서 제공하는 최고 수준의 포트폴리오 진단을 경험하세요.
          </p>
        </div>

        <div class="section-title">
          <img
            class="main"
            src="https://assets.themiilk.com/portfolio/logo-themiilk-rich.svg"
            alt="더밀크.리치"
          />
          <img
            class="sub"
            src="https://assets.themiilk.com/portfolio/logo-powered-by-alphametrica.svg"
            alt="Powered by alphametrica"
          />
        </div>
      </div>
    </section>

    <section class="portfolio-landing-content result">
      <div class="content single">
        <div class="box-wrapper c-black">
          <div class="box-step label-small-description bold c-white">
            DONE
          </div>

          <div class="box">
            <div class="box-head compare label-sub-title-small">
              <div class="text-wrapper">
                <span class="section-title bold">포트폴리오 비교</span>
                <span
                  class="section-description normal label-small-description"
                >
                  추천된 포트폴리오의 비중을 입력하신 포트폴리오와 비교해 보세요
                </span>
              </div>
            </div>
            <div
              class="box-content"
              v-if="performanceLoading || portfolioLoading"
            >
              <div class="working-wrapper">
                <div class="image-wrapper remove-line-height">
                  <img
                    src="https://assets.themiilk.com/portfolio/icon-analytics.svg"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <h3 class="point label-small-title bold">
                    데이터를 받아 오는 중입니다.
                  </h3>
                  <p class="description label-small-description normal">
                    사용자의 환경에 따라 응답이 다소 늦을 수 있습니다. 잠시만
                    기다려주세요.
                  </p>

                  <!--button class="button button-box xsmall button-refresh" v-on:click="reload">
                    데이터 새로고침
                    <img
                      class="icon"
                      src="https://assets.themiilk.com/portfolio/icon-refresh.svg"
                    />
                  </button-->
                </div>
              </div>
            </div>
            <div class="box-content" v-else>
              <div class="working-wrapper" v-if="alphaPortfolio.length == 0">
                <div class="image-wrapper remove-line-height">
                  <img
                    src="https://assets.themiilk.com/portfolio/icon-analytics.svg"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <h3 class="point label-small-title bold">
                    포트폴리오 진단이 진행중입니다…
                    <!--({{ loadingRatio }}%)-->
                  </h3>
                  <p class="description label-small-description normal">
                    입력하신 포트폴리오를 최적화를 하는 중입니다. 잠시만
                    기다려주세요.
                  </p>

                  <button
                    class="button button-box xsmall button-refresh"
                    v-on:click="reload"
                  >
                    데이터 새로고침
                    <img
                      class="icon"
                      src="https://assets.themiilk.com/portfolio/icon-refresh.svg"
                    />
                  </button>
                </div>
              </div>

              <div
                v-if="alphaPortfolio.length > 0 && this.result.length > 1"
                class="compare-wrapper"
              >
                <div class="compare-list">
                  <div class="text-wrapper">
                    <div class="head-wrapper">
                      <h2 class="point label-small-title bold">전체자산</h2>
                    </div>

                    <div class="data-wrapper my">
                      <div class="data-item point">
                        <div class="data-point label-middle-description bold">
                          내 포트폴리오
                        </div>
                        <div class="data-value label-middle-description bold">
                          {{
                            amountFormat(userPerformance["nav"], "USD", true)
                          }}
                        </div>
                      </div>

                      <div class="data-item small">
                        <div class="data-point label-xsmall">
                          <!-- 포트폴리오 업데이트 시 0으로 리셋, 적절한 표현이나 설명 필요 -->
                          최초 진단일부터의 수익
                        </div>
                        <div class="data-value label-xsmall">
                          {{
                            amountFormat(
                              userPerformance["gain"] || 0,
                              "USD",
                              true,
                              false,
                              true,
                              true
                            )
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="data-wrapper alphametrica">
                      <div class="data-item point">
                        <div class="data-point label-middle-description bold">
                          추천 포트폴리오
                        </div>
                        <div class="data-value label-middle-description bold">
                          {{
                            amountFormat(alphaPerformance["nav"], "USD", true)
                          }}
                        </div>
                      </div>

                      <div class="data-item small">
                        <div class="data-point label-xsmall">
                          <!-- 포트폴리오 업데이트 시 0으로 리셋, 적절한 표현이나 설명 필요 -->
                          최초 진단일부터의 수익
                        </div>
                        <div class="data-value label-xsmall">
                          {{
                            amountFormat(
                              alphaPerformance["gain"] || 0,
                              "USD",
                              true,
                              false,
                              true,
                              true
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-wrapper">
                    <div class="head-wrapper">
                      <h2 class="point label-small-title bold">수익률</h2>
                      <!--div class="selectbox-wrapper">
                        <span class="current label-small">1개월</span>
                        <div class="icon-arrow">
                          <div class="arrow"></div>
                        </div>
                        <select>
                          <option selected>1개월</option>
                          <option>3개월</option>
                          <option>6개월</option>
                          <option>1년</option>
                          <option>5년</option>
                        </select>
                      </div-->
                    </div>

                    <div class="data-wrapper my">
                      <div class="data-item point">
                        <div class="data-point label-middle-description bold">
                          내 포트폴리오
                        </div>
                        <div class="data-value label-small-title bold">
                          {{
                            parseFloat(userPerformance["abs_irr_max"] || 0) > 0
                              ? `+${(
                                  userPerformance["abs_irr_max"] || 0
                                ).toFixed(2)}`
                              : (userPerformance["abs_irr_max"] || 0).toFixed(2)
                          }}
                          %
                        </div>
                      </div>
                    </div>

                    <div class="data-wrapper alphametrica">
                      <div class="data-item point">
                        <div class="data-point label-middle-description bold">
                          추천 포트폴리오
                        </div>
                        <div class="data-value label-small-title bold">
                          {{
                            parseFloat(alphaPerformance["abs_irr_max"] || 0) > 0
                              ? `+${(
                                  alphaPerformance["abs_irr_max"] || 0
                                ).toFixed(2)}`
                              : (alphaPerformance["abs_irr_max"] || 0).toFixed(
                                  2
                                )
                          }}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chart-wrapper">
                  <div class="chart">
                    <GChart
                      type="LineChart"
                      :data="chartData"
                      :settings="{ packages: ['corechart'] }"
                      :options="chartOptions"
                      :events="chartEvents"
                      ref="gChart"
                      @ready="onChartReady"
                    />
                  </div>
                  <div class="button-wrapper">
                    <button class="button button-bordered round active">
                      주간
                    </button>
                    <button
                      class="button button-bordered round disabled"
                    >
                      월간
                    </button>
                    <button
                      class="button button-bordered round disabled"
                    >
                      분기
                    </button>
                    <button
                      class="button button-bordered round disabled"
                    >
                      년
                    </button>
                    <button
                      class="button button-bordered round disabled"
                    >
                      최대
                    </button>
                  </div>
                </div>
                <div class="guide-wrapper label-small-description">
                  <span>
                    자산 가치는 미국시장
                    {{
                      `${parseInt(today.substring(0, 4))}년 ${parseInt(
                        today.substring(4, 6)
                      )}월 ${parseInt(today.substring(6, 8))}일`
                    }}
                    종가를 기준으로 계산되었습니다.
                  </span>
                  <!--a class="button" href="#"><span>데이터 새로고침</span>
                    <img
                      class="icon"
                      src="https://assets.themiilk.com/portfolio/icon-refresh.svg"
                    />
                  </a-->
                </div>
              </div>

              <!-- TODO: 기대수익률이 없을때 bar-charts-wrapper 에 only-portfolio 클래스틑 추가 해주세요. -->
              <div
                class="bar-charts-wrapper"
                :class="{
                  only:
                    this.alphaRiskToReturn == 0 && this.userRiskToReturn == 0,
                }"
              >
                <div class="bar-chart-wrapper" v-if="alphaPortfolio.length > 0">
                  <div class="title label-middle-description bold">
                    포트폴리오 비중
                  </div>
                  <p class="description normal label-xsmall">
                    alphametrica 알고리즘은 위험대비 수익을 개선할 비중을
                    추천합니다.
                  </p>
                  <div class="bar-chart-item-wrapper current">
                    <div class="title label-xsmall bold">
                      MY
                    </div>
                    <div class="bar-chart-item-list">
                      <div
                        class="bar-chart-item"
                        :class="[
                          (item.user_rate * 100).toFixed(0) == 0 ? 'zero' : '',
                        ]"
                        v-for="(item, idx) in this.compareTable"
                        :style="
                          `--width: ${(item.user_rate * 100).toFixed(1)}%`
                        "
                      >
                        <div class="color" :class="`c-${idx}`">
                          <div class="base"></div>
                          <div class="over"></div>
                        </div>

                        <span
                          class="label-xxsmall"
                          v-if="
                            item.ticker !== 'cash' && item.user_rate * 100 > 0
                          "
                          >{{ item.ticker }}</span
                        >
                        <span
                          class="label-xxsmall"
                          v-if="item.ticker === 'cash'"
                          >{{ "Cash" }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="bar-chart-item-wrapper recommand">
                    <div class="title label-xsmall bold">
                      추천
                    </div>
                    <div class="bar-chart-item-list">
                      <div
                        class="bar-chart-item"
                        :class="[
                          (item.alpha_rate * 100).toFixed(0) == 0 ? 'zero' : '',
                        ]"
                        v-for="(item, idx) in this.compareTable"
                        :style="
                          `--width: ${(item.alpha_rate * 100).toFixed(1)}%`
                        "
                      >
                        <div class="color" :class="`c-${idx}`">
                          <div class="base"></div>
                          <div class="over"></div>
                        </div>

                        <span
                          class="label-xxsmall"
                          v-if="
                            item.ticker !== 'cash' && item.alpha_rate * 100 > 0
                          "
                          >{{ item.ticker }}</span
                        >
                        <span
                          class="label-xxsmall"
                          v-if="item.ticker === 'cash'"
                          >{{ "Cash" }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="double-side-bar-chart"
                  v-if="this.userRiskToReturn > 0 && this.alphaRiskToReturn > 0 && alphaPortfolio.length > 0"
                >
                  <div class="double-side-bar-chart-wrapper">
                    <div class="point-icon remove-line-height">
                      <img
                        src="https://assets.themiilk.com/portfolio/icon-risk.svg"
                        alt=""
                      />
                    </div>

                    <div class="head-wrapper">
                      <div class="title label-small-description bold">
                        위험대비 기대수익
                      </div>
                      <button
                        class="button button-show-detail medium"
                        v-on:click="showPopup('expectation_modal')"
                      >
                        <span>자세히 보기</span>
                      </button>
                    </div>

                    <div class="chart-wrapper">
                      <div class="bar-item current">
                        <div
                          class="color"
                          :style="
                            `--percent: ${(this.userRiskToReturn * 100).toFixed(
                              1
                            )}%`
                          "
                        >
                          <div class="text">
                            <span
                              class="percentage bold"
                              :class="
                                (this.userRiskToReturn * 100).toFixed(1) > 38 &&
                                  'c-white'
                              "
                              >{{ this.userRiskToReturn.toFixed(3) }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="bar-item recommand">
                        <div
                          class="color"
                          :style="
                            `--percent: ${(
                              this.alphaRiskToReturn * 100
                            ).toFixed(1)}%`
                          "
                        >
                          <div class="text">
                            <span
                              class="percentage bold"
                              :class="
                                (this.userRiskToReturn * 100).toFixed(1) > 38 &&
                                  'c-white'
                              "
                              >{{ this.alphaRiskToReturn.toFixed(3) }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="message-wrapper label-small">
                      기존 포트폴리오 대비 추천 포트폴리오의 위험대비 기대수익이
                      <em
                        >{{
                          (
                            ((this.alphaRiskToReturn - this.userRiskToReturn) /
                              this.userRiskToReturn) *
                            100
                          ).toFixed(1)
                        }}%</em
                      >
                      더 높은 배분 입니다.
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="portfolio-list-wrapper"
                v-if="alphaPortfolio.length > 0"
              >
                <div class="list-item head-wrapper">
                  <div class="item-section ticker label-xsmall bold">
                    Ticker
                    <span class="description label-xxsmall normal">종목명</span>
                  </div>
                  <div class="item-section current label-xsmall bold">보유</div>
                  <div class="item-section recommand label-xsmall bold">
                    추천
                  </div>
                  <!--div class="item-section rate label-xsmall bold">변동률</div-->
                  <div class="item-section bar-graph label-xsmall bold">
                    차이
                  </div>
                </div>
                <div class="list-item-wrapper">
                  <div
                    class="list-item"
                    v-for="(item, idx) in this.compareTable"
                    :class="{ cash: item.ticker === 'cash' }"
                  >
                    <div
                      class="item-section ticker label-small-description bold"
                    >
                      <span class="ellipsis one">{{ item.ticker }}</span>
                      <span class="description label-xxsmall normal ellipsis">{{
                        item.name
                      }}</span>
                    </div>
                    <div
                      class="item-section right current label-small-description bold"
                    >
                      <span class="mark" v-if="item.ticker === 'cash'"></span
                      >{{ (item.user_rate * 100).toFixed(2) }}%
                      <span class="description label-xxsmall normal"
                        >${{ item.user_amount.toFixed(2) || 0 }}</span
                      >
                    </div>
                    <div
                      class="item-section right recommand label-small-description bold"
                      v-bind:class="{
                        plus: item.user_rate < item.alpha_rate,
                        minus: item.user_rate > item.alpha_rate,
                      }"
                    >
                      <span class="mark" v-if="item.ticker === 'cash'"></span
                      >{{ (item.alpha_rate * 100).toFixed(2) }}%
                      <span class="description label-xxsmall normal"
                        >${{ item.alpha_amount.toFixed(2) || 0 }}</span
                      >
                    </div>
                    <!--div class="item-section rate label-small-description bold">
                      <span>{{
                        `${(
                          item.user_amount / item.alpha_amout 
                        ).toFixed(4)}%`
                      }}</span>
                      <span class="description label-xxsmall normal"
                        >${{
                          item.shares * item.recommand ||
                            item.recommand - item.current
                        }}</span
                      >
                    </div-->
                    <div class="item-section row bar-graph">
                      <div class="bar-item current">
                        <div
                          class="base-wrapper"
                          v-bind:class="{
                            full: item.user_rate > 0.95,
                            zero: item.user_rate === 0,
                            up: item.user_rate < item.alpha_rate,
                            down: item.user_rate > item.alpha_rate,
                          }"
                          :style="
                            `--height: ${(item.user_rate * 100).toFixed(1)}%`
                          "
                        >
                          <div class="base">
                            <div
                              class="percent"
                              :class="`c-${idx}`"
                              :style="
                                `--height: ${(item.user_rate * 100).toFixed(
                                  1
                                )}%`
                              "
                            >
                              <div class="point" :class="`c-${idx}`"></div>
                            </div>
                          </div>
                        </div>
                        <div class="copy label-xxsmall">보유</div>
                      </div>

                      <div class="bar-item explain">
                        <div class="range">
                          <div class="high">100%</div>
                          <div class="low">0%</div>
                        </div>
                        <div class="copy label-xxsmall">범위</div>
                      </div>

                      <div class="bar-item recommand">
                        <div
                          class="base-wrapper"
                          :class="{
                            full: item.alpha_rate > 0.95,
                            zero: item.alpha_rate === 0,
                          }"
                        >
                          <div class="base">
                            <div
                              class="percent"
                              :class="`c-${idx}`"
                              :style="
                                `--height: ${(item.alpha_rate * 100).toFixed(
                                  1
                                )}%`
                              "
                            >
                              <div class="point" :class="`c-${idx}`">
                                <!-- <div class="text">
                                  {{
                                    `${(
                                      (item.alpha_rate - item.user_rate) *
                                      100
                                    ).toFixed(2)}%`
                                  }}
                                </div> -->
                                <div
                                  class="icon-arrow"
                                  :class="{
                                    up: item.user_rate < item.alpha_rate,
                                    down: item.user_rate > item.alpha_rate,
                                  }"
                                >
                                  <div
                                    class="arrow"
                                    v-if="item.user_rate != item.alpha_rate"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="copy label-xxsmall">추천</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="ticker-popup">
                  <div class="ticker-popup-wrapper">
                    <button class="button button-close"></button>

                    <div class="head-wrapper">
                      <h2 class="section-title label-sub-title-small bold">
                        JPM
                      </h2>
                      <p class="section-description label-small-description">
                        Jpmorgan chase
                      </p>
                    </div>

                    <ul class="list label-middle-description bold">
                      <li class="item">
                        <p class="">
                          시가총액: <span class="thin">$1,000,000,000</span>
                        </p>
                      </li>
                      <li class="item">
                        <p class="">현재가: <span class="thin">$12</span></p>
                      </li>
                      <li class="item">
                        <p class="">
                          평가기관 컨센서스: <span class="thin">Buy</span>
                        </p>

                        <div class="consensus-bar label-xsmall">
                          <div class="bar-item buy" style="--width: 60%">
                            13
                          </div>
                          <div class="bar-item hold" style="--width: 30%">
                            3
                          </div>
                          <div class="bar-item sell" style="--width: 10%">
                            1
                          </div>
                        </div>
                      </li>
                      <li class="item">
                        <p class="">
                          목표가 컨센서스
                        </p>

                        <div class="dot-chart">
                          <div class="bar label-xxsmall">
                            <div
                              class="dot-item current"
                              style="--position: 32.5%"
                            >
                              <div class="label">
                                <span class="point">현재</span>
                                <span class="price">133.84</span>
                              </div>
                            </div>

                            <div
                              class="dot-item average"
                              style="--position: 42%"
                            >
                              <div class="label">
                                <span class="point">평균</span>
                                <span class="price">138.84</span>
                              </div>
                            </div>
                          </div>

                          <div class="labels label-xxxsmall normal">
                            <div class="label">
                              <span class="point">최저</span>
                              <span class="price">118.00</span>
                            </div>
                            <div class="label">
                              <span class="point">최대</span>
                              <span class="price">174.00</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div class="button-wrapper">
                      <a
                        href="#"
                        class="button button-arrow label-small-description medium"
                      >
                        JPMORGAN 관련 기사 보러가기
                        <div class="icon-arrow">
                          <div class="icon"></div>
                        </div>
                      </a>
                    </div>

                    <div class="message">
                      <p class="label-small">
                        본정보는 개별 분석기관이 분석한 정보를 수집한 단순
                        데이터 입니다.
                      </p>
                      <p class="sub label-xxsmall">
                        alphametrica 알고리즘은 위와 같은 정보외에
                        개인투자자들이 접근할 수 없는 시장정보를 수집하여
                        종합적으로 진단하고 포트폴리오 비중을 계산합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="box-lower-message flip"
                v-if="!performanceLoading && !portfolioLoading"
              >
                <div
                  class="flip-message"
                  v-for="(item, index) in resultMessages"
                  :class="{ show: resultMessages[index].show }"
                >
                  <div class="message-header">
                    <span class="message-title label-small-title bold">{{
                      item.title
                    }}</span>
                    <button
                      class="button button-rounded bordered xsmall"
                      v-if="!resultMessages[index].show"
                      v-on:click="
                        {
                          resultMessages[index].show = true;
                        }
                      "
                    >
                      <span>{{ item.button.open }}</span>
                    </button>
                    <button
                      class="button button-rounded arrow up xsmall"
                      v-if="resultMessages[index].show"
                      v-on:click="
                        {
                          resultMessages[index].show = false;
                        }
                      "
                    >
                      <span>{{ item.button.close }}</span>
                    </button>
                  </div>
                  <ul
                    class="message-list"
                    :class="{ show: resultMessages[index].show }"
                  >
                    <li
                      class="message-item label-small-description"
                      v-for="(message, index) in item.descriptions"
                    >
                      {{ message }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="box-lower-message inside">
                <p class="message" v-if="userStatus.transactionInit == false">
                  포트폴리오를 <b>확정</b>하시면, 미국 시장 종가로 평가된 성과를
                  매일 확인 하실 수 있습니다.
                </p>
                <p
                  class="message"
                  v-else-if="userStatus.transactionInit == true"
                >
                  고객님의 포트폴리오와 alphametrica의 포트폴리오 가치가 매일
                  평가 됩니다.<br />
                  주기적으로 방문하셔서 성과를 비교해 보세요.
                </p>
                <p class="message caution">
                  추천된 포트폴리오의 비중은 참고사항이며 투자에 대한 최종
                  책임은 사용자에게 있습니다.
                </p>
              </div>
            </div>
          </div>

          <div class="box-button-wrapper" v-if="alphaPortfolio.length > 0">
            <button
              class="button button-rounded shadow hover no-deco"
              v-if="
                userStatus.transactionInit == false && userStatus.initCheck < 5
              "
              v-on:click="checkAgain"
            >
              <span>다시 진단<span class="fold-hide">하기</span></span>
              <span class="balloon">{{ 5 - userStatus.initCheck }}회 남음</span>
            </button>
            <button
              class="button button-rounded shadow hover no-deco modify"
              v-if="userStatus.transactionInit == true"
              :class="{ disabled: userStatus.updateable != true }"
              v-on:click="checkAgain"
            >
              <span>수정<span class="fold-hide">하기</span></span>
              <span class="balloon" v-if="userStatus.updateable == false"
                >다음 영업일 장종료 후 가능</span
              >
            </button>
            <button
              v-if="userStatus.transactionInit == false"
              class="button button-rounded shadow hover confirm no-deco"
              v-on:click="showPopup('confirm_modal')"
            >
              <span>확정<span class="fold-hide">하기</span></span>
            </button>
          </div>
          <!--div class="button-wrapper" v-else>
            에러 발생시 수정 위한 임시 코드
            <button
              class="button button-recheck button-box medium round portfolio-button-green bold no-deco"
              v-if="userStatus.transactionInit==false && userStatus.initCheck == 0"
              v-on:click="checkAgain"
            >
            수정하기
            </button>                  
          </div-->
        </div>
      </div>
    </section>

    <section class="portfolio-landing-illust">
      <div class="content remove-line-height">
        <img
          class="icon-illust"
          src="https://assets.themiilk.com/portfolio/lower-illust.svg"
          alt=""
        />
      </div>
    </section>

    <section class="popup-container" v-bind:class="{ active: currentPopup }">
      <div class="popup-background"></div>
      <div
        class="popup-item reservation guest"
        v-bind:class="{
          show: popups.confirm_modal,
        }"
      >
        <div class="state-wrapper remove-line-height">
          <img
            class="image"
            src=" https://assets.themiilk.com/portfolio/icon-notice.svg"
            alt=""
          />
        </div>

        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-middle-title bold">
              포트폴리오가 확정 됩니다.
            </h2>

            <p class="popup-description label-small-description">
              확정된 포트폴리오는 매일 미국 시장 종료 후 가치 평가가 이뤄집니다.
              주기적으로 방문 하셔서, 고객님의 포트폴리오와 alphametrica의
              포트폴리오 성과를 비교해 보세요.<br />
              포트폴리오가 확정 되면 하루 한번만 포트폴리오를 수정하고 진단하실
              수 있습니다.
              <!--확정된 포트폴리오 구성으로 매일 자산 가치가 평가 되며, 포트폴리오 구성 변경이 필요할 경우, alphametrica가 자동으로 조정한 비율을 이메일로 알려드립니다.-->
            </p>
          </div>
        </div>

        <div class="button-wrapper">
          <button
            class="button button-box round large grey no-deco c-white"
            v-on:click="closePopup"
          >
            닫기
          </button>
          <button
            class="button button-box round large bright-blue no-deco c-white"
            v-on:click="confirm"
            :disabled="isLoading"
          >
            {{ isLoading ? "확인중" : "확인" }}
          </button>
        </div>
      </div>

      <div
        class="popup-item reservation guest"
        v-bind:class="{
          show: popups.alpha_modal,
        }"
      >
        <div class="state-wrapper remove-line-height">
          <img
            class="image"
            src=" https://assets.themiilk.com/portfolio/icon-notice.svg"
            alt=""
          />
        </div>

        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-middle-title bold">
              alphametrica로 이동합니다.
            </h2>

            <p class="popup-description label-small-description">
              보다 장기간의 데이터를 확인하시려면, alphametrica 사이트를
              방문해주세요. <br />아래 확인 버튼을 누르시면 더밀크에 가입하신
              이메일로 alphametrica에 로그인 링크가 전송 됩니다.
            </p>
          </div>
        </div>

        <div class="button-wrapper">
          <button
            class="button button-box round large grey no-deco c-white"
            v-on:click="closePopup"
          >
            닫기
          </button>
          <button
            class="button button-box round large bright-blue no-deco c-white"
            v-on:click="sendEmail"
            :disabled="isLoading"
          >
            {{ isLoading ? "확인중" : "확인" }}
          </button>
        </div>
      </div>

      <div
        class="popup-item guide"
        v-bind:class="{
          show: popups.expectation_modal,
        }"
      >
        <div class="state-wrapper remove-line-height">
          <img
            class="image"
            src="https://assets.themiilk.com/portfolio/icon-guide.svg"
            alt=""
          />
        </div>

        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-middle-title bold">
              위험대비 기대수익
            </h2>

            <p class="popup-description label-small-description text-red-400">
              더밀크리치의 포트폴리오 진단 알고리즘은 다양한 시장
              데이터와 포트폴리오 이론에 기반하여 고객님이 입력한 포트폴리오의 위험대비
              기대 수익을 최대화 하도록 개별 종목의 비중을 조정하여 추천 포트폴리오를 제시 합니다.
            </p>

            <p class="popup-description label-small-description">
              포트폴리오 진단 알고리즘은 입력하신 포트폴리오 대비 추천 포트폴리오의 
              위험대비 기대 수익을 비교해 보세요!
            </p>
          </div>

          <div class="chart-wrapper">
            <div class="single-bar-chart-wrapper">
              <div class="single-bar-chart-item-wrapper current">
                <div class="bar-chart-item">
                  <div
                    class="color low"
                    :style="
                      `--height: ${(this.userRiskToReturn * 100).toFixed(1)}%`
                    "
                  >
                    <div class="text">
                      <div class="icon-arrow"><div class="arrow"></div></div>
                      <span class="percentage">{{
                        (this.userRiskToReturn).toFixed(3)
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="label label-xsmall bold">MY</div>
              </div>
              <div class="single-bar-chart-item-wrapper recommand">
                <div class="bar-chart-item">
                  <div
                    class="color"
                    :style="
                      `--height: ${(this.alphaRiskToReturn * 100).toFixed(1)}%`
                    "
                  >
                    <div class="text">
                      <div class="icon-arrow"><div class="arrow"></div></div>
                      <span class="percentage">{{
                        (this.alphaRiskToReturn).toFixed(3)
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="label label-xsmall bold">추천</div>
              </div>
            </div>
          </div>

          <div class="poll-message-wrapper">
            <p class="popup-description label-small-description">
              설명에 만족 하셨나요?
            </p>
            <button class="icon thumbs-up" @click="clickThumbs('up')">
              <img src="https://assets.themiilk.com/portfolio/icon-like.svg" />
            </button>
            <button class="icon thumbs-down" @click="clickThumbs('down')">
              <img
                src="https://assets.themiilk.com/portfolio/icon-unlike.svg"
              />
            </button>
          </div>
        </div>

        <div class="button-wrapper">
          <button
            class="button button-box round large bright-blue no-deco c-white"
            v-on:click="closePopup"
          >
            닫기
          </button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { GChart } from "vue-google-charts";
import Bugsnag from "@bugsnag/js";
export default {
  props: {
    userStatus: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      resultMessages: [
        {
          show: false,
          title: "진단 결과에 만족 하셨나요?",
          button: {
            open: "설명 더 보기",
            close: "설명 닫기",
          },
          descriptions: [
            "더밀크리치의 alphametrica 포트폴리오 진단 알고리즘은 다양한 데이터와 포트폴리오 이론에 기반 하여 포트폴리오 전체의 위험대비 수익률을 최대화 하게끔 개별 종목에의 배분을 계산합니다.",
            "따라서 상장된 지 얼마 되지 않은 종목이나 극도의 변동성을 보이는 종목들은 데이터의 양과 질에서 한계를 가지고 있습니다. 이에따라, 해당 종목들에 대해서는 추천하지 않거나 매우 낮은 비중을 제안할 수 있습니다.",
          ],
        },
        {
          show: false,
          title: "이렇게 활용해 보세요.",
          button: {
            open: "설명 더 보기",
            close: "설명 닫기",
          },
          descriptions: [
            "고객님의 포트폴리오 분배와 alphamerica의 포트폴리오 분배 결과를 비교하시고, 고객님의 포트폴리오를 조정해보세요.",
            "S&P 500 또는 더 광범위하게는 Russell 2000 지수에 포함된 종목을 고려하는 것을 권고 드립니다. 지수에 편입된 종목들은 시장 데이터의 퀄리티, 유동성등의 조건을 충족 시키고 있기 때문에 불필요한 위험에 노출되지 않을 수 있습니다. 데이터에 기반한 alphametrica의 알고리즘 역시 이런 종목들의 배분을 더 안정적으로 계산합니다.",
            "alphametrica 의 최적화 엔진은 개별 종목에 대한 최적 배분을 목표로 개발 되었습니다. ETF도 편입이 가능하나 ETF의 경우 구성 종목의 배분을 바꿀 수 없으며 ETF 자체가 이미 어느 정도의 배분이 이루어진 자산이므로, 개별 주식과 함께 진단할 경우 ETF 비중이 매우 높게 계산 될 수 있습니다.",
          ],
        },
      ],
      popups: {
        confirm_modal: false,
        alpha_modal: false,
        expectation_modal: false,
      },
      chartOptions: {
        title: "Performance",
        focusTarget: "category",
        chartArea: { left: 0, top: 0, width: "100%", height: "95%" },
        legend: { position: "bottom" },
        colors: ["#1377cb", "#41b11f"],
        hAxis: {
          format: "yy/MM",
          gridlines: {
            count: 0,
          },
        },
        vAxis: {
          grindlines: {
            count: 4,
            color: "#f7f7f7",
          },
          minorGridlines: {
            count: 0,
          },
        },
        async: true,
      },
      chartEvents: {
        select: () => {
          const table = this.$refs.gChart.chartObject;
          const selection = table.getSelection();
          //console.log(selection);
          //let row = selection[0]['row']
          //table.setSelection([{row, column:null}]);
        },
      },
      waitTime: 1000,
      increase: 10,
      loadingRatio: 0,
      today: "",
      performanceLoading: false,
      portfolioLoading: false,
      isLoading: false,
      currentPopup: false,
      chartData: [],
      userPerformance: {},
      alphaPerformance: {},
      userPortfolio: [],
      alphaPortfolio: [],
      userRiskToReturn: 0.0,
      alphaRiskToReturn: 0.0,
      compareTable: [],
      result: [],
      portfolio: [
        {
          type: "stock",
          ticker: "TSLA",
          name: "테슬라",
          shares: 10,
          recommand: 300,
          price: 716,
          currency: "USD",
        },
        {
          type: "stock",
          ticker: "MSFT",
          name: "마이크로 소프트",
          shares: 105,
          recommand: 50,
          price: 254,
          currency: "USD",
        },
        {
          type: "stock",
          ticker: "LCID",
          name: "루시드 그룹",
          shares: 1054,
          recommand: 9,
          price: 20.1,
          currency: "USD",
        },
        {
          type: "stock",
          ticker: "URA",
          name: "GLOBAL X URANIUM ETF",
          shares: 150,
          recommand: 450,
          price: 18.56,
          currency: "USD",
        },
        {
          type: "stock",
          ticker: "GRAB",
          name: "그랩 홀딩스",
          shares: 2500,
          recommand: 100,
          price: 3.05,
          currency: "USD",
        },
        {
          type: "stock",
          ticker: "CRM",
          name: "세일즈 포스",
          shares: 192,
          recommand: 250,
          price: 162.39,
          currency: "USD",
        },
        {
          type: "cash",
          name: "현금",
          current: 184542.75,
          recommand: 68467542.54,
          currency: "USD",
        },
      ],
    };
  },
  components: {
    GChart,
  },
  mounted() {
    this.getPerformance();
    this.getPortfolios();
    //this.getComparison();
  },
  methods: {
    getPerformance() {
      this.performanceLoading = true;
      axios
        .get("/api/portfolios/performances")
        .then((res) => {
          //console.log(res.data);
          if (res.data?.performances.length > 0) {
            this.result = res.data.performances;
            console.log(this.result.length);
            let performance = this.result[this.result.length - 1]?.performance;
            this.today = this.result[this.result.length - 1]?.date;
            console.log(performance);
            this.userPerformance = performance?.userPerformance;
            this.alphaPerformance = performance?.alphaPerformance;

            this.chartData.push([
              "Date",
              "alpha",
              "user",
              { type: "string", role: "domain" },
              { type: "string", role: "style" },
            ]);
            this.result.map((item, key) => {
              let tempUserPerformance = item.performance.userPerformance;
              let tempAlphaPerformance = item.performance.alphaPerformance;

              this.chartData.push([
                item.date,
                tempAlphaPerformance["nav"] || 0,
                tempUserPerformance["nav"] || 0,
                null,
                null,
              ]);
            });
            console.log(this.chartData);
          }
          this.performanceLoading = false;
        })
        .catch((e) => {
          Bugsnag.notify(e.message || e);
        });
    },
    getComparison() {
      axios
        .get("/api/portfolios/comparison")
        .then((res) => {
          console.log("comparison", res.data);
          let comparison = res.data;
          this.alphaRiskToReturn = comparison?.alpha_risk_to_return || 0.0;
          this.userRiskToReturn = comparison?.user_risk_to_return || 0.0;
          console.log(this.alphaRiskToReturn);
        })
        .catch((e) => {
          Bugsnag.notify(e.message || e);
        });
    },
    getPortfolios() {
      this.portfolioLoading = true;
      axios
        .get("/api/portfolios")
        .then((res) => {
          console.log(res);
          let result = res.data;

          this.userPortfolio = result?.userPortfolios;
          this.alphaPortfolio = result?.alphametricaPortfolios;
          this.alphaRiskToReturn = result?.alpha_risk_to_return || 0.0;
          this.userRiskToReturn = result?.user_risk_to_return || 0.0;

          if (this.alphaPortfolio.length > 0) {
            let userCash = false;
            this.compareTable = this.userPortfolio.map((item, key) => {
              let alphaItem = {};

              alphaItem = this.alphaPortfolio.filter(
                (stock) => item.ticker === stock.ticker
              )?.[0];
              userCash = item.ticker == "cash";
              return {
                ticker: item.ticker,
                name: this.findStockName(item.ticker),
                alpha_amount: alphaItem?.amount || 0,
                alpha_shares: alphaItem?.shares || 0,
                alpha_rate: alphaItem?.rate || 0,
                user_amount: item?.amount || 0,
                user_sharse: item?.shares || 0,
                user_rate: item?.rate || 0,
              };
            });

            let alphaItem = this.alphaPortfolio.filter(
              (stock) => stock.ticker === "cash"
            )?.[0];
            if (
              this.alphaPortfolio.length > this.userPortfolio.length &&
              !userCash
            ) {
              //alphaTotal += alphaItem.amount;
              this.compareTable.push({
                ticker: "cash",
                name: "현금",
                alpha_amount: alphaItem?.amount || 0,
                alpha_rate: alphaItem?.rate || 0,
                user_amount: 0,
                user_rate: 0,
              });
            }
            console.log(this.compareTable);
            this.compareTable.sort((a, b) => {
              return b.user_rate - a.user_rate;
            });
            let cashIndex = this.compareTable
              .map((item) => item.ticker)
              .indexOf("cash");
            if (cashIndex < this.compareTable.length - 1) {
              this.compareTable = this.moveElement(
                this.compareTable,
                cashIndex,
                this.compareTable.length - 1
              );
            }
          } else {
            this.portfolioLoading = false;
          }

          this.portfolioLoading = false;
        })
        .then(() => {
          this.getComparison();
        })
        .catch((e) => {
          Bugsnag.notify(e.message || e);
        });
    },
    sendEmail() {
      this.isLoading = true;
      axios
        .get("/api/portfolios/email_login")
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          Bugsnag.notify(e.message || e);
        });
    },
    confirm() {
      this.isLoading = true;
      axios
        .post("/api/portfolios/confirmed")
        .then((res) => {
          console.log(res);
          this.closePopup();
          window.location.reload();
          this.isLoading = false;
        })
        .catch((e) => {
          Bugsnag.notify(e.message || e);
        });
    },
    checkAgain(e) {
      this.$emit("check-again", e);
    },
    getPercent(number) {
      return `style: "--height: ${number * 100}%"`;
    },
    showPopup(name) {
      document.documentElement.style.overflow = "hidden";
      this.currentPopup = name;
      this.popups[name] = true;
      
    },
    reload() {
      window.location.reload();
    },
    closePopup(sec) {
      if (this.currentPopup) {
        this.popups[this.currentPopup] = false;
        this.currentPopup = false;
        document.documentElement.style.overflow = "auto";
      }
    },
    onChartReady(chart, google) {
      this.isLoading = false;
      this.chartsLib = google;
      //let setRow = this.setPoint;
      google.visualization.events.addListener(chart, "ready", function() {
        //chart.setSelection([{row:setRow, column:null}]);
      });
    },
    clickThumbs(type) {
      //alert(event.target)
      let buttonUp  = document.querySelector("button.icon.thumbs-up")
      let buttonDown  = document.querySelector("button.icon.thumbs-down")
      if (type === 'up'){
        buttonUp.classList.add("selected");
        if(buttonDown.classList.contains("selected")){
          buttonDown.classList.toggle("selected");
        }
      }
      else if (type === 'down'){
        buttonDown.classList.add("selected");
        if(buttonUp.classList.contains("selected")){
          buttonUp.classList.toggle("selected");
        }

      }
      
      //event.target.style.backgroundColor = "black";
    },
  },
};
</script>
