var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plan-item",
      class: { selected: _vm.isSelected },
      attrs: { "data-id": [_vm.itemNumber] },
      on: { click: _vm.clicked }
    },
    [
      _c(
        "div",
        { staticClass: "plan-item-base", class: { shorten: _vm.isDetailShow } },
        [
          !_vm.isLanding
            ? _c(
                "div",
                {
                  staticClass: "checkbox",
                  class: { selected: _vm.isSelected }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "icon",
                      attrs: {
                        viewBox: "0 -46 417.81333 417",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        staticClass: "icon-path",
                        attrs: {
                          d:
                            "m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.plan.is_promotion
            ? _c(
                "div",
                { staticClass: "badge anniversary remove-line-height" },
                [
                  _c("img", {
                    attrs: { src: _vm.plan.promotion_image_url, alt: "" }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [
            _c(
              "p",
              { staticClass: "plan-name label-middle-description bold" },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.plan.name) + " 플랜\n        "
                ),
                _vm.currentPlan
                  ? _c("span", { staticClass: "current thin mobile-only" }, [
                      _vm._v("\n          현재 구독중\n        ")
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "plan-point label-middle-description thin" },
              [_c("span", [_vm._v(_vm._s(_vm.plan.description))])]
            ),
            _vm._v(" "),
            _vm.plan.is_promotion
              ? _c(
                  "div",
                  { staticClass: "discount-tag label-small-description bold" },
                  [
                    _c("p", { staticClass: "discount-point c-white" }, [
                      _vm._v(_vm._s(_vm.plan.promotion_name))
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "discount-expired-date label-xxsmall thin c-white"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.plan.promotion_end_at) +
                            " 까지\n        "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "price" }, [
            _c("p", { staticClass: "current label-large-title bold" }, [
              _vm._v(
                "$" + _vm._s(_vm.plan.price - _vm.plan.promotion_discount_price)
              )
            ]),
            _vm._v(" "),
            _vm.plan.is_promotion
              ? _c("p", { staticClass: "original label-middle-title normal" }, [
                  _vm._v("\n        $" + _vm._s(_vm.plan.price) + "\n      ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "per label-small mobile-hide" }, [
              _vm.isYearly ? _c("span", [_vm._v("1년 단위 구독")]) : _vm._e(),
              _vm._v(" "),
              _vm.isMonthly
                ? _c("span", [_vm._v("1개월 단위 구독")])
                : _vm._e(),
              _vm._v(" "),
              _vm.isFree ? _c("span", [_vm._v("무료 구독")]) : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "plan-point label-middle-description medium mobile-only"
              },
              [_c("span", [_vm._v(_vm._s(_vm.plan.discription))])]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "plan-item-detail label-small",
          class: { hide: !_vm.isDetailShow },
          style: { "--height": _vm.getHeight() ? _vm.getHeight() + "px" : "" }
        },
        _vm._l(_vm.plan.benefits, function(b) {
          return _c("li", { key: b.id, staticClass: "plan-item-detail-item" }, [
            _c("i", { staticClass: "material-icons bold done" }, [
              _vm._v("done")
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(b.benefit))])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "button button-benefit button-box round grey mobile-only",
          class: { active: _vm.isDetailShow },
          on: { click: _vm.showBenefit }
        },
        [
          !_vm.isDetailShow
            ? _c("span", [_vm._v("혜택 자세히 보기")])
            : _vm._e(),
          _vm._v(" "),
          _vm.isDetailShow ? _c("span", [_vm._v("혜택 닫기")]) : _vm._e(),
          _vm._v(" "),
          _vm._m(0)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-arrow" }, [
      _c("div", { staticClass: "icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }