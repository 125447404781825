<template>
  <div class="reporter-landing-head" v-if="reporter">
    <div class="background-image remove-line-height" :class="{ active: show }">
      <img class="background" :src="reporter.background_url" alt="" />
    </div>

    <div class="content single">
      <div class="image-wrapper remove-line-height" :class="{ active: show }">
        <img class="profile" :src="reporter.profile_url" alt="" />
      </div>

      <div class="text-wrapper">
        <div class="option">
          <div class="option-item location">
            {{ reporter.location }}
          </div>
          <div class="option-item category">{{ reporter.interest }}</div>
        </div>

        <div class="name-wrapper">
          <h2 class="name label-sub-title-small bold c-white">
            {{ reporter.name }}
          </h2>

          <!-- 추후 추가 예정 -->
          <!-- <button
            class="button button-subscribe button-bordered round white"
            :class="{ subscribed: subscribe }"
            v-on:click="changeSubscribe()"
          >
            <span v-if="!subscribe">뷰스레터 프로 구독하기</span>
            <span v-if="subscribe">뷰스레터 프로 구독중</span>
          </button> -->
        </div>

        <p
          class="description label-small-description ellipsis three"
          :class="{ show: show }"
        >
          <span v-html="reporter.description" />
        </p>

        <div class="control-wrapper">
          <!-- <button
            class="button button-show-more button-box round"
            :class="{ active: show }"
            v-on:click="showDetail()"
          >
            <span>정보 더 보기</span>
            <div class="icon-arrow">
              <div class="icon"></div>
            </div>
          </button> -->

          <div class="social remove-line-height">
            <!-- <a
              class="social-item linked-in"
              :href="reporter.linkedin"
              target="_blank"
              v-if="reporter.linkedin"
            >
              <img
                src="https://assets.themiilk.com/reporters/icon-linkedin.svg"
                alt="linkedIn_icon"
              />
            </a>
            <a
              class="social-item twitter"
              :href="reporter.sns"
              v-if="reporter.sns"
            >
              <img
                src="https://assets.themiilk.com/reporters/icon-twitter.svg"
                alt="twitter_icon"
              />
            </a>
            SNS 추가
            <a
              class="social-item facebook"
              :href="reporter.sns"
              v-if="reporter.sns"
            >
              <img
                src="https://assets.themiilk.com/reporters/icon-facebook.svg"
                alt="facebook_icon"
              />
            </a>
            <a
              class="social-item instagram"
              :href="reporter.sns"
              v-if="reporter.sns"
            >
              <img
                src="https://assets.themiilk.com/reporters/icon-instagram.svg"
                alt="instagram_icon"
              />
            </a> 
              <a
                class="social-item medium"
                :href="reporter.sns"
                v-if="reporter.sns"
              >
                <img
                  src="https://assets.themiilk.com/reporters/icon-medium.svg"
                  alt="medium_icon"
                />
              </a> -->
            <a
              class="social-item telegram"
              :href="reporter.sns"
              v-if="reporter.sns"
            >
              <img
                src="https://assets.themiilk.com/reporters/icon-telegram.svg"
                alt="telegram_icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reporter"],
  data() {
    return {
      show: false,
      subscribe: false,
    };
  },
  mounted() {
    console.log(this.reporter);
  },
  methods: {
    showDetail() {
      this.show ? (this.show = false) : (this.show = true);
    },
    changeSubscribe() {
      this.subscribe ? (this.subscribe = false) : (this.subscribe = true);
    },
  },
};
</script>
