<template>
  <div class="text-input" :class="{error: v.$error}">
    <label v-if="label" class="label" :class="{required: required}">{{ label }}</label>

    <div class="card-element">
      <input :type="type" class="form-control" :placeholder="placeholder"
             :value="value" :disabled="disabled" :readonly="readonly"
             :class="{error: v.$error}"
             @input="onInput($event.target.value)"
             @click="onClick"
             @keydown.enter="onEnter"
             @keydown="onKeydown"
      >
    </div>
    <label v-if="description" class="desc"> {{ description }} </label>
    <validation-message :v="v" v-if="isShowError"></validation-message>
  </div>
</template>

<script>
  import {defaultV} from '../../commons/validators'
  import ValidationMessage from "./validation_message";

  export default {
    components: {
      ValidationMessage
    },
    props: {
      label: String,
      value: [String, Number],
      placeholder: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'text'
      },
      required: {
        type: Boolean,
        default: false,
      },
      isShowError: {
        type: Boolean,
        default: true
      },
      description: String,
      clientSecret: String,
      v: defaultV
    },
    data() {
      return {
        card: null,
        stripe: null,
      }
    },
    mounted() {
      //const stripeApiKey = "pk_live_uZFyUBPwDjcvgG50NH7T8STY00CGX3T7sn";
      //console.log(stripe);
      const stripeApiKey = stripe.env == "development" ? "pk_test_rTqo85OliEFshmIHoDK0cTgY00d3jPs4oo":"pk_live_uZFyUBPwDjcvgG50NH7T8STY00CGX3T7sn";
      const self = this;

      $.getScript("https://js.stripe.com/v3/", function() {
        this.stripe = Stripe(stripeApiKey);
        var elements = this.stripe.elements();
        this.card = elements.create("card", {
          style: {
            base: {
              fontSize: '16px',
              '::placeholder': {
                color: '#BFBFBF',
              },
            }
          }
        });

        this.card.mount(".card-element");
        this.card.on('change', function (event) {
          self.onChange(event);
        });

        MiilkStripeElement.stripe = this.stripe;
        MiilkStripeElement.card = this.card;
      });
    },
    methods: {
      onChange(event) {
        if (event.error) {
          this.$emit('card-error', event);
        } else {
          this.$emit('card-valid', event);
        }
      },
      onInput(value) {
        this.v.$touch();
        this.$emit('input', value);
      },
      onClick(e) {
        this.$emit('click', e);
      },
      onEnter(e) {
        this.$emit('enter', e);
      },
      onKeydown(e) {
        this.$emit('keydown', e);
      },
    }
  }
</script>