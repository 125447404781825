<template>
  <div class="dialog-container">
    <div class="dialog-background"></div>
    <div class="dialog-wrapper">
      <div class="button button-circle close-btn" v-on:click="cancelPop">
        <img class="icon-close" src="https://assets.themiilk.com/common/icon-close-white.svg" alt="Close" />
      </div>
      <div class="dialog-box">
        <div class="dialog-content">
          <div class="section-title label-small-title bold" v-if="title">{{ title }}</div>
          <ul class="list-wrapper label-middle-description">
            <li class="item">
              <label>
<!--                <div class="checkbox">-->
<!--                  <input name="option" type="radio" value="now" v-model="applyOption">-->
<!--                  <div class="box"></div>-->
<!--                </div>-->
                <span class="text">
                  {{ content }}
<!--                  등록되어 있는 카드로 즉시 결제되며 플랜이 변경됩니다.-->
<!--                  <p class="caption">(다른카드로 결제를 원하시면 "마이페이지 > 결제수단"에서 변경 후 진행해주세요.)</p>-->
                </span>
              </label>
            </li>
          </ul>
        </div>
        <div class="dialog-button">
          <div class="button button-box cancel-btn" v-on:click="cancelPop">취소</div>
          <div class="button button-box confirm-btn" v-on:click="confirmed">확인</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'content'],
    data() {
      return {
        applyOption: 'now'
      }
    },
    methods: {
      confirmed: function(e) {
        this.$emit('clicked', this.applyOption);
      },
      cancelPop: function(e) {
        this.$emit('canceled', e);
      }
    }
  }
</script>
