var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showPop
      ? _c(
          "div",
          {
            staticClass:
              "relative overflow-hidden bg-gradient-to-b from-close_start to-close_end"
          },
          [_vm._m(0), _vm._v(" "), _vm._m(1)]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "absolute top-0 left-1/2 transform -translate-x-1/2 -ml-40 sm:-ml-96 max-w-screen-sm"
      },
      [
        _c("img", {
          attrs: {
            src: "https://assets.themiilk.com/portfolio/close-bg.png",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-full bg-close-rich-service fold:px-2.5 px-5 sm:px-10 py-10 sm:py-16 text-white"
      },
      [
        _c("div", { staticClass: "flex flex-col max-w-screen-md mx-auto" }, [
          _c(
            "div",
            {
              staticClass: "pb-6 mb-6 sm:pb-8 sm:mb-8 border-b border-red-500"
            },
            [
              _c(
                "p",
                { staticClass: "text-sm font-light fold:mb-2 mb-3 opacity-50" },
                [_vm._v("공지")]
              ),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass:
                    "fold:text-2xl text-3xl sm:text-4xl font-bold leading-title"
                },
                [
                  _vm._v("\n            2023년 3월 1일(수) "),
                  _c("br"),
                  _vm._v("\n            더밀크리치"),
                  _c("sup", { staticClass: "font-thin opacity-50" }, [
                    _vm._v("beta")
                  ]),
                  _vm._v(" 서비스가\n            종료됩니다.\n          ")
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "font-light text-sm sm:text-base opacity-75" },
            [
              _c("p", [
                _vm._v("\n            안녕하세요. 더밀크입니다. "),
                _c("br"),
                _vm._v(
                  "\n            우선 더밀크리치 서비스를 이용해주신 구독자님들께 감사드립니다.\n          "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mt-3" }, [
                _vm._v(
                  "\n            더밀크리치 베타 서비스를 2023년 3월 1일자로 종료하게 되었습니다.\n            베타서비스를 종료하고, 추후 더 나은 서비스로 찾아뵙도록\n            하겠습니다.\n          "
                )
              ])
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }