import Vue from 'vue';
import Vuelidate from "vuelidate";
import router from '../front/routes';
import App from '../front/app'
import VueCookies from 'vue-cookies';
import common from '../lib/common';
import VueGoogleCharts from 'vue-google-charts';
import ToggleButton from 'vue-js-toggle-button'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import {EventBus} from '../front/commons/event_bus';
import VueSimpleAlert from "vue-simple-alert";
import '../front/assets/tailwind.css';
const userItem = localStorage.getItem('miilkUser');
const user = userItem ? JSON.parse(userItem) : null;
Bugsnag.start({
  apiKey: 'ee631ab556c30db8222237529056b7d1',
  plugins: [new BugsnagPluginVue()],
  onError: function (event){
    if(user){
      event.setUser(user.id, user.email, user.name);
    }
  }

})
const bugsnagVue = Bugsnag.getPlugin('vue')


Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.use(VueGoogleCharts);
Vue.use(ToggleButton);
Vue.use(bugsnagVue)
Vue.use(VueSimpleAlert);

Vue.config.productionTip = false;

window._ = require('lodash');
window.moment = require('moment');
window.common = common;
window.axios = require('axios');
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true

// 요청 인터셉터
axios.defaults.baseURL = window.apiHost;
axios.interceptors.request.use(function (config) {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  config.headers.common["X-CSRF-Token"] = csrfToken;
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use( function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.status == 500 && (error.response.data == null || error.response.data == '') ) {
    EventBus.$emit('internalError');
  } else if (error.response && error.response.data && error.response.data.errorCode == 10002) {
    location.href = `/login?url=${location.pathname}`;
  }

  return Promise.reject(error);
});

Vue.mixin({
  methods: {
    moveElement(array,initialIndex,finalIndex){
      array.splice(finalIndex,0,array.splice(initialIndex,1)[0])
      return array;
    },
    amountFormat(value, currency="USD", showText=false, showSign=false, showZero=false) {


      if (value != null && value != ""){
            
            if (currency == "USD"){
                  let returnValue = value.toFixed(2).replace(/\d(?=(\d{3})+(?!\d))/g,  "$&,");

                  if(value.toFixed(2) != 0){
                        if (showSign){
                              returnValue = value > 0 ? `+${returnValue}`:`${returnValue}`;
                        }      

                        return showText? `$${returnValue}` : returnValue;
                  }
                  else return showZero?0:'';
                  
                  

                  
            }
            else {
                  let returnValue =  value.toFixed(0).replace(/\d(?=(\d{3})+(?!\d))/g,  "$&,")

                  if(value.toFixed(0) != 0){
                        if (showSign){
                              returnValue = value > 0 ? `+${returnValue}`:`${returnValue}`;
                        }

                        return showText? `₩${returnValue}` : returnValue;
                  }
                  else return showZero?0:'';
                                    

                  
            }      
      }
      else {
            
            return showZero?0:'';
      }
    },
    findStockName(ticker) {
      let company = window.companyProfiles.filter((item)=>item.symbol===ticker)
      return company[0]?.englishName || '';    
    },
    articleUrl(article) {
      if (article == null) {
        return '';
      }

      return `/articles/${article.nickname}`;
    },
    heroImageUrl(article, size) {
      if (article == null) {
        return '';
      }

      let urlSize = size == null ? 'origin' : size;
      const newPath = article.new_hero_image_path;
      const env = window.env;
      let path;

      if (size == 'origin') {
        if (env == 'alpha' || env == 'development') {
          path = `https://s3.ap-northeast-2.amazonaws.com/images.themiilk.com/${article.hero_image_url}`;
        } else {
          path = `https://s3.ap-northeast-2.amazonaws.com/image.themiilk.com/${article.hero_image_url}`;
        }
      } else {
        path = `https://dsi523du1o5iq.cloudfront.net/fit-in/${urlSize}x0/${article.hero_image_url}`;
      }

      return path;
    }
  }
});

new Vue({
  router,
  // store: store,
  render: h => h(App)
}).$mount('#service_app');

Vue.filter('formatNumber', function(number) {
  return common.numberWithCommas(number);
});

Vue.filter('intToDate', function(number) {
  return common.intToDateString(number);
});

Vue.filter('stripHTML', function (value) {
  const div = document.createElement('div')
  div.innerHTML = value
  const text = div.textContent || div.innerText || ''
  return text
});
