import _ from 'lodash';

const collectionRequired = (collection) => {
  return Boolean(collection && _.find(collection, el => !el._destroy));
};

const shouldBeFuture = (value) => {
  return moment(value).isAfter();
};

const adAllowedHours = (value) => {
  return moment(value).hour() > 8 && moment(value).hour() < 21;
};

const emailValid = (value) => {
  const regExp = /^[0-9a-zA-Z]([a-zA-Z0-9.+/=?_-])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}$/i;

  return regExp.test(value);
};

const confirmPassword = (value, user) => {
  return value == user.password;
};

const misMatchedPwd = (value, user) => {
  return !user.misMatchedPwd;
};

const validPromotionCode = (value, paymentInfo) => {
  if (value == null || value.length == 0) {
    return true;
  }

  return paymentInfo.promotionId != null;
};

const checkDiffPlan = (selectedPlan, plans) => {
  return selectedPlan.id != plans.currentPlan.id;
};

const requiredName = (name, paymentInfo) => {
  if (paymentInfo.isFree || (paymentInfo.selectedMethod != -1)) {
    return true;
  }

  if (name == null || name.length == 0) {
    return false;
  }

  return true;
};

const requiredEmail = (email) => {
  if (email == null || email.length == 0) {
    return false;
  }

  return true;
};

const requiredPassword = (password) => {
  if (password == null || password.length == 0) {
    return false;
  }

  return true;
};

const requiredChanged = (val, user) => {
  if (val == null || val.length == 0) {
    return false;
  }

  return val != user.oldName;
};

const cardValidation = (val, paymentInfo) => {
  return paymentInfo.isFree || paymentInfo.isValidCard || (paymentInfo.selectedMethod != -1);
};

const cvcValid = (val, paymentInfo) => {
  return paymentInfo.isFree || paymentInfo.isValidCvc || (paymentInfo.selectedMethod != -1);
};

const requiredPaymentTerm = (val, paymentInfo) => {
  return paymentInfo.isFree || (val != null);
}


const defaultV = {
  type: Object,
  default: () => { return { $touch: () => {} } }
};

export {
  collectionRequired, shouldBeFuture,
  adAllowedHours, emailValid, confirmPassword, misMatchedPwd,
  validPromotionCode, checkDiffPlan, requiredName,
  requiredEmail, requiredPassword, requiredChanged,
  cardValidation, cvcValid, requiredPaymentTerm,
  defaultV }
