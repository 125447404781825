<template>
  <div>
    <div
      class="relative overflow-hidden bg-gradient-to-b from-close_start to-close_end"
      v-if="showPop"
    >
      <div
        class="absolute top-0 left-1/2 transform -translate-x-1/2 -ml-40 sm:-ml-96 max-w-screen-sm"
      >
        <img src="https://assets.themiilk.com/portfolio/close-bg.png" alt="" />
      </div>
      <div
        class="w-full bg-close-rich-service fold:px-2.5 px-5 sm:px-10 py-10 sm:py-16 text-white"
      >
        <div class="flex flex-col max-w-screen-md mx-auto">
          <div class="pb-6 mb-6 sm:pb-8 sm:mb-8 border-b border-red-500">
            <p class="text-sm font-light fold:mb-2 mb-3 opacity-50">공지</p>
            <h2
              class="fold:text-2xl text-3xl sm:text-4xl font-bold leading-title"
            >
              2023년 3월 1일(수) <br />
              더밀크리치<sup class="font-thin opacity-50">beta</sup> 서비스가
              종료됩니다.
            </h2>
          </div>
          <div class="font-light text-sm sm:text-base opacity-75">
            <p>
              안녕하세요. 더밀크입니다. <br />
              우선 더밀크리치 서비스를 이용해주신 구독자님들께 감사드립니다.
            </p>
            <p class="mt-3">
              더밀크리치 베타 서비스를 2023년 3월 1일자로 종료하게 되었습니다.
              베타서비스를 종료하고, 추후 더 나은 서비스로 찾아뵙도록
              하겠습니다.
            </p>
            <!-- <p class="mt-3">
              입력하신 포트폴리오의 정보를 지속적으로 확인하시기 위해서는
              알파메트리카 사이트를 방문해주시기 바랍니다.
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Reservation from "../components/portfolios/reservation";
import Insert from "../components/portfolios/insert";
//import Edit from "../components/portfolios/edit";
import Result from "../components/portfolios/result";
import Bugsnag from "@bugsnag/js";

export default {
  props: {},
  data() {
    return {
      menu: 0,
      user: null,
      userStatus: null,
      showPop: this.checkPopup(),
    };
  },
  mounted() {
    const hasUser = localStorage.getItem("miilkUser");
    if (hasUser != null) {
      this.user = JSON.parse(hasUser);
      axios
        .get("/api/portfolios/status")
        .then((res) => {
          console.log(res.data);
          this.userStatus = res.data;
          if (this.userStatus.status == 0) {
            //예약전

            this.menu = 1;
          } else if (this.userStatus.status == 1) {
            //
            this.menu = 2;
          } else if (this.userStatus.status == 2) {
            this.menu = 4;
          }
        })
        .catch((e) => {
          Bugsnag.notify(e.message || e);
        });
    } else this.menu = 1;
  },
  computed: {},
  components: {
    Reservation,
    Insert,
    Result,
  },
  methods: {
    serviceBegin() {
      this.menu = 2;
    },
    checkAgain() {
      this.menu = 2;
    },
    noEmail() {
      this.menu = 1;
    },
    setNClosePop() {
      this.$cookies.set("rich-close", (new Date()).getTime());
      this.closePop();
    },
    closePop() {
      this.showPop = false;
    },
    checkPopup() {
      let pop = true;
      if (this.$cookies?.get("rich-close")) {
        pop = false;
      }

      return pop;
    },
  },
};
</script>
