<template>
  <section class="plan-container padding tiny" v-if="!paymentComplete">
    <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.23.2/antd.min.css"
        integrity="sha512-sQujA/BbGZTcsUrHu5Vt3WL1CJlfB6aouojDf6dxjfKddXqQQKCHbW5Z9BkB0b4VjI8V77jv9tTAxn7/YhLDtQ=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
    />

    <div class="content">
      <div class="content-head">
        <p class="label-xsmall bold step" v-if="signup">2 / 2 단계</p>
        <h1 class="label-title">구독결제</h1>
        <h2 class="label-small-title plan-title">
          <span>구독 플랜 선택</span>
        </h2>

        <h2 class="label-small-title plan-title">
          <a href="/business">기업 회원 상품 바로가기</a>
          <hr class="mobile-only" />
        </h2>
      </div>

      <div class="content-detail">
        <div class="plan-wrapper">
          <div class="plan-list">
            <plan-card-item
                v-for="(plan, index) in plans"
                :itemNumber="index"
                :key="plan.id"
                :plan="plan"
                :selectedType="selectedType"
                :couponId="selectedCoupon"
                :isDetailShow="showDetail"
                @selected="planSelected"
            />
          </div>

          <div class="button-wrapper">
            <button
                class="button button-bordered round grey"
                :class="{
                'item-1': plans.length == 1,
                'item-2': plans.length == 2,
                'item-3': plans.length == 3,
                active: showDetail,
              }"
                @click="showPlanDetail"
            >
              <span v-if="!showDetail">혜택 자세히 보기</span>
              <span v-if="showDetail">혜택 닫기</span>
              <div class="icon-arrow">
                <div class="icon"></div>
              </div>
            </button>
          </div>
        </div>

        <template v-if="this.selectedType != 'free'">
          <div class="payment payment-container">
            <h2 class="label-small-title payment-title">
              <span>구매 정보</span>
              <hr />
            </h2>

            <div class="amount">
              <div class="row">
                <div class="key">구매 상품</div>
                <div class="val plan" v-if="selectedPlan">
                  {{ selectedPlan.name }} 플랜
                </div>
              </div>

              <div class="row">
                <div class="key">결제 금액</div>
                <div class="val">${{ price }}</div>
              </div>

              <div class="row discount" v-if="paymentInfo.discount > 0">
                <div class="key">할인 <span class="small"></span></div>

                <div class="val">${{ paymentInfo.discount }}</div>
              </div>

              <div class="row total">
                <div class="key"><strong>최종 결제 금액</strong></div>
                <div class="val">
                  <strong>${{ paymentInfo.totalAmount }}</strong>
                </div>
              </div>
            </div>
          </div>

          <div class="payment payment-container" v-if="(coupons.length > 0) && selectedPlan.coupon_usable">
            <h2 class="label-small-title payment-title">
              <span>쿠폰</span>
              <hr />
            </h2>

            <div class="text-input">
              <div class="selectbox-element">
                <div class="selectbox-element-wrapper">
                  <div class="selected">
                    <span class="">{{ couponName }}</span>
                  </div>
                  <div class="icon-arrow">
                    <div class="icon"></div>
                  </div>

                  <select v-model="selectedCoupon" @change="changedCoupon">
                    <option value="-1">
                      쿠폰을 선택해주세요.
                    </option>

                    <option
                        :value="coupon.id"
                        v-for="coupon in coupons"
                        :disabled="getDisabled(coupon)"
                    >
                      {{ coupon.name }}
                    </option>
                  </select>
                </div>

                <label class="state-message" v-if="selectedCoupon != -1">
                  <i class="material-icons done">done</i>
                  <small>할인 코드가 적용 되었습니다.</small>
                </label>
              </div>
            </div>
          </div>

          <div class="payment payment-container">
            <h2
                class="label-small-title payment-title"
                style="margin-bottom: 0;"
            >
              <span>결제 정보 입력</span>
              <hr />
            </h2>
            <p style="font-size: 10px; margin-top: 5px; margin-bottom: 1.5em;">
              *구독기간 종료일에 다음 회차 구독료가 자동 결제 됩니다.
            </p>

            <div class="selectbox-element">
              <div class="selectbox-element-wrapper">
                <div class="selected">
                  <span class="">{{ cardName }}</span>
                </div>
                <div class="icon-arrow">
                  <div class="icon"></div>
                </div>

                <select v-model="paymentInfo.selectedMethod">
                  <option value="-1">
                    새로운 카드 등록
                  </option>

                  <option :value="method.id" v-for="method in paymentMethods">
                    {{ getCardName(method) }}
                  </option>
                </select>
              </div>
            </div>

            <template v-if="paymentInfo.selectedMethod == -1">
              <card-input
                  v-model="paymentInfo.cardInfo"
                  :v="$v.paymentInfo.cardInfo"
                  label="카드번호"
                  placeholder="카드번호를 입력하세요."
                  :required="true"
                  :clientSecret="clientSecret"
                  @card-change="cardNumberChange"
                  @card-error="cardError"
                  @card-valid="setCardValid"
              />
              <text-input
                  v-model="paymentInfo.name"
                  :v="$v.paymentInfo.name"
                  label="이름"
                  placeholder="카드에 적힌 이름을 입력하세요."
                  :required="true"
                  @input="checkValid"
              />
            </template>

            <checkbox-input
                v-model="paymentInfo.paymentTerm"
                :v="$v.paymentInfo.paymentTerm"
                :label="termsLabel"
                val="true"
                pk="terms"
                @input="validCheckBox"
            ></checkbox-input>
            <miilk-button
                :v="$v"
                name="구독 시작"
                class="primary"
                :isLoading="isLoading"
                loading-text="결제 처리중..."
                :isDisableStart="true"
                @clicked="subscribe"
            />

            <div class="message small">
              <p>
                * 구독 취소 또는 해지를 원하시면
                <a class="button-link" href="mailto:support@themiilk.com"
                >support@themiilk.com</a
                >
                으로 연락주십시오.
              </p>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="payment payment-container">
            <miilk-button
                :v="$v"
                name="시작하기"
                class="primary"
                :isLoading="isLoading"
                :isDisableStart="false"
                @clicked="startService"
            />
          </div>
        </template>
      </div>
    </div>

    <layer-confirm-popup v-if="yearlyPlanCheck" title="연간 재구독 안내" content="기존의 이용중인 연간플랜이 종료 되었습니다. 재구독하시면 20% 할인된 비용 $200로 재결제됩니다. 재결제 하시겠어요?" @close-layer-popup="processYearlyPlan"></layer-confirm-popup>

    <error-popup
        :isShow="errorShow"
        title="결제 중 오류가 발생했습니다."
        :description="errorMsg"
        @close="closeErrorPopup"
      />
  </section>
  <div class="complete-container" v-else >
    <div class="container" >
      <div class="icon-container">
        <i class="material-icons done">done</i>
      </div>
      <div v-if="selectedPlan.id==1">
        <p class="title" >2주간 무료 체험이 신청 되었습니다.</p>
        <p class="desc">이제 더밀크에서 제공하는 다양한 정보와 뉴스를 제한 없이 만나보실 수 있습니다.</p>
        <p class="desc small">*2주 후 자동결제가 진행됩니다. 자동결제를 원치 않으실 경우 마이페이지 > 멤버십 메뉴에서 자동결제를 중지해주세요.</p>
      </div>
      <div v-else>
        <p class="title">결제가 완료되었습니다.</p>
        <p class="desc">이제 더밀크에서 제공하는 다양한 정보와 뉴스를 제한 없이 만나보실 수 있습니다.</p>
      </div>
      

      <a :href="returnUrl">
        <div class="button-container primary">
          <div class="btn"> 둘러보기 </div>
        </div>
      </a>
    </div>

  </div>
</template>

<script>
import {
  cardValidation,
  cvcValid,
  requiredName,
  requiredPaymentTerm,
} from "../../commons/validators";
import {sendHeapOrder} from "../../commons/heap";
import PlanCardItem from "../../components/orders/plan_card_item";
import CardInput from "../../components/commons/card_input";
import TextInput from "../../components/commons/text_input";
import CheckboxInput from "../../components/commons/checkbox_input";
import MiilkButton from "../../components/commons/miilk_button";
import ErrorPopup from "../../components/commons/error_popup";
import LayerConfirmPopup from "../../components/commons/layer_confirm_popup";

export default {
  components: {
    PlanCardItem,
    CardInput,
    TextInput,
    CheckboxInput,
    MiilkButton,
    ErrorPopup,
    LayerConfirmPopup,
  },
  props: {
    signup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      yearlyPlanCheck: false,
      paymentComplete: false,
      returnUrl: '/',
      errorShow: false,
      errorDefaultMsg: '모든 정보가 올바른지 확인하고 다시 시도해 주세요. 계속 오류가 발생할 경우 고객센터(support@themiilk.com)으로 문의해 주세요.',
      errorMsg: null,
      showDetail: false,
      user: null,
      uid: null,
      plans: [],
      coupon: this.$route.params.coupon,
      initClientSecret: null,

      selectedCoupon: -1,
      coupons: [],

      paymentMethods: [],

      createdKey: 0,

      selectedType: "monthly",
      selectedPlanId: 1,
      selectedPlan: null,
      isLoading: false,
      paymentInfo: {
        cardInfo: null,
        isValidCard: false,
        isValidCvc: true,

        cardInfoError: null,
        name: null,

        discount: 0,
        discountRate: 0,
        paymentTerm: null,
        totalAmount: 0,
        isFree: false,
        selectedMethod: -1,
      },
      clientSecret: null,
      expiredMethodIds: [],
    };
  },
  created() {
    this.checkYearly();
    // get user infos
    axios
        .get("/api/users/me")
        .then((res) => {
          this.user = res.data.user;

          if (this.user.is_subscribe) {
            location.href = "/users/me/membership";
          }
        })
        .catch((e) => {});

    // get plans
    axios.get(`/api/orders/plans?free=${this.signup}`).then((res) => {
      this.plans = res.data.plans;

      const items = this.plans.map((plan) => ({
        item_id: plan.id,
        item_name: plan.terms,
        item_category: "Subscription",
        currency: "USD",
        price: plan.price,
        quantity: 1,
      }));

      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: items,
        },
      });

      this.selectedPlan = this.plans.find((plan) => {
        return plan.terms == "monthly";
      });

      this.initPaymentInfo();
    });

    if (this.coupon) {
      const self = this;
      axios
          .put(`/api/coupons/${this.coupon}`)
          .then((res) => {})
          .catch((error) => {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errorCode == 40008
            ) {
              self.$alert(error.response.data.message);
            }
          })
          .finally(() => {
            self.getCoupons();
          });
    } else {
      this.getCoupons();
    }

    // get cards
    axios.get("/api/users/payment_methods").then((res) => {
      if (res.data.payment_methods) {
        res.data.payment_methods.forEach((method) => {
          this.paymentMethods = res.data.payment_methods;

          if (this.paymentMethods.length > 0) {
            this.paymentInfo.selectedMethod = this.paymentMethods[0].id;
          }
        });
      }
    });

    this.uid = this.generateUUID();

    this.$v.$reset();
    this.$v.$touch();

    // client secret
    this.updateClientSecret();
  },
  methods: {
    checkYearly() {
      const self = this;
      axios.get("/api/orders/check_yearly").then((res) => {
        if (res.data.yearly) {
          self.yearlyPlanCheck = true;
        }
      });
    },
    async processYearlyPlan(confirmed) {
      if (confirmed) {
        const self = this;
        await axios.post("/api/orders/yearly_resubscribe").then(res => {
          if (res.data.result) {
            this.returnUrl = self.getReturnUrl();
            location.href = "/orders/complete?url=" + self.getReturnUrl();
          } else {
            self.showError('카드를 변경하시거나 문제가 계속되면 1:1 문의하기로 문의해 주세요.', null, null);
          }
        });
      }

      this.yearlyPlanCheck = false;
    },
    closeErrorPopup() {
      this.errorShow = false;
    },
    showError(message, code, declineCode) {
      let msg = null;
      if (message != null && message.length > 0) {
        msg = message;
      } else {
        msg = this.errorDefaultMsg;
      }

      let errorCodes = [];
      if (code != null && code.length > 0) {
        errorCodes.push(`error code: ${code}`);
      }
      if (declineCode != null && declineCode.length > 0) {
        errorCodes.push(`decline code: ${declineCode}`);
      }

      if (errorCodes.length > 0) {
        const e = errorCodes.join(", ");
        msg = `${msg} (${e})`;
      }


      this.errorMsg = msg;
      this.errorShow = true;
    },
    getCoupons() {
      axios.get("/api/users/coupons").then((res) => {
        if (res.data.coupons) {
          res.data.coupons.forEach((coupon) => {
            this.coupons.push(coupon);
          });
        }
      });
    },
    generateUUID() {
      var d = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function(c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
          }
      );
      return uuid;
    },
    initPaymentInfo() {
      this.paymentInfo.discount = this.selectedPlan.price - this.selectedPlan.amount;
      this.paymentInfo.discountRate = 0;
      this.paymentInfo.totalAmount = this.selectedPlan.amount;
    },
    showPlanDetail() {
      this.showDetail = !this.showDetail;
    },
    updateClientSecret() {
      axios.get("/api/users/client_secret").then((res) => {
        this.clientSecret = res.data.client_secret;
      });
    },
    planSelected(type, id) {
      this.selectedType = type;
      this.selectedPlan = this.plans.find((plan) => {
        return plan.id == id;
      });

      this.paymentInfo.isFree = this.selectedPlan.price == 0;
      this.selectedCoupon = -1;
      this.initPaymentInfo();
    },
    cardNumberChange: function(event) {
      if (event != null && event.error != null) {
        this.paymentInfo.isValidCard = false;
      }
    },
    cardError: function(event) {
      this.paymentInfo.isValidCard = false;
      // this.$v.$reset();
      this.$v.$touch();
    },
    setCardValid: function(event) {
      this.paymentInfo.isValidCard = true;
    },
    subscribe: function() {
      if (this.paymentInfo.selectedMethod == -1) {
        var stripe = MiilkStripeElement.stripe;
        var card = MiilkStripeElement.card;
        const self = this;

        this.isLoading = true;
        stripe
            .confirmCardSetup(this.clientSecret, {
              payment_method: {
                card: card,
                billing_details: {
                  name: this.paymentInfo.name,
                },
              },
            })
            .then(function(result) {
              if (result.error) {
                self.paymentInfo.isValidCard = false;
                self.isLoading = false;
                self.updateClientSecret();
                self.showError(result.error.message, result.error.code, result.error.decline_code);
              } else {
                self.payment(result.setupIntent.payment_method, -1);
              }
            });
      } else {
        this.isLoading = true;
        this.payment(null, this.paymentInfo.selectedMethod);
      }
    },
    payment: function(stripePaymentId, miilkPaymentId) {
      const coupon = this.coupons.find((x) => x.id == this.selectedCoupon);
      const data = {
        payment_id: stripePaymentId,
        payment_method_id: miilkPaymentId,
        plan_id: this.selectedPlan.id,
        plan_name: this.selectedPlan.terms,
        coupon_id: this.selectedCoupon,
        coupon_type_id: coupon?.coupon_type_id || -1,
        total_amount: this.paymentInfo.totalAmount,
        uid: this.uid,
        free_count: this.$cookies?.get("free_count") || 0,
      };
      const self = this;
      const formData = common.objectToFormData(data);
      axios
          .post("/api/orders/payment", formData)
          .then((res) => {
            try {
              
              sendHeapOrder(res, data);
              fbq("track", "Purchase", {
                currency: "USD",
                value: data.total_amount,
                content_type: data.plan_id,
              });
            } catch (e) {}
            this.returnUrl = self.getReturnUrl();
            // this.paymentComplete =true;
            location.href = "/orders/complete?url=" + self.getReturnUrl();
          })
          .catch(this.paymentError);
    },
    getReturnUrl: function() {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("url")) {
        return urlParams.get("url");
      }

      return "/";
    },
    paymentError(error) {
      console.error(error);
      this.isLoading = false;
      this.paymentInfo.isValidCard = false;
      this.uid = this.generateUUID();
      this.updateClientSecret();

      let message = null;
      if (error.response != null && error.response.data != null && error.response.data.message != null) {
        message = error.response.data.message;
      }
      this.showError(message, null, null);
    },
    validCheckBox: function(checked) {
      if (checked) {
        this.paymentInfo.paymentTerm = true;
      } else {
        this.paymentInfo.paymentTerm = null;
      }
    },
    checkValid: function() {
      this.$v.$reset();
      this.$v.$touch();
    },
    startService: function() {
      location.href = this.getReturnUrl();
    },
    changedCoupon() {
      // check price
      axios
          .get(
              `/api/orders/calculate?plan_id=${this.selectedPlan.id}&coupon_id=${this.selectedCoupon}`
          )
          .then((res) => {
            this.paymentInfo.discount = res.data.discount;
            this.paymentInfo.discountRate = res.data.discount_rate;
            this.paymentInfo.totalAmount = res.data.price;
          });
    },
    getCardName(payMethod) {
      return `${payMethod.brand} ···· ···· ···· ${payMethod.last_number}`;
    },
    getDisabled(coupon) {
      if (
          this.selectedPlan.terms == "yearly" &&
          coupon.coupon_type.discount_type == "terms"
      ) {
        return true;
      } else if (
          this.selectedPlan.terms == "monthly" &&
          coupon.coupon_type.discount_type == "rate"
      ) {
        return true;
      }

      return false;
    },
  },
  computed: {
    termsLabel: function() {
      return "(필수) 상품, 가격, <a href='/payment' class='button-link' target='_blank'>결제 및 환불 관련 정책</a> 등을 확인하였으며 구매에 동의합니다. ";
    },
    price: function() {
      return this.selectedPlan?.price;
    },
    cardName() {
      if (this.paymentInfo.selectedMethod == -1) {
        return "새로운 카드 등록";
      }

      const payMethod = this.paymentMethods.find(
          (x) => x.id == this.paymentInfo.selectedMethod
      );
      return `${payMethod.brand} ···· ···· ···· ${payMethod.last_number}`;
    },
    couponName() {
      if (this.selectedCoupon == -1) {
        return "쿠폰을 선택해주세요.";
      }

      const coupon = this.coupons.find((x) => x.id == this.selectedCoupon);
      return coupon.name;
    },
  },
  validations: {
    paymentInfo: {
      cardInfo: { cardValidation, cvcValid },
      name: { requiredName },
      paymentTerm: { requiredPaymentTerm },
    },
  },
};
</script>
