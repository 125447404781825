var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-wrapper" }, [
    _vm.isLoading
      ? _c(
          "div",
          {
            staticClass: "button button-box fix",
            class: { error: _vm.hasError, loading: _vm.isLoading },
            attrs: { type: _vm.type },
            on: { click: _vm.save }
          },
          [
            _c("div", { staticClass: "txt-wrapper" }, [
              _vm.isLoading ? _c("div", { staticClass: "spin" }) : _vm._e(),
              _vm._v("\n      " + _vm._s(_vm.buttonName) + "\n    ")
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "div",
          {
            staticClass: "button button-box fix",
            class: { error: _vm.hasError },
            attrs: { type: _vm.type },
            on: { click: _vm.save }
          },
          [_vm._v("\n    " + _vm._s(_vm.buttonName) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }