<template>
  <component :is="getLayout" />
</template>

<script>
import UserLanding from "../components/home/user_landing";

export default {
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
    getLayout() {
      return 'UserLanding';
    }
  },
  components: {
    UserLanding
  }
}
</script>