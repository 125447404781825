<template>
  <section class="reporter-landing">
    <landing-head :reporter="reporter" v-if="reporter"/>
    <landing-result 
      :articles="articles" 
      :latest="latest" 
      :totalCount="totalCount"
      v-if="articles" @changeOrder="changeOrder"
    />

    <section
      class="reporter-landing-pagenation"
    >
      <div class="content single">
        <div class="pagination-wrapper">
          <ul class="pagination">
            <li class="page-item prev">
              <div
                class="page-link"
                :class="{ 'link-a': page != 1 }"
                v-on:click="pageClick(page - 1)"
              >
                <div class="icon-arrow">
                  <div class="icon"></div>
                </div>
                <span>이전</span>
              </div>
            </li>
            <div class="number">
              <li
                v-for="(idx, index) in this.pageCount"
                :key="idx"
                class="page-item page-link"
                :class="{ active: liPageNumber(idx) == page }"
                v-on:click="pageClick(liPageNumber(idx))"
                v-if="liPageNumber(idx) > 0 && (index < 5)"
              >
                {{ liPageNumber(idx) }}
              </li>
            </div>
            <li class="page-item next" :class="{ ex: lastPage() }">
              <div
                class="page-link"
                :class="{ 'link-a': !lastPage() }"
                v-on:click="pageClick(page + 1)"
              >
                <span>다음</span>
                <div class="icon-arrow">
                  <div class="icon"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import LandingHead from "../components/reporter/landing_head";
import LandingResult from "../components/reporter/landing_result";

export default {
  data() {
    return {
      id: this.$route.params.id,
      latest: true,
      reporter: null,
      articles: null,
      pageCount: 0,
      totalCount: 0,
      page: 1
    };
  },
  mounted() {
    this.getReporterInfo();
  },
  methods: {
    getReporterInfo() {
      axios.get(`/api/reporters/${this.id}?page=${this.page}&order=${this.latest ? 'desc' : 'asc'}`)
        .then(res => {
          this.reporter = res.data?.reporter;
          this.articles = res.data?.articles;
          this.pageCount = res.data?.page_count;
          this.totalCount = res.data?.total_count;
        })
        .catch(err => {
          this.$router.go(-1)
        })
    },
    changeOrder() {
      this.latest ? (this.latest = false) : (this.latest = true);
      this.page = 1;
      this.getReporterInfo()
    },
    liPageNumber(idx) {
      if (this.page == 1) {
        return idx;
      }
      if (this.lastPage()) {
        return this.page - this.pageCount + idx;
      }

      const pageNumber = this.page - 3 + idx;
      if (this.pageOverflowCount() > -2 && this.pageOverflowCount() < 0)
        return pageNumber + this.pageOverflowCount();

      return pageNumber;
    },
    lastPage() {
      return this.page == this.pageCount;
    },
    pageOverflowCount() {
      return this.page - this.pageCount;
    },
    pageClick(pageNumber) {
      console.log(pageNumber);
      if ((this.lastPage() && this.page < pageNumber) || pageNumber <= 0) {
        return;
      }

      this.page = pageNumber;
      this.getReporterInfo(pageNumber)
    }
  },
  components: {
    LandingHead,
    LandingResult
  },
};
</script>
