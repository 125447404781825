var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.v.$error
    ? _c("div", { staticClass: "error-message" }, [
        _vm.v.requiredIf === false ||
        _vm.v.required === false ||
        _vm.v.collectionRequired === false
          ? _c("small", [_vm._v("이건 꼭 입력해야 해요!")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.maxLength === false
          ? _c("small", [_vm._v("최대 자리수를 확인해주세요.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.minLength === false
          ? _c("small", [_vm._v("비밀번호 형식에 맞지 않습니다.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.ticketClassPrice === false
          ? _c("small", [_vm._v("0원 이상이어야 합니다.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.shouldBeFuture === false
          ? _c("small", [_vm._v("현재 시각 이후로 설정해 주세요.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.adAllowedHours === false
          ? _c("small", [
              _vm._v(
                "광고성 푸시는 오전 8시부터 오후 9시 사이에만 보낼 수 있습니다."
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.emailValid === false
          ? _c("small", [_vm._v("이메일 형식에 맞지 않습니다.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.confirmPassword === false
          ? _c("small", [_vm._v("비밀번호가 동일하지 않습니다.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.misMatchedPwd === false
          ? _c("small", [_vm._v("이메일 또는 비밀번호를 확인해주세요.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.requiredName === false
          ? _c("small", [_vm._v("이름을 입력해주세요.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.requiredEmail === false
          ? _c("small", [_vm._v("이메일을 입력해주세요.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.requiredPassword === false
          ? _c("small", [_vm._v("패스워드를 입력해주세요.")])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.validPromotionCode === false
          ? _c("small", [
              _vm._v("유효하지 않은 코드입니다. 확인 후 다시 입력해주세요.")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.cardValidation === false
          ? _c("small", [
              _vm._v(
                "카드 번호가 올바르지 않습니다. 카드 번호를 확인하거나 다른 카드를 사용하세요."
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.v.cvcValid === false
          ? _c("small", [
              _vm._v(
                "카드의 CVC가 잘못되었습니다. 카드의 CVC를 확인하거나 다른 카드를 사용하세요."
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }