var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reporter-landing-result" }, [
    _c("section", { staticClass: "search-form-landing-tabs" }, [
      _c("div", { staticClass: "content single set-underline" }, [
        _c("ul", { staticClass: "tabs" }, [
          _c("li", { staticClass: "tab" }, [
            _c("div", { staticClass: "tab-item" }, [
              _vm._v("News"),
              _c("span", { staticClass: "count" }, [
                _vm._v(_vm._s(_vm.totalCount))
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sort" }, [
          _c(
            "button",
            {
              staticClass: "sort-item",
              class: { active: _vm.latest },
              on: {
                click: function($event) {
                  return _vm.changeOrder()
                }
              }
            },
            [
              _vm._v("\n          최신"),
              _c("span", { staticClass: "mobile-hide" }, [_vm._v("순")]),
              _vm._v(" "),
              _c(
                "svg",
                {
                  staticClass: "icon",
                  attrs: {
                    width: "12px",
                    height: "14px",
                    viewBox: "0 0 12 14",
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink"
                  }
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd"
                      }
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            transform: "translate(-1262.000000, -420.000000)"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                transform: "translate(388.000000, 403.000000)"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform:
                                      "translate(874.000000, 17.000000)"
                                  }
                                },
                                [
                                  _c("rect", {
                                    attrs: {
                                      x: "0",
                                      y: "0",
                                      width: "12",
                                      height: "14"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "g",
                                    {
                                      staticClass: "svg",
                                      attrs: {
                                        transform:
                                          "translate(2.000000, 1.000000)",
                                        "fill-rule": "nonzero"
                                      }
                                    },
                                    [
                                      _c("polygon", {
                                        attrs: {
                                          points:
                                            "4.66156356 0 4.66150638 9.94940104 7.49524399 7.116677 8.25237799 7.873811 4.12618899 12 9.51016282e-16 7.873811 0.757133979 7.116677 3.59075724 9.94940104 3.59081442 1.90203256e-15"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "search-form-landing-result" }, [
      _c("div", { staticClass: "content single set-underline" }, [
        _vm.articles.length !== 0
          ? _c("div", { staticClass: "list-wrapper" }, [
              _c(
                "ul",
                { staticClass: "list short-top news" },
                _vm._l(_vm.filterArticles(_vm.articles), function(article) {
                  return _c("li", { staticClass: "item" }, [
                    _c(
                      "article",
                      { staticClass: "item-wrapper investing-item" },
                      [
                        _c(
                          "div",
                          { staticClass: "image-wrapper remove-line-height" },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: _vm.articleUrl(article) },
                                on: {
                                  click: function($event) {
                                    return _vm.clickArticle(article)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-box",
                                    style: _vm.heroImageUrl(article)
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "blank-image",
                                      attrs: {
                                        src:
                                          "https://assets.themiilk.com/common/blank-6-4.png",
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-wrapper" }, [
                          article.tags.length > 0
                            ? _c(
                                "ul",
                                { staticClass: "tags" },
                                _vm._l(article.tags, function(tag) {
                                  return _c("li", { staticClass: "tag" }, [
                                    _c(
                                      "a",
                                      { attrs: { href: _vm.tagUrl(tag) } },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(tag.name) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: _vm.articleUrl(article) },
                              on: {
                                click: function($event) {
                                  return _vm.clickArticle(article)
                                }
                              }
                            },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "title label-middle-title bold ellipsis one"
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(article.title) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          article.first_text
                            ? _c(
                                "a",
                                {
                                  staticClass: "no-deco",
                                  attrs: { href: _vm.articleUrl(article) },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickArticle(article)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "description label-small-description ellipsis three"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.stripTag(article.first_text)
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", { staticClass: "author label-xsmall" }, [
                            _c("span", { staticClass: "bold" }, [
                              _vm._v(_vm._s(article.author_names))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(article.str_published_at) +
                                  "\n                  "
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                }),
                0
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }