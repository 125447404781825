<template>
  <div
    class="plan-item"
    :data-id="[itemNumber]"
    :class="{ selected: isSelected }"
    v-on:click="clicked"
  >
    <div class="plan-item-base" :class="{ shorten: isDetailShow }">
      <div class="checkbox" :class="{ selected: isSelected }" v-if="!isLanding">
        <svg
          class="icon"
          viewBox="0 -46 417.81333 417"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="icon-path"
            d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
          />
        </svg>
      </div>

      <div
        class="badge anniversary remove-line-height"
        v-if="plan.is_promotion"
      >
        <img :src="plan.promotion_image_url" alt="" />
      </div>

      <div class="info">
        <p class="plan-name label-middle-description bold">
          {{ plan.name }} 플랜
          <span class="current thin mobile-only" v-if="currentPlan">
            현재 구독중
          </span>
        </p>
        <p class="plan-point label-middle-description thin">
          <span>{{ plan.description }}</span>
        </p>

        <div
          class="discount-tag label-small-description bold"
          v-if="plan.is_promotion"
        >
          <p class="discount-point c-white">{{ plan.promotion_name }}</p>
          <p class="discount-expired-date label-xxsmall thin c-white">
            {{ plan.promotion_end_at }} 까지
          </p>
        </div>
      </div>

      <div class="price">
        <p class="current label-large-title bold">${{ (plan.price - plan.promotion_discount_price) }}</p>
        <p
            class="original label-middle-title normal"
            v-if="plan.is_promotion"
        >
          ${{ plan.price }}
        </p>
        <p class="per label-small mobile-hide">
          <span v-if="isYearly">1년 단위 구독</span>
          <span v-if="isMonthly">1개월 단위 구독</span>
          <span v-if="isFree">무료 구독</span>
        </p>

        <p
          class="plan-point label-middle-description medium mobile-only"
        >
          <span>{{ plan.discription }}</span>
        </p>
      </div>
    </div>

    <ul
      class="plan-item-detail label-small"
      :class="{ hide: !isDetailShow }"
      :style="{ '--height': getHeight() ? getHeight() + 'px' : '' }"
    >
      <li class="plan-item-detail-item" v-for="b in plan.benefits" :key="b.id">
        <i class="material-icons bold done">done</i>
        <span>{{ b.benefit }}</span>
      </li>
    </ul>

    <button
      class="button button-benefit button-box round grey mobile-only"
      :class="{ active: isDetailShow }"
      @click="showBenefit"
    >
      <span v-if="!isDetailShow">혜택 자세히 보기</span>
      <span v-if="isDetailShow">혜택 닫기</span>
      <div class="icon-arrow">
        <div class="icon"></div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    itemNumber: {
      type: Number,
      default: 0,
    },
    plan: {
      type: Object,
    },
    selectedType: {
      type: String,
    },
    currentPlan: {
      type: Boolean,
      default: false,
    },
    isChangeMode: {
      type: Boolean,
      default: false,
    },
    isDetailShow: {
      type: Boolean,
      default: false,
    },
    isLanding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    priceText() {
      if (this.plan.price == 0) {
        return "FREE";
      } else {
        return `$${this.plan.price}`;
      }
    },
    isYearly() {
      return this.plan.terms == "yearly";
    },
    isFree() {
      return this.plan.terms == "free";
    },
    isMonthly() {
      return this.plan.terms == "monthly";
    },
    isSelected() {
      return this.plan.terms == this.selectedType;
    },
  },
  mounted() {
    if (this.isMobile() && this.isDetailShow) {
      this.isDetailShow = true;
    }
  },
  methods: {
    isMobile() {
      return window.innerWidth > 768 ? false : true;
    },
    clicked() {
      this.$emit("selected", this.plan.terms, this.plan.id);
    },
    showBenefit() {
      this.isDetailShow = !this.isDetailShow;
    },
    getHeight() {
      let height = 0;

      document
        .querySelectorAll(
          `.plan-item[data-id="${this.itemNumber}"] .plan-item-detail .plan-item-detail-item`
        )
        .forEach((item) => {
          height += item.clientHeight;
        });

      if (height > 0) {
        if (window.screen.width > 568) {
          return height + 33;
        } else {
          if (window.screen.width <= 281) {
            return height + 33 + 20;
          } else {
            return height + 33 + 5;
          }
        }
      }
    },
  },
};
</script>
