<template>
  <section class="portfolio-landing">
    <section class="portfolio-landing-background">
      <div class="image-wrapper side top-right remove-line-height">
        <img
          class="image"
          src="https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-rt.png"
          alt=""
        />
      </div>
      <div class="image-wrapper side bottom-left remove-line-height">
        <img
          class="image"
          src="https://assets.themiilk.com/portfolio/themiilk-rich-circle-background-rt.png"
          alt=""
        />
      </div>
    </section>

    <section class="portfolio-landing-hero">
      <div class="content">
        <div class="current-page">
          <h2 class="name c-white">Portfolio</h2>
          <p class="description c-white">
            더밀크에서 제공하는 최고 수준의 포트폴리오 진단 서비스를 경험하세요.
          </p>
        </div>

        <div class="section-title">
          <img
            class="main"
            src="https://assets.themiilk.com/portfolio/logo-themiilk-rich.svg"
            alt="더밀크.리치"
          />
          <img
            class="sub"
            src="https://assets.themiilk.com/portfolio/logo-powered-by-alphametrica.svg"
            alt="Powered by alphametrica"
          />
        </div>

        <h2 class="section-point label-sub-title-small bold c-white">
          더밀크에서 제공하는 최고 수준의 <br />
          포트폴리오 진단 서비스를 경험하세요!
        </h2>

        <div class="button-wrapper">
          <button
            class="button button-rounded shadow hover confirm no-deco"
            @click="reserveBtnClicked"
          >
            <span>{{
              this.user != null ? "시작하기" : "회원가입 후	무료 진단하기"
            }}</span>
          </button>
        </div>
      </div>
    </section>

    <section class="portfolio-landing-content">
      <div class="content single">
        <div class="text-wrapper">
          <h2 class="section-head label-sub-title-small c-white">
            <div class="label">더밀크리치란?</div>
            <div class="line"></div>
          </h2>
          <p class="section-description label-description normal">
            더밀크리치는 개인 맞춤형 포트폴리오 진단 서비스입니다.<br />
            여러분이 진단 의뢰한 미국 주식 포트폴리오를 alphametrica가 추천하는
            위험 대비 수익률을 장기적으로 극대화 시키는 포트폴리오 배분과 비교
            분석해 드립니다.<br />
          </p>
          <p class="section-description label-middle-description thin">
            'alphametrica'는 금융 선진국의 일류 연구기관과 헤지펀드에서 퀀트
            알고리즘을 연구, 개발하고 있는 최고 수준의 계량 투자 전문가들이 만든
            포트폴리오 최적화 엔진입니다.
          </p>
        </div>

        <ul class="box-items">
          <li
            class="box-item c-black subscriber"
            v-on:click="showPopup('special_service')"
          >
            <div class="image-wrapper remove-line-height">
              <img
                src="https://assets.themiilk.com/portfolio/illust-special-service.png"
                alt=""
              />
              <div class="message">자세히 알아보기</div>
            </div>
            <h3 class="label-description bold">더밀크 특별 서비스</h3>
          </li>
          <li
            class="box-item c-black portfolio"
            v-on:click="showPopup('optimization')"
          >
            <div class="image-wrapper remove-line-height">
              <img
                src="https://assets.themiilk.com/portfolio/illust-optimization.png"
                alt=""
              />
              <div class="message">자세히 알아보기</div>
            </div>
            <h3 class="label-description bold">포트폴리오 최적화란?</h3>
          </li>
          <li
            class="box-item c-black algorithm"
            v-on:click="showPopup('algorithm_based_optimization')"
          >
            <div class="image-wrapper remove-line-height">
              <img
                src="https://assets.themiilk.com/portfolio/illust-algorithm-based-optimization.png"
                alt=""
              />
              <div class="message">자세히 알아보기</div>
            </div>
            <h3 class="label-description bold">알고리즘 기반 최적화</h3>
          </li>
        </ul>

        <p class="help-message label-middle-description thin">
          더밀크 회원에게만 드리는 더밀크리치 포트폴리오 진단 서비스를
          <br />
          이용하시려면 아래 버튼을 눌러 시작해주세요!
        </p>

        <div class="button-wrapper">
          <button
            class="button button-rounded shadow hover confirm no-deco"
            @click="reserveBtnClicked"
          >
            <span>{{
              this.user != null ? "시작하기" : "회원가입 후	무료 진단하기"
            }}</span>
          </button>
        </div>
      </div>
    </section>

    <section class="portfolio-landing-illust">
      <div class="content remove-line-height">
        <img
          class="icon-illust"
          src="https://assets.themiilk.com/portfolio/lower-illust.svg"
          alt=""
        />
      </div>
    </section>

    <section class="popup-container" v-bind:class="{ active: currentPopup }">
      <div class="popup-background" v-on:click="closePopup"></div>

      <div
        class="popup-item special-service"
        v-bind:class="{ show: popups.special_service }"
      >
        <button
          class="button button-circle button-close remove-line-height"
          v-on:click="closePopup"
        >
          <img
            class="close"
            src="https://assets.themiilk.com/commons/icon-close-thin-white.svg"
            alt=""
          />
        </button>
        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-sub-title">
              더밀크 회원 <br />
              <strong class="bold">특별 제공 서비스</strong>
            </h2>

            <p class="popup-description label-middle-description">
              더밀크 사용자들은 최초 구성시 5번까지의 최적화 실험을 해볼수
              있으며, 최초 포트폴리오 구성 이후 시장상황의 변화등을 반영하여
              수시로 최적 비중을 업데이트 받아 보게 됩니다.
            </p>

            <div class="button-wrapper">
              <a
                class="button button-box round large bright-blue no-deco c-white"
                @click="reserveBtnClicked"
              >
                {{ this.user != null ? "시작하기" : "회원가입 후	무료 진단하기" }}
              </a>
            </div>
          </div>

          <div class="image-wrapper remove-line-height">
            <img
              class="illust"
              src="https://assets.themiilk.com/portfolio/illust-special-service-detail.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        class="popup-item optimization"
        v-bind:class="{ show: popups.optimization }"
      >
        <button
          class="button button-circle button-close remove-line-height"
          v-on:click="closePopup"
        >
          <img
            class="close"
            src="https://assets.themiilk.com/commons/icon-close-thin-white.svg"
            alt=""
          />
        </button>
        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-sub-title">
              포트폴리오 <strong class="bold">최적화란</strong>?
            </h2>

            <ul class="bullet-list label-middle-description">
              <li class="item">
                투자가 여러가지 종목 혹은 자산으로 이루어져 있을 때, 전체 성과는
                어떤 종목을 선택하였는지 뿐만 아니라 각 종목이 얼마나 투자
                되었는지에 큰 영향을 받게 됩니다.
              </li>
              <li class="item">
                따라서 전체 투자 자산, 즉 포트폴리오를 구성하고 있는 각각의
                자산의 비중을 결정하는 것은 매우 중요하며, 포트폴리오의 위험에
                대비한 기대 수익률을 최대한 늘리게끔 구성 자산의 비중을 변경하는
                것을 포트폴리오 최적화라 합니다.
              </li>
            </ul>

            <div class="button-wrapper">
              <a
                class="button button-box round large bright-blue no-deco c-white"
                @click="reserveBtnClicked"
              >
                {{ this.user != null ? "시작하기" : "회원가입 후	무료 진단하기" }}
              </a>
            </div>
          </div>

          <div class="image-wrapper remove-line-height">
            <img
              class="illust"
              src="https://assets.themiilk.com/portfolio/illust-optimization-detail.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        class="popup-item algorithm-based-optimization"
        v-bind:class="{ show: popups.algorithm_based_optimization }"
      >
        <button
          class="button button-circle button-close remove-line-height"
          v-on:click="closePopup"
        >
          <img
            class="close"
            src="https://assets.themiilk.com/commons/icon-close-thin-white.svg"
            alt=""
          />
        </button>
        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-sub-title">
              <strong class="bold">알고리즘 기반</strong> <br />
              포트폴리오 최적화 효과
            </h2>

            <p class="popup-description label-middle-description">
              alphametrica는 학술적 연구를 통해 엄밀하게 검증된 모델들을 활용해
              시장 상황에 따른 최적화된 조합을 제시합니다.
            </p>

            <div class="button-wrapper">
              <a
                class="button button-box round large bright-blue no-deco c-white"
                @click="reserveBtnClicked"
              >
                {{ this.user != null ? "시작하기" : "회원 가입하고 진단하기" }}
              </a>
            </div>
          </div>

          <div class="image-wrapper remove-line-height">
            <div class="lines">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
            <img
              class="chart"
              src="https://assets.themiilk.com/portfolio/illust-algorithm-based-optimization-detail-chart.png"
              alt=""
            />
          </div>

          <div class="image-wrapper remove-line-height point">
            <img
              class="point"
              src="https://assets.themiilk.com/portfolio/illust-algorithm-based-optimization-detail-point.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        class="popup-item reservation confirm"
        v-bind:class="{
          show: popups.reservation_confirm,
        }"
      >
        <div class="state-wrapper remove-line-height">
          <img
            class="image"
            src="https://assets.themiilk.com/portfolio/icon-confirm.svg"
            alt=""
          />
        </div>

        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-middle-title bold">
              신청이 완료 되었습니다.
            </h2>
            <p class="popup-description label-small-description">
              베타 서비스 오픈 후, 안내 메일을 발송해 드립니다.
            </p>
          </div>
        </div>

        <div class="button-wrapper">
          <button
            v-on:click="closePopup"
            class="button button-box round large bright-blue no-deco c-white"
          >
            확인
          </button>
        </div>
      </div>

      <div
        class="popup-item reservation guest"
        v-bind:class="{
          show: popups.reservation_guest,
        }"
      >
        <div class="state-wrapper remove-line-height">
          <img
            class="image"
            src="https://assets.themiilk.com/portfolio/icon-subscribe.svg"
            alt=""
          />
        </div>

        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-middle-title bold">
              회원 전용 서비스 입니다.
            </h2>

            <p class="popup-description label-small-description">
              회원 가입 후 더 많은 혜택을 누려 보세요.
            </p>
          </div>
        </div>

        <div class="button-wrapper">
          <button
            class="button button-box round large grey no-deco c-white"
            v-on:click="closePopup"
          >
            닫기
          </button>
          <a
            class="button button-box round large bright-blue no-deco c-white"
            href="/orders/plans?url=/portfolio"
            >유료 구독하러 가기</a
          >
        </div>
      </div>

      <div
        class="popup-item reservation membership"
        v-bind:class="{
          show: popups.reservation_membership,
        }"
      >
        <div class="state-wrapper remove-line-height">
          <img
            class="image"
            src="https://assets.themiilk.com/portfolio/icon-upgrade.svg"
            alt=""
          />
        </div>

        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-middle-title bold">
              회원 전용 서비스 입니다.
            </h2>

            <p class="popup-description label-small-description">
              회원 가입 후 더 많은 혜택을 누려 보세요.
            </p>
          </div>
        </div>

        <div class="button-wrapper">
          <button
            class="button button-box round large grey no-deco c-white"
            v-on:click="closePopup"
          >
            닫기
          </button>
          <a
            class="button button-box round large bright-green no-deco c-white"
            href="/users/change_plan?url=/portfolio"
            >연간회원으로 전환하기</a
          >
        </div>
      </div>

      <div
        class="popup-item reservation no-email"
        v-bind:class="{
          show: popups.reservation_no_email,
        }"
      >
        <div class="state-wrapper remove-line-height">
          <img
            class="image"
            src="https://assets.themiilk.com/portfolio/icon-email.svg"
            alt=""
          />
        </div>

        <div class="popup-wrapper c-black">
          <div class="text-wrapper">
            <h2 class="popup-title label-middle-title bold">
              이메일을 등록해주세요.
            </h2>

            <input v-model="email"
              class="input round label-middle-description email"
              :class="{ warning: emailWarning}"
              type="text"
              placeholder="이메일을 입력해주세요."
            />


            <p class="popup-description label-small-description warning" v-if="emailWarning">
              정확한 이메일을 입력해주세요.
            </p>            
            <p class="popup-description label-small-description" v-else>
              이메일을 입력하시면, 더밀크리치를 활용한 미국 주식 포트폴리오 진단이 가능합니다.
            </p>            
          </div>
        </div>

        <div class="button-wrapper">
          <button
            class="button button-box round large grey no-deco c-white"
            v-on:click="closeRegisterPopup"
          >
            취소
          </button>
          <button
            class="button button-box round large bright-green no-deco c-white"
            @click="registerEmail"
          >
            입력하기
          </button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      email: "",
      emailWarning: false,
      currentPopup: false,
      popups: {
        special_service: false,
        optimization: false,
        algorithm_based_optimization: false,
        reservation_confirm: false,
        reservation_guest: false,
        reservation_membership: false,
        reservation_no_email: false,
      },
    };
  },
  mounted() {
    if (this.user !== null && (this.user.email === null || this.user.email.length === 0 || this.user.email === "undefined")) {
      this.popups["reservation_no_email"] = true;
      this.currentPopup = "reservation_no_email";
    }
  },  
  computed: {},
  methods: {
    reserveBtnClicked(e) {
      if (this.user === null) {
        location.href = "/signup?url=/portfolio";
        return;
      }
      /*
      if (
        this.user.email == null || this.user.email.length == 0 || this.user.email === "undefined"
      ) {
        this.popups["reservation_no_email"] = true;
        this.currentPopup = "reservation_no_email";
        return;
      }
      */
      if (this.user!==null) {
        this.popups["reservation_guest"] = true;
        this.currentPopup = "reservation_guest";
        /*} else if (this.user.userType == "monthly") {
          this.popups["reservation_membership"] = true;
          this.currentPopup = "reservation_membership";
        } else {*/
        /*axios.post('/api/portfolios/reserve').then(res => {
          this.popups['reservation_confirm'] = true;
          this.currentPopup = 'reservation_confirm';
        });*/
        this.$emit("service-start", e);
      }
    },
    closeRegisterPopup() {
      this.closePopup();      
      this.emailWarning = false;
      this.email = "";
    },
    registerEmail(e) {
      
      if (this.email === null || this.email.length === 0) {
        this.emailWarning = true;
        return;
      }

     
      const regExp = /^[0-9a-zA-Z]([a-zA-Z0-9.+/=?_-])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}$/i;
      if (regExp.test(this.email)) {
        const data = {
          email: this.email
        };


        axios.put("/users/update_email", data).then(res => {
          console.log("success");
          this.closePopup();
          window.location.href = "/portfolio";
          
        }).catch((err) => {
          Bugsnag.notify(e.message||e);
          // alert("다시 시도해주세요.");

        });
      } else {
        this.emailWarning = true;
        // alert("정확한 이메일을 입력해 주세요.");
      }
      
    },    
    showPopup(name) {
      this.currentPopup = name;
      this.popups[name] = true;
    },
    closePopup() {
      if (this.currentPopup) {
        this.popups[this.currentPopup] = false;
        this.currentPopup = "";
      }
    },
  },
};
</script>
