var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-wrapper notification-btn",
      class: { active: _vm.isActive },
      on: { click: _vm.readAll }
    },
    [
      _c("div", { staticClass: "button-notification remove-line-height" }, [
        _c("img", {
          staticClass: "icon-notification",
          attrs: {
            src: "https://assets.themiilk.com/common/icon-notification.svg"
          }
        }),
        _vm._v(" "),
        _vm.notification_count !== 0
          ? _c("div", { staticClass: "count" }, [
              _vm._v(_vm._s(_vm.notification_count))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "notifications-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "notifications-head",
            class: {
              empty: _vm.notification_count + _vm.read_notification_count === 0
            }
          },
          [
            _c("div", { staticClass: "count-label" }, [
              _vm._v(
                "알림 " +
                  _vm._s(_vm.notification_count + _vm.read_notification_count)
              )
            ]),
            _vm._v(" "),
            _vm.notification_count !== 0 || _vm.read_notification_count !== 0
              ? _c(
                  "button",
                  {
                    staticClass:
                      "button underline button-remove-messege notification-remove-all",
                    on: { click: _vm.removeAll }
                  },
                  [_vm._v("\n        모두삭제\n      ")]
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "notification-list" },
          _vm._l(_vm.read_notifications, function(notification) {
            return _c(
              "li",
              { key: notification.id, staticClass: "notification" },
              [
                _c("div", { staticClass: "date" }, [
                  _vm._v(_vm._s(_vm.formatDate(notification.created_at)))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "point" }, [
                  _vm._v(_vm._s(notification.title))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.notiMessage(notification.message))
                    }
                  })
                ]),
                _vm._v(" "),
                notification.url
                  ? _c(
                      "a",
                      {
                        staticClass: "button-custom button-box tiny",
                        attrs: { href: notification.url, target: "_blank" }
                      },
                      [_vm._v("보러가기")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button-remove notification-remove",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.remove(notification.id)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "icon-remove",
                      attrs: {
                        src:
                          "https://assets.themiilk.com/common/icon-remove.svg"
                      }
                    })
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }