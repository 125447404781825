var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "coupon-container" },
    [
      _vm.user != null
        ? [
            _vm.usable && _vm.user.is_subscribe
              ? _c("div", { staticClass: "content padding" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._m(2)
                ])
              : _vm.usable && !_vm.user.is_subscribe
              ? _c("div", { staticClass: "content padding" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _vm._m(5)
                ])
              : !_vm.usable
              ? _c("div", { staticClass: "content padding" }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8)
                ])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://assets.themiilk.com/common/illust-coupon-registered.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-wrapper" }, [
      _c("h2", { staticClass: "title label-sub-title bold" }, [
        _vm._v("\n          쿠폰이 등록되었습니다.\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-wrapper" }, [
      _c(
        "a",
        {
          staticClass: "button button-box round no-deco",
          attrs: { href: "/" }
        },
        [_vm._v("\n          더밀크 홈으로가기\n        ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://assets.themiilk.com/common/illust-coupon-registered.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-wrapper" }, [
      _c("h2", { staticClass: "title label-sub-title bold" }, [
        _vm._v("\n          쿠폰이 등록되었습니다.\n        ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description label-small-description" }, [
        _vm._v(
          "\n          구독하시면 더 많은 정보를 얻을 수 있습니다.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-wrapper" }, [
      _c(
        "a",
        {
          staticClass: "button button-box round no-deco",
          attrs: { href: "/orders/plans" }
        },
        [_vm._v("\n          구독하러가기\n        ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://assets.themiilk.com/common/illust-coupon-failed.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-wrapper" }, [
      _c("h2", { staticClass: "title label-sub-title bold" }, [
        _vm._v("\n          사용 할 수 없는 쿠폰입니다.\n        ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description label-small-description" }, [
        _vm._v(
          "\n          쿠폰 코드를 다시 확인 하시거나 고객센터에 문의 해주세요.\n        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-wrapper" }, [
      _c(
        "a",
        {
          staticClass: "button button-box round no-deco",
          attrs: { href: "/orders/plans" }
        },
        [_vm._v("\n          구독하러가기\n        ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }