var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup-container" }, [
    _c(
      "div",
      {
        staticClass: "popup-item reservation guest",
        class: { show: _vm.isShow }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "popup-wrapper c-black" }, [
          _c("div", { staticClass: "text-wrapper" }, [
            _c("h2", { staticClass: "popup-title label-middle-title bold" }, [
              _vm._v("\n          " + _vm._s(_vm.title) + "\n        ")
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "popup-description label-small-description" },
              [_vm._v("\n          " + _vm._s(_vm.description) + "\n        ")]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "button-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "button button-box round large grey no-deco c-white",
              on: { click: _vm.closePopup }
            },
            [_vm._v("\n        닫기\n      ")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-wrapper remove-line-height" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: "https://assets.themiilk.com/commons/icon-error.svg",
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }